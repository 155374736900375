import React from "react";
import { Link } from "react-router-dom";
import { SITE_TITLE } from "../../Config";

export default function Footer() {
  return (
    <>
      <div className="footer p-0 bg-white border">
        {/* <div className="row m-0">
          <div className="col-md-3 col-6 border-end border-primary">
            <h1 className="text-secondary text-center">Biziness</h1>
            <div className="text-center">
              <img
                src={require("./../../assets/img/bzlogo.png")}
                alt=""
                width="20%"
                className=""
              />
            </div>
            <div className="text-center">
              <img
                src={BASE_URL + "/assets/img/logo.png"}
                alt=""
                width="80%"
                className=""
              />
            </div>
          </div>
          <div className="col-md-3 col-6 border-end border-primary">
            <ul>
              <li>
                <Link to="/login" className="text-dark">
                  Login
                </Link>
              </li>
              <li>
                <Link to="/register" className="text-dark">
                  Register
                </Link>
              </li>
            </ul>
          </div>
          <div className="col-md-3 col-6 border-end border-primary">
            <ul>
              <li>
                <Link to="/shopping-cart" className="text-dark">
                  Cart
                </Link>
              </li>
              <li>
                <Link to="/order-history" className="text-dark">
                  Order History
                </Link>
              </li>
            </ul>
          </div>
          <div className="col-md-3 col-6 border-end border-primary">
            <ul>
              <li>
                <Link to="/address" className="text-dark">
                  Address
                </Link>
              </li>
              <li>
                <Link to="/reset-password" className="text-dark">
                  Password Reset
                </Link>
              </li>
            </ul>
          </div>
        </div> */}
        <div className="border-top">
          <div className="container p-3 text-center" style={{fontSize:'12px'}}>
            &#169; {SITE_TITLE} By{" "}
            <a
              href="https://innovilla.in"
              target="_blank"
              rel="noopener noreferrer"
              style={{ textDecoration: "none", color: "black" }}
            >
              Innovilla
            </a>
            {' '}| <Link to={'/terms-and-conditions'} className="text-dark">Terms & Conditions</Link>
            {' '}| <Link to={'/privacy-policy'} className="text-dark">Privacy Policy</Link>
            {' '}| <Link to={'/refund-and-shipping'} className="text-dark">Refund & Shipping</Link>
          </div>
        </div>
      </div>
    </>
  );
}
