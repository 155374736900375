import React from "react";
import { useEffect } from "react";
import "../../layout/common/common.css";
import EzBanner from "./sections/EzBanner";

export default function Pricing() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="mh-90 mb-5">
      {/* <EzBanner /> */}
      <div className="container pt-5">
        <div className="row m-0 mt-5">
          <div className="col-md-4">
            <div className="shadow bg-white rounded p-2 text-center">
              <h2>Basic</h2>
              <h5>
                <del>6500</del> Rs. 2500/Year
                <span className="bg-danger rounded text-white ps-1 pe-1">
                  In Offer
                </span>
              </h5>
            </div>
            <div className="rounded mt-3 bg-white">
              <table className="table table-striped">
                <tbody>
                  <tr>
                    <td>
                      <span className="text-bold">
                        {" "}
                        5 Days Free trial available
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td>Easy Page</td>
                  </tr>
                  <tr>
                    <td>Easy Inventory & Orders</td>
                  </tr>
                  <tr>
                    <td>Easy Point of Sale</td>
                  </tr>
                  <tr>
                    <td>Easy Invoicing</td>
                  </tr>
                  <tr>
                    <td>Easy Human Resource</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div className="col-md-4">
            <div className="shadow bg-white rounded p-2 text-center">
              <h2>Advance</h2>
              <h5>Coming soon...</h5>
            </div>
            <div className="rounded mt-3 bg-white">
              <table className="table table-striped">
                <tbody>
                  <tr>
                    <td>Coming Soon...</td>
                  </tr>
                  <tr>
                    <td>Coming Soon...</td>
                  </tr>
                  <tr>
                    <td>Coming Soon...</td>
                  </tr>
                  <tr>
                    <td>Coming Soon...</td>
                  </tr>
                  <tr>
                    <td>Coming Soon...</td>
                  </tr>
                  <tr>
                    <td>Coming Soon...</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div className="col-md-4">
            <div className="shadow bg-white rounded p-2 text-center">
              <h2>Pro</h2>
              <h5>Coming soon...</h5>
            </div>
            <div className="rounded mt-3 bg-white">
              <table className="table table-striped">
                <tbody>
                  <tr>
                    <td>Comin Soon...</td>
                  </tr>
                  <tr>
                    <td>Comin Soon...</td>
                  </tr>
                  <tr>
                    <td>Comin Soon...</td>
                  </tr>
                  <tr>
                    <td>Comin Soon...</td>
                  </tr>
                  <tr>
                    <td>Comin Soon...</td>
                  </tr>
                  <tr>
                    <td>Comin Soon...</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div className="p-3 shadow rounded bg-white">
          <p className="text-justify" style={{fontSize:14}}>
            Our SaaS (Software-as-a-Service) platform for business management,
            Biziness.in, has been designed to provide our customers with a
            comprehensive and affordable solution for all their business needs.
            We understand that in today's competitive environment, businesses
            need to stay ahead of the game and manage their operations
            efficiently to achieve success. That's why we have created a
            platform that offers an extensive range of features and
            functionalities, while keeping the pricing structure affordable for
            all types of businesses. <br />
            <br />
            We believe that every business deserves access to the latest
            technology and tools to streamline their operations and maximize
            productivity. With our platform, you can enjoy all the benefits of a
            modern, cloud-based solution, without breaking the bank. Our pricing
            structure has been designed to be flexible, with options to suit
            businesses of all sizes and budgets.
            <br />
            <br />
            Biziness.in offers a subscription-based pricing model, which means
            you only pay for what you use. We offer various subscription plans
            with different tiers of features and functionalities, so you can
            choose the plan that best suits your needs. Whether you are a small
            business or a large corporation, we have a plan that will fit your
            budget and requirements.
            <br />
            <br />
            Moreover, our pricing structure is transparent, with no hidden fees
            or charges. We believe in building long-term relationships with our
            customers, and that starts with being honest and upfront about our
            pricing. You can trust us to provide a cost-effective solution that
            will help you manage your business more efficiently.
            <br />
            <br />
            In conclusion, Biziness.in is the most affordable solution in the
            market. With a range of subscription plans to choose from and
            transparent pricing, we make it easy for businesses to access the
            latest technology and tools for managing their operations
            effectively. Try our platform today and experience the benefits of a
            modern, cloud-based solution at an affordable price.
            <br />
            <br />
          </p>
        </div>
      </div>
    </div>
  );
}
