import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { API_PATH, IMAGE_PATH } from "../../Config";
import Spinner from "../../layout/common/Spinner";

export default function ProductList() {
  const [spinner, setspinner] = useState(false)

  const params = useParams();
  const upath = params.id;
  const [pdata, setpdata] = useState([]);

  useEffect(() => {
    setspinner(true)
    axios
      .post(API_PATH + "ManageStoreFront.php", {
        action: "getStoreFrontProductData",
        upath: upath,
      })
      .then((r) => {
        if (r.data.status === "success") {
          setpdata(r.data.retval);
        }
        setspinner(false)
      });
  }, [upath]);

  return (
    <>
      <div className="container mt-5">
        {spinner === true && <Spinner/>}
        <div className="row m-0">
          {
            pdata.length>0 ? 
            pdata.map((d) => {
              return (
                <div key={d.id} className="col-6 col-md-4 col-lg-2 p-1">
                  <div
                    className=" bg-white shadow p-1"
                    style={{
                      border: "1px dashed #ffcc00",
                      borderRadius: "15px",
                    }}
                  >
                    <img
                      src={IMAGE_PATH + "./product/" + d.prod_image_link}
                      alt=""
                      width={"100%"}
                      style={{
                        borderRadius: "15px",
                        aspectRatio: '1 / 1',
                      }}
                    />
                    <div
                      style={{
                        whiteSpace: "nowrap",
                        width: "100%",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                      }}
                    >
                      <small
                        className="p-1"
                        style={{ textTransform: "capitalize" }}
                      >
                        {d.prod_full_name}
                      </small>
                    </div>
                    <div className="row">
                      <div
                        className="col-6 text-center text-danger"
                        style={{ fontSize: 14, fontWeight: "bold" }}
                      >
                        <del>{d.prod_mrp}</del>
                      </div>
                      <div
                        className="col-6 text-center text-success"
                        style={{ fontSize: 14, fontWeight: "bold" }}
                      >
                        {"\u20B9" + d.final_sale_price}
                      </div>
                    </div>
                    <Link to={'/view-product/'+upath+'/'+d.id}>
                      <button
                        className="btn btn-white border-primary form-control p-1"
                        style={{ borderRadius: "15px" }}
                      >
                        View More
                      </button>
                    </Link>
                  </div>
                </div>
              );
            })
            :
            <>
            <h4 className="text-secondary text-center">
              Product or service not disclosed by owner.
            </h4>
            <div className="text-center mt-5">
              <Link to={'/contact/'+upath}>
                <button className="btn bg-white rounded-pill border-primary shadow">Find contact details</button>
              </Link>
            </div>
            </>
          }
        </div>
      </div>
    </>
  );
}
