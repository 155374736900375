import React from "react";
import { Link } from "react-router-dom";

export default function NavlinksPublic() {
  return (
    <div className="navlinks">
      <ul className="w-100" style={{ justifyContent: "right" }}>
        <Link to="/">
          <li>
            {/* <i className="bi bi-house-heart-fill"></i>  */}
            Home
          </li>
        </Link>
        <Link to="/search-business">
          <li>
            {/* <i className="bi bi-search"></i>  */}
            Search Business
          </li>
        </Link>
        {/* <Link to="/job-board">
            <li>
              <i className="bi bi-award"></i> Jobs
            </li>
          </Link> */}
        {/* <Link to="/ez"><li><i className="bi bi-shop"></i> Store</li></Link> */}

        {/* <Link to="/blog"><li><i className="bi bi-newspaper"></i> Blog</li></Link>*/}
        <Link to="/partner-with-us">
          <li>
            {/* <i className="bi bi-briefcase"></i>  */}
            Partner Us
          </li>
        </Link>
        <li>
          {/* <i className="bi bi-info-circle"></i>  */}
          Info
          <ul className="child-nav">
            <Link to="/about-us">
              <li>
                <i className="bi bi-body-text"></i> About
              </li>
            </Link>
            <Link to="/pricing">
              <li>
                <i className="bi bi-cash-stack"></i> Pricing
              </li>
            </Link>
            {/* <Link to="/career">
                <li>
                  <i className="bi bi-person-workspace"></i> Career
                </li>
              </Link> */}
            <Link to="/faq">
              <li>
                <i className="bi bi-question-circle"></i> Faq
              </li>
            </Link>
          </ul>
        </li>
        <li>
          {/* <i className="bi bi-person-bounding-box"></i>  */}
          Login/Register
          <ul className="child-nav">
            <Link to="/login">
              <li>
                <i className="bi bi-door-open"></i> Login
              </li>
            </Link>
            <Link to="/user-registration">
              <li>
                <i className="bi bi-journal"></i> Register
              </li>
            </Link>
            <Link to="/reset-password">
              <li>
                <i className="bi bi-shield-lock"></i> Password Reset
              </li>
            </Link>
          </ul>
        </li>
        <a href="https://www.facebook.com/innovilla.in" target="_blank" rel="noopener noreferrer" style={{ marginTop: 10 }}>
          <i className="socialnav mt-2 ms-3 bi bi-facebook"></i>
        </a>
        <a href="https://www.linkedin.com/company/innovilla-private-limited" rel="noopener noreferrer" target="_blank" style={{ marginTop: 10 }}>
          <i className="socialnav ms-3 bi bi bi-linkedin"></i>
        </a>
        <a href="https://twitter.com/Innovillaoffice" target="_blank" rel="noopener noreferrer" style={{ marginTop: 10 }}>
          <i className="socialnav ms-3 bi bi bi-twitter"></i>
        </a>
        <a href="https://www.instagram.com/innovillaoffice" target="_blank" rel="noopener noreferrer"  style={{ marginTop: 10 }}>
          <i className="socialnav mt-2 ms-3 bi bi bi-instagram"></i>
        </a>
      </ul>
    </div>
  );
}
