import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { API_PATH, STATE_LIST, USER_ID, USER_TOKEN } from "../../Config";
import Spinner from "../../layout/common/Spinner";

export default function PosCheckout() {
  const closebtnref = useRef();
  const navigate = useNavigate();
  const [spinner, setspinner] = useState(false);
  const [invoicetype, setinvoicetype] = useState("non-gst");
  const [reversecharge, setreversecharge] = useState("n");
  const [buyercontact, setbuyercontact] = useState("");
  const [buyername, setbuyername] = useState("");
  const [buyerstate, setbuyerstate] = useState("");
  const [buyergstin, setbuyergstin] = useState("");
  const [dueamount, setdueamount] = useState(0);
  const [discount, setdiscount] = useState(0);
  const [roundoff, setroundoff] = useState(0.0);
  const [invoicetotal, setinvoicetotal] = useState(0);
  const [modeofpay, setmodeofpay] = useState("cash");
  const [paystatus, setpaystatus] = useState("paid");
  const [duedate, setduedate] = useState("");
  const [buyerdata, setbuyerdata] = useState([]);
  const [buyerid, setbuyerid] = useState("0");

  // modal data
  const [modalContact, setModalContact] = useState("");
  const [modalEmail, setModalEmail] = useState("");
  const [modalFname, setModalFname] = useState("");
  const [modalMname, setModalMname] = useState("");
  const [modalLname, setModalLname] = useState("");
  const [modalIsBusiness, setModalIsBusiness] = useState("n");
  const [modalBNameReadOnly, setModalBNameReadOnly] = useState(true);
  const [modalGstNum, setModalGstNum] = useState("");
  const [modalBusinessName, setModalBusinessName] = useState("");
  const [modalAddress, setModalAddress] = useState("");
  const [modalCity, setModalCity] = useState("");
  const [modalDistrict, setModalDistrict] = useState("");
  const [modalState, setModalState] = useState("");
  const [modalPincode, setModalPincode] = useState("");
  // modal data
  // get buyer contact on number change
  useEffect(() => {
    window.scrollTo(0, 0);
    setspinner(true);
    axios
      .post(API_PATH + "ManageSale.php", {
        userid: USER_ID,
        usertoken: USER_TOKEN,
        action: "getposordersummery",
      })
      .then((r) => {
        // console.log(r.data);
        if (r.data.status === "success") {
          setdueamount(r.data.retval.total ? r.data.retval.total : 0);
        }
        setspinner(false);
        //console.log(r.data);
      });
    setspinner(true);
    axios
      .post(API_PATH + "ManageSale.php", {
        action: "getcustomerdataonload",
        userid: localStorage.getItem("ez-userid"),
        usertoken: localStorage.getItem("ez-usertoken"),
      })
      .then((r) => {
        setspinner(false);
        // console.log(r.data)
        if (r.data.status === "success") {
          setbuyerdata(r.data.retval);
        }
      });
  }, []);
  
  const closesale = () => {
    if (buyercontact.length < 10) {
      alert("Contact number can not be less than 10 digit.");
    } else {
      setspinner(true);
      axios
        .post(API_PATH + "ManageSale.php", {
          userid: USER_ID,
          usertoken: USER_TOKEN,
          action: "closepossalebybusiness",
          invoicetype: invoicetype,
          reversecharge: reversecharge,
          buyercontact: buyercontact,
          buyerid: buyerid,
          buyername: buyername,
          buyerstate: buyerstate,
          buyergstin: buyergstin,
          dueamount: dueamount,
          discount: discount,
          roundoff: roundoff,
          invoicetotal: invoicetotal,
          modeofpay: modeofpay,
          paystatus: paystatus,
          duedate: duedate,
        })
        .then((r) => {
          console.log(r.data)
          if (r.data.status === "success") {
            alert("Sale closed successfully!");
            navigate("/point-of-sale");
          }
          setspinner(false);
        });
    }
  };

  const adddiscount = (e) => {
    setdiscount(e.target.value);
    let newtotal = dueamount - e.target.value - roundoff;
    if (newtotal < 0) {
      let newtotal = dueamount - roundoff;
      setdiscount(0);
      alert("Negative total not allowed, we've reset the discount to 0.");
      setinvoicetotal(newtotal);
    } else {
      setinvoicetotal(newtotal);
    }
  };
  const addroundoff = (e) => {
    setroundoff(e.target.value);
    let newtotal = dueamount - discount - e.target.value;
    if (newtotal < 0) {
      let newtotal = dueamount - discount;
      setroundoff(0);
      alert("Negative total not allowed, we've reset the round-off to 0.");
      setinvoicetotal(newtotal);
    } else {
      setinvoicetotal(newtotal);
    }
  };

  // add customer data to customer base
  const addCustomerToBase = () => {
    if (!modalContact || !modalFname) {
      alert(
        "Contact number and Name is mandatory to add customer to database."
      );
    } else {
      if (modalContact.length >= 10 && modalContact.length <= 12) {
        setspinner(true);
        axios
          .post(API_PATH + "ManageUser.php", {
            userid: localStorage.getItem("ez-userid"),
            userrole: localStorage.getItem("ez-userrole"),
            usertoken: localStorage.getItem("ez-usertoken"),
            action: "addcustomerbasefrompos",
            modalContact: modalContact,
            modalEmail: modalEmail,
            modalFname: modalFname,
            modalMname: modalMname,
            modalLname: modalLname,
            modalIsBusiness: modalIsBusiness,
            modalGstNum: modalGstNum,
            modalBusinessName: modalBusinessName,
            modalAddress: modalAddress,
            modalCity: modalCity,
            modalDistrict: modalDistrict,
            modalState: modalState,
            modalPincode: modalPincode,
          })
          .then((r) => {
            // console.log(r.data);
            closebtnref.current.click();
            setspinner(false);
          });
      } else {
        alert(
          "Invalid Contact Number, Minimum 10 and Maximum 12 Digits are allowed."
        );
      }
    }
  };
  const updatebuyerdetailonblur=(e)=>{
    setspinner(true)
    axios
      .post(API_PATH + "ManageSale.php", {
        action: "getcustomerdatabycontact",
        userid: localStorage.getItem("ez-userid"),
        usertoken: localStorage.getItem("ez-usertoken"),
        contact : e.target.value
      })
      .then((r) => {
        setspinner(false);
        // console.log(r.data)
        if (r.data.status === "success") {
          let d = r.data.retval
          let name = d.fname + ' ' +d.mname + ' ' +d.lname
          !name && (name = '')
          setbuyername(name)
          setbuyerstate(d.state?d.state:'')
          setbuyergstin(d.gstn_no?d.gstn_no:'')
          setbuyergstin(d.gstn_no?d.gstn_no:'')
        }
      });
  }

  return (
    <>
      <div className="mh-90 mt-5 pt-5 pb-5">
        {spinner === true && <Spinner />}
        <div className="container">
          <div className="shadow p-1 rounded">
            <h4>Generate Invoice</h4>
            <div className="row m-0 p-0">
              <div className="col-md-2 p-1">
                <label>Invoice type</label>
                <select
                  className="form-control"
                  value={invoicetype}
                  onChange={(e) => setinvoicetype(e.target.value)}
                >
                  <option value="non-gst" key="0">
                    Non Taxable
                  </option>
                  <option value="gst" key="1">
                    Taxable
                  </option>
                </select>
              </div>
              <div className="col-md-2 p-1">
                <label>Reverse Charge ?</label>
                <select
                  className="form-control"
                  value={reversecharge}
                  onChange={(e) => setreversecharge(e.target.value)}
                >
                  <option value="n" key="n">
                    No
                  </option>
                  <option value="y" key="y">
                    Yes
                  </option>
                </select>
              </div>
              <div className="col-md-4 p-1">
                <div className="d-flex justify-content-between">
                  <div>
                    <label>Buyer Contact</label>
                  </div>
                  <div>
                    <i
                      className="btn p-0 bi bi-person-plus-fill bg-primary ps-1 pe-1 rounded text-white"
                      type="button"
                      data-bs-toggle="modal"
                      data-bs-target="#staticBackdrop"
                    ></i>
                  </div>
                </div>
                <input
                  list="buyers"
                  name="buyer_contact"
                  id="buyer_contact"
                  type="number"
                  min={"0"}
                  step="1"
                  className="form-control"
                  value={buyercontact}
                  onChange={e=>setbuyercontact(e.target.value)}
                  onBlur={updatebuyerdetailonblur}
                />

                <datalist id="buyers">
                  {buyerdata.map((d) => {
                    return (
                      <option value={d.contact ? d.contact : ""} key={d.id} />
                    );
                  })}
                </datalist>
              </div>
              <div className="col-md-4 p-1">
                <label>Buyer Name</label>
                <input
                  type="text"
                  className="form-control"
                  value={buyername}
                  onChange={(e) => setbuyername(e.target.value)}
                />
              </div>
              <div className="col-md-4 p-1">
                <label>Buyer State</label>
                <select
                  className="form-control"
                  value={buyerstate}
                  onChange={(e) => setbuyerstate(e.target.value)}
                >
                  <option value="" key="0">
                    Select from list
                  </option>
                  {STATE_LIST.map((i) => {
                    return (
                      <option value={i} key={i}>
                        {i}
                      </option>
                    );
                  })}
                </select>
              </div>
              <div className="col-md-4 p-1">
                <label>Buyer Gstin</label>
                <input
                  type="text"
                  className="form-control"
                  value={buyergstin}
                  onChange={(e) => setbuyergstin(e.target.value)}
                />
              </div>
              <div className="col-md-4 p-1">
                <label>Due Amount</label>
                <input
                  type="number"
                  min="0"
                  className="form-control"
                  value={dueamount}
                  onChange={(e) => setdueamount(e.target.value)}
                  readOnly
                />
              </div>
              <div className="col-md-4 p-1">
                <label>Discount</label>
                <input
                  type="number"
                  min="0"
                  className="form-control"
                  value={discount}
                  onChange={adddiscount}
                />
              </div>
              <div className="col-md-4 p-1">
                <label>Round Off</label>
                <input
                  type="number"
                  min="0"
                  className="form-control"
                  value={roundoff}
                  onChange={addroundoff}
                />
              </div>
              <div className="col-md-4 p-1">
                <label>Invoice Total</label>
                <input
                  type="number"
                  min="0"
                  className="form-control"
                  value={invoicetotal}
                  onChange={(e) => setinvoicetotal(e.target.value)}
                  readOnly
                />
              </div>
              <div className="col-md-2 p-1">
                <label>Mode of Payment</label>
                <select
                  className="form-control"
                  value={modeofpay}
                  onChange={(e) => setmodeofpay(e.target.value)}
                >
                  <option value="cash" key="cash">
                    Cash
                  </option>
                  <option value="online" key="online">
                    Online
                  </option>
                </select>
              </div>
              <div className="col-md-2 p-1">
                <label>Payment Status</label>
                <select
                  className="form-control"
                  value={paystatus}
                  onChange={(e) => setpaystatus(e.target.value)}
                >
                  <option value="paid" key="paid">
                    Paid
                  </option>
                  <option value="pending" key="pending">
                    Pending
                  </option>
                </select>
              </div>
              {paystatus === "pending" && (
                <div className="col-md-3 p-1">
                  <label>Payment Due Date</label>
                  <input
                    type="date"
                    className="form-control"
                    value={duedate}
                    onChange={(e) => setduedate(e.target.value)}
                  />
                </div>
              )}
            </div>
            <div className="text-center mt-3">
              <button className="btn btn-primary" onClick={closesale}>
                Confirm Sale
              </button>
            </div>
          </div>
        </div>
      </div>

      <div
        className="modal fade"
        id="staticBackdrop"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex="-1"
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-lg">
          {spinner === true && <Spinner />}
          <div className="modal-content">
            <div className="modal-header p-1 ps-2 pe-2 bg-primary">
              <h5 className="modal-title text-white" id="staticBackdropLabel">
                Add customer record
              </h5>
              <button
                ref={closebtnref}
                type="button"
                className="btn-close bg-white text-white"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <div className="row m-0">
                <div className="col-md-6">
                  <label>Contact Number</label>
                  <input
                    type="number"
                    value={modalContact}
                    onChange={(e) => setModalContact(e.target.value)}
                    className={"form-control border-danger"}
                  />
                </div>
                <div className="col-md-6">
                  <label>Email Address</label>
                  <input
                    type="email"
                    value={modalEmail}
                    onChange={(e) => setModalEmail(e.target.value)}
                    className={"form-control"}
                  />
                </div>
                <div className="col-md-4">
                  <label>First Name</label>
                  <input
                    type="text"
                    value={modalFname}
                    onChange={(e) => setModalFname(e.target.value)}
                    className={"form-control border-danger"}
                  />
                </div>
                <div className="col-md-4">
                  <label>Middle Name</label>
                  <input
                    type="text"
                    value={modalMname}
                    onChange={(e) => setModalMname(e.target.value)}
                    className={"form-control"}
                  />
                </div>
                <div className="col-md-4">
                  <label>Last Name</label>
                  <input
                    type="text"
                    value={modalLname}
                    onChange={(e) => setModalLname(e.target.value)}
                    className={"form-control"}
                  />
                </div>
                <div className="col-md-4">
                  <label>Is Business</label>
                  <select
                    className="form-control"
                    value={modalIsBusiness}
                    onChange={(e) => {
                      setModalIsBusiness(e.target.value);
                      e.target.value === "y"
                        ? setModalBNameReadOnly(false)
                        : setModalBNameReadOnly(true);
                    }}
                  >
                    <option value="n">No</option>
                    <option value="y">Yes</option>
                  </select>
                </div>
                <div className="col-md-8">
                  <label>Business Name</label>
                  <input
                    type="text"
                    value={modalBusinessName}
                    placeholder={
                      modalBNameReadOnly === true ? "Not Applicable" : ""
                    }
                    onChange={(e) => setModalBusinessName(e.target.value)}
                    className={"form-control"}
                    readOnly={modalBNameReadOnly}
                  />
                </div>
                <div className="col-md-6">
                  <label>Gst Number</label>
                  <input
                    type="text"
                    value={modalGstNum}
                    onChange={(e) => setModalGstNum(e.target.value)}
                    className={"form-control"}
                  />
                </div>
                <div className="col-md-6">
                  <label>Address</label>
                  <input
                    type="text"
                    value={modalAddress}
                    onChange={(e) => setModalAddress(e.target.value)}
                    className={"form-control"}
                  />
                </div>
                <div className="col-md-4">
                  <label>City</label>
                  <input
                    type="text"
                    value={modalCity}
                    onChange={(e) => setModalCity(e.target.value)}
                    className={"form-control"}
                  />
                </div>
                <div className="col-md-4">
                  <label>District</label>
                  <input
                    type="text"
                    value={modalDistrict}
                    onChange={(e) => setModalDistrict(e.target.value)}
                    className={"form-control"}
                  />
                </div>
                <div className="col-md-4">
                  <label>State</label>
                  <select
                    className="form-control"
                    value={modalState}
                    onChange={(e) => setModalState(e.target.value)}
                  >
                    <option value="" key="0">
                      Select from list
                    </option>
                    {STATE_LIST.map((i) => {
                      return (
                        <option value={i} key={i}>
                          {i}
                        </option>
                      );
                    })}
                  </select>
                </div>
                <div className="col-md-4">
                  <label>Pincode</label>
                  <input
                    type="number"
                    min={0}
                    value={modalPincode}
                    onChange={(e) => setModalPincode(e.target.value)}
                    className={"form-control"}
                  />
                </div>
              </div>
            </div>
            <div className="modal-footer">
              {/* <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
              >
                Close
              </button> */}
              <button
                type="button"
                className="btn btn-primary"
                onClick={addCustomerToBase}
              >
                Update
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
