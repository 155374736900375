import axios from "axios";
import React, { useEffect, useState } from "react";
import { Navigate, useParams } from "react-router-dom";
import { API_PATH, USER_ID, USER_ROLE, USER_TOKEN } from "../../Config";
import Spinner from "../../layout/common/Spinner";
import OrderNav from "./OrderNav";

export default function OrderItemizedView() {
    
    const param = useParams()
    const [spinner, setspinner] = useState(false)
    const [dataLimit, setDataLimit ] = useState(100);
    const [orderBy, setOrderBy ] = useState('DESC');
    const [skipper, setSkipper ] = useState(0);
    const [fromDate, setFromDate ] = useState('');
    const [tillDate, setTillDate ] = useState('');
    const [dataStatus, setDataStatus] = useState('');
    const [dataset, setDataSet] = useState([]);
    const [changeDetector, setChangeDetector] = useState(0);
    
    const ProcessData = ()=>{
        setspinner(true)
        axios.post(
            API_PATH+`ManageCart.php`,{
                userid: USER_ID,
                userrole: USER_ROLE,
                usertoken: USER_TOKEN,
                datalimit : dataLimit,
                orderby : orderBy,
                fromdate : fromDate,
                tilldate : tillDate,
                skipval : skipper,
                action : 'itemizedcartviewbyorderid',
                orderid : param.orderid
            }
        ).then(r=>{
            console.log(r.data)
            if(r.data.status==='success'){
                setDataSet(r.data.retval);
                setDataStatus(r.data.status);
            }
            setspinner(false)
        });
    }
    
    // reduce skipper value only if skipper value is 
    // greater than zero else it will show blank page on 
    // next increament


    const reduceSkipper =(e)=>{
        if(skipper>0){
            setSkipper(skipper-dataLimit);
        }
    }
    

    // increase skipper value only if skipper value is 
    // greater than zero else it will show blank page on 
    // next increament


    const increaseSkipper =(e)=>{
        setSkipper(Number(skipper)+Number(dataLimit));
    }

    // list all dependency in dependency 
    //array to let filter work properly

    useEffect(()=>{
        ProcessData();
    },[dataLimit,orderBy,skipper,changeDetector]);
    
    if(!USER_ID || !USER_TOKEN){
        return(
            <Navigate to={'/login'}/>
        );
    }else{
        return(
            <>
            <div className="bg-light p-2 pt-5 mh-90">
                {spinner===true && <Spinner/>}
                <div>
                    <h5>Itemized View for Order Id::{param.orderid}</h5>

                </div>
                <div className="mt-4">
                    <OrderNav/>
                </div>
                    {/* <div className="row m-0 mt-2">
                        <div className="col-6 col-sm-3 col-md-2 p-1">
                            <label className="">Data Limit</label>
                            <select className="form-control p-1" onChange={e=>{setDataLimit(e.target.value)}}>
                                <option value={dataLimit} key="">{dataLimit}</option>
                                <option value="100" >100</option>
                                <option value="200" >200</option>
                                <option value="500" >500</option>
                                <option value="1000" >1000</option>
                                <option value="5000" >5000</option>
                            </select>
                        </div>
                        <div className="col-6 col-sm-3 col-md-2 p-1">
                            <label className="">Order By</label>
                            <select className="form-control p-1" onChange={e=>{setOrderBy(e.target.value)}}>
                                <option value={orderBy}>{orderBy}</option>
                                <option value="ASC">ASC</option>
                                <option value="DESC">DESC</option>
                            </select>
                        </div>
                        <div className="col col-sm-3 col-md-2 p-1">
                            <label className="">Date From</label>
                            <input type="date" className="form-control p-1" onChange={e=>{setFromDate(e.target.value)}}/>
                        </div>
                        <div className="col col-sm-3 col-md-2 p-1">
                            <label className="">Till Date</label>
                            <input type="date" className="form-control p-1" onChange={e=>{setTillDate(e.target.value)}}/>
                        </div>
                        <div className="col p-1">
                            <label>Navigate</label>
                            <div className="p-0">
                                <button className="btn bg-danger text-white p-1" onClick={reduceSkipper}>
                                    <i className="bi bi-arrow-bar-left"></i>
                                </button>
                                <button className="ms-2 btn bg-success text-white p-1" onClick={increaseSkipper}>
                                    <i className="bi bi-arrow-bar-right"></i>
                                </button>
                            </div>
                        </div>
                    </div> */}
                <div className="p-2" style={{overflowX:'scroll', fontSize:'12px'}}>
                    <table className=" rounded bg-white table table-responsive">
                        <thead>
                            <tr key="">
                                <th>Prod Name</th>
                                <th>Qty</th>
                                <th>Rate</th>
                                <th>Taxable Value</th>
                                <th>Tax</th>
                                <th>Tax Amount</th>
                                <th>Invoice Total</th>
                                <th>Order Status</th>
                                <th>Date</th>
                                <th>Time</th>
                            </tr>
                        </thead>
                        <tbody>
                            {dataStatus==='success' && 
                            dataset.map((data)=>{
                                return(
                                    <tr key={data.id}>
                                        {/* <td>
                                            <select id={data.id} onChange={changeOrderStatus}>
                                                <option value="" key="0"></option>
                                                <option value="in-transit">Shipped</option>
                                            </select>
                                        </td> */}
                                        <td>{data.prod_name}</td>
                                        <td>{data.prod_qty}</td>
                                        <td>{data.unit_rate}</td>
                                        <td>{data.taxable_value}</td>
                                        <td>{data.igst_rate}</td>
                                        <td>{data.igst_amount}</td>
                                        <td>{data.total_amount}</td>
                                        <td>{data.order_status}</td>
                                        <td>{data.date}</td>
                                        <td>{data.time}</td>
                                    </tr>
                                )
                            })
                            }
                            
                        </tbody>
                    </table>

                </div>
            </div>

            </>
        );
    }
}