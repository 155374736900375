import React from "react";

function AboutUsIntro() {
  return (
    <>
      <div className="container mt-5">
        <h4 className="text-center">
          Biziness is your business manager that helps you monitor your business
          on the go.
        </h4>
        <h5 className="mt-5 text-secondary text-justify">
          Every company is a startup unless it gets huge success and attention
          of investors. And in startups, the owner is everything from being
          staff to boss. In ininitial stages, the same person is responsible
          from hiring, managing, purchaging, stock maintainance, sales, employee
          retaintion and almost everything. It becomes really very difficult
          task to manage everything on their own without involving any
          investment in using available third party services or softwares. There
          is another problem, which is, that available services are scattered
          and too expensive for these startups to use. Biziness is here to help
          you overcome these situations and manage your business and all its
          requirements under one single roof, without involving any special
          investment.
        </h5>
        <h5 className="mt-5 text-secondary text-justify">
          Any business can register on Biziness and create a business profile
          which replaces the requirement of a website. The business can manage
          inventory, hire people, track their attendence, assign target, monitor
          leads, create a sales team with location base field work monitoring
          Post updates as blog to inform customers about their work patterns and
          upcoming offers, set up own mini call center to assist and track
          business with their existing or new clients and so on. Our aim is to
          provide an ecosystem to startups and small to medium scale businesses
          to overcome any kind of negative business situations.
        </h5>
        <h4 className="mt-5">Biziness is for MSMEs</h4>
        <h5 className="text-justify">
          "Biziness is an alternative to all those services which are made only
          for already established companies and are too much expensive, but can
          help SMEs to grow faster if the similar technology is available for
          them at affordable pricing. " - Founder and CEO, M Pandey
        </h5>
      </div>
      <div
        className="mt-5 pt-5 pb-4 container"
        style={{ borderTop: "5px dashed #ffcc00" }}
      >
        <h3>What you can do with Biziness</h3>
        <div className="mt-4 row m-0">
          <div className="col-md-6">
            <div className="mt-1 rounded bg-primary text-white p-1 text-center">
              Track performance of your Team
            </div>
            <div className="mt-1 rounded bg-light p-1 text-center">
              Manage inventory and generate sale
            </div>
            <div className="mt-1 rounded bg-primary text-white p-1 text-center">
              Setup your store front and receive orders
            </div>
            <div className="mt-1 rounded bg-ligh p-1 text-center">
              Keep track of sales team and generate leads
            </div>
            <div className="mt-1 rounded bg-primary text-white p-1 text-center">
              Craete job opening and Hire Candidates
            </div>
          </div>
          <div className="col-md-6">
            <div className="mt-1 rounded bg-light p-1 text-center">
              Setup your mini mall with EasyPos
            </div>
            <div className="mt-1 rounded bg-primary text-white p-1 text-center">
              Generate agreements and manage business docs
            </div>
            <div className="mt-1 rounded bg-light p-1 text-center">
              Generate agreements and manage business docs
            </div>
            <div className="mt-1 rounded bg-primary text-white p-1 text-center">
              Utility and bill pay and earn from it
            </div>
            <div className="mt-1 rounded bg-light p-1 text-center">
              Wallet transfers and so on....
            </div>
          </div>
        </div>
      </div>

      <div
        className="mt-5 pt-5 container"
        style={{ borderTop: "5px dashed #ffcc00" }}
      >
        <h3>Why Biziness.in</h3>
        <div className="mt-4 row m-0">
          <div className="col-6 col-md-3">
            <div className="p-2 text-center">
              <img
                src={require("../../../assets/img/responsive.png")}
                width="50%"
                alt=""
              />
              <h6 className="text-center mt-1">One platform for all devices</h6>
            </div>
          </div>
          <div className="col-6 col-md-3">
            <div className="p-2 text-center">
              <img
                src={require("../../../assets/img/earnings.png")}
                width="50%"
                alt=""
              />
              <h6 className="text-center mt-1">Earn while work program</h6>
            </div>
          </div>
          <div className="col-6 col-md-3">
            <div className="p-2 text-center">
              <img
                src={require("../../../assets/img/swords.png")}
                width="50%"
                alt=""
              />
              <h6 className="text-center mt-1">
                Everything in single platform
              </h6>
            </div>
          </div>
          <div className="col-6 col-md-3">
            <div className="p-2 text-center">
              <img
                src={require("../../../assets/img/sync.png")}
                width="50%"
                alt=""
              />
              <h6 className="text-center mt-1">Easy to use and data backups</h6>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default AboutUsIntro;
