import React, { useEffect } from "react";
import "../../layout/common/common.css";
import BusinessSearchBar from "./BusinessSearchBar";
import Seo from "../../layout/common/Seo";

export default function SearchBusiness() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="mh-90 pt-5 mb-5">
      <Seo
        title="Biziness : Search your business here"
        keywords="
          local search, travel, order food, business, website, mini website, 
          software, management, inventory, sale, pos,
          grocery, medicines, online shopping, book tickets, 
          movies, bus, flights, hotels, events, search plus services, 
          local businesses, online yellow pages, India trade directory, 
          city yellow pages, indian search engine,  customer care, customer support,
          Business management software, Cloud-based management platform,
          SaaS for business management, Enterprise resource planning, 
          Workflow automation, Project management tools,
          Customer relationship management, Sales and marketing automation,
          Financial management software,Inventory management system,
          Human resource management software,Collaboration tools for businesses,
          Productivity tools for businesses, Analytics and reporting software, 
          Business intelligence tools
        "
        description="Use this page to Search any business, connect with them and increase your productivity and trade."
        // canonical="https://biziness.in"
        ogurl="https://biziness.in/search-business"
        ogimage="https://biziness.in/static/media/bzlogo.515dd7e3.png"
        ogtype="website"
      />
      <BusinessSearchBar />
    </div>
  );
}
