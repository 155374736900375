import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { API_PATH, IMAGE_PATH } from "../../Config";
import axios from "axios";
import Spinner from "../../layout/common/Spinner";
import Seo from "../../layout/common/Seo";

export default function ProfileBanner() {
  const [spinner, setspinner] = useState(false);
  const params = useParams();
  const upath = params.id;
  const [bdata, setbdata] = useState([]);
  const [bannerimage, setbannerimage] = useState(
    require("./../../assets/img/ezbanner.jpg")
  );

  useEffect(() => {
    setspinner(true);
    axios
      .post(API_PATH + "ManageStoreFront.php", {
        action: "getStoreFrontData",
        upath: upath,
      })
      .then((r) => {
        console.log(r.data);
        if (r.data.status === "success") {
          setbdata(r.data.retval);
          setbannerimage(r.data.retval.b_banner_image);
        } else {
          alert("Something went wrong. We didn't find the data.");
        }
        setspinner(false);
      });
  }, [upath]);

  return (
    <>
      <Seo
        title={"Biziness : " + bdata.b_name}
        keywords="
          local search, travel, order food, business, website, mini website, 
          software, management, inventory, sale, pos,
          grocery, medicines, online shopping, book tickets, 
          movies, bus, flights, hotels, events, search plus services, 
          local businesses, online yellow pages, India trade directory, 
          city yellow pages, indian search engine,  customer care, customer support,
          Business management software, Cloud-based management platform,
          SaaS for business management, Enterprise resource planning, 
          Workflow automation, Project management tools,
          Customer relationship management, Sales and marketing automation,
          Financial management software,Inventory management system,
          Human resource management software,Collaboration tools for businesses,
          Productivity tools for businesses, Analytics and reporting software, 
          Business intelligence tools
        "
        description={
          bdata.b_name +
          ` is doing business online with biziness.in. Is your Business online?`
        }
        // canonical="https://biziness.in"
        ogurl={window.location.href}
        ogimage={ bdata.b_profile_image
          ? "https://biziness.in/business-profile/" + bdata.b_profile_image
          : require("./../../assets/img/bzlogo-transparent.png")}
        ogtype="website"
      />
      {spinner === true && <Spinner />}
      <div className="container-fluid">
        {/* business name */}
        <div
          className="fs-5 text-center text-bold text-secondary"
          style={{ textTransform: "uppercase" }}
        >
          {bdata.b_name}
        </div>
        <div className="bg-white shadow p-1 rounded ">
          {/* banner image */}
          <div
            style={{
              height: 220,
            }}
            className="rounded bg-white "
          >
            <img
              src={
                bannerimage !== ""
                  ? IMAGE_PATH + "./business-profile/" + bannerimage
                  : require("./../../assets/img/my-profile-bg.jpg")
              }
              alt=""
              style={{
                height: "100%",
                width: "100%",
                objectFit: "cover",
                zIndex: -22,
              }}
              className="rounded"
            />
          </div>
          {/* stats container */}
        </div>
        {/* profile image */}
        <div className="p-1 text-center text-md-start ps-md-5">
          <img
            src={
              bdata.b_profile_image
                ? IMAGE_PATH + "./business-profile/" + bdata.b_profile_image
                : require("./../../assets/img/bzlogo-transparent.png")
            }
            alt=""
            className="shadow rounded bg-white"
            width={"100%"}
            style={{
              height: 210,
              width: 210,
              marginTop: -90,
              aspectRatio: "1 / 1",
            }}
          />
        </div>
      </div>
      <div className="container-fluid rounded" style={{ marginTop: -120 }}>
        <div className="row m-0 mt-md-3">
          <div
            className="col-md-5 col-lg-4 col-xl-3  col-12"
            style={{ height: 140 }}
          ></div>
          <div className="col-md-7 col-lg-8 col-xl-9  col-12 p-0 text-center">
            <div className="row m-0">
              <div className="col-3 col-md-2 col-lg-1 p-1 text-center">
                <Link to={"/pay/" + upath}>
                  <img
                    src={require("../../assets/img/pay.png")}
                    alt=""
                    className="p-2 btn bg-white shadow"
                    width={"80%"}
                    style={{
                      objectFit: "contain",
                      borderRadius: "10px",
                      aspectRatio: "1 / 1",
                    }}
                  />
                  Pay Now
                </Link>
              </div>
              <div className="col-3 col-md-2 col-lg-1 p-1 text-center">
                <Link to={"/intro/" + upath}>
                  <img
                    src={require("../../assets/img/about.png")}
                    alt=""
                    className="p-2 btn bg-white shadow"
                    width={"80%"}
                    style={{
                      objectFit: "contain",
                      borderRadius: "10px",
                      aspectRatio: "1 / 1",
                    }}
                  />
                  About Us
                </Link>
              </div>
              <div className="col-3 col-md-2 col-lg-1 p-1 text-center">
                <Link to={"/contact/" + upath}>
                  <img
                    src={require("../../assets/img/mail.png")}
                    alt=""
                    className="p-2 btn bg-white shadow"
                    width={"80%"}
                    style={{
                      objectFit: "contain",
                      borderRadius: "10px",
                      aspectRatio: "1 / 1",
                    }}
                  />
                  Contact
                </Link>
              </div>
              <div className="col-3 col-md-2 col-lg-1 p-1 text-center">
                <Link to={"/blog/" + upath}>
                  <img
                    src={require("../../assets/img/blogging.png")}
                    alt=""
                    className="p-2 btn bg-white shadow"
                    width={"80%"}
                    style={{
                      objectFit: "contain",
                      borderRadius: "10px",
                      aspectRatio: "1 / 1",
                    }}
                  />
                  Blog
                </Link>
              </div>
              <div className="col-3 col-md-2 col-lg-1 p-1 text-center">
                <Link to={"/team/" + upath}>
                  <img
                    src={require("../../assets/img/united.png")}
                    alt=""
                    className="p-2 btn bg-white shadow"
                    width={"80%"}
                    style={{
                      objectFit: "contain",
                      borderRadius: "10px",
                      aspectRatio: "1 / 1",
                    }}
                  />
                </Link>
                Team
              </div>
              <div className="col-3 col-md-2 col-lg-1 p-1 text-center">
                <Link to={"/gallery/" + upath}>
                  <img
                    src={require("../../assets/img/gallery.png")}
                    alt=""
                    className="p-2 btn bg-white shadow"
                    width={"80%"}
                    style={{
                      objectFit: "contain",
                      borderRadius: "10px",
                      aspectRatio: "1 / 1",
                    }}
                  />
                </Link>
                Gallery
              </div>
              <div
                className="col-3 col-md-2 col-lg-1 p-1 text-center"
                data-bs-toggle="modal"
                data-bs-target="#modal_social_media_links"
              >
                <img
                  src={require("../../assets/img/social-media.png")}
                  alt=""
                  className="p-2 btn bg-white shadow"
                  width={"80%"}
                  style={{
                    objectFit: "contain",
                    borderRadius: "10px",
                    aspectRatio: "1 / 1",
                  }}
                />
                <div>Social</div>
              </div>
              <div className="col-3 col-md-2 col-lg-1 p-1 text-center">
                <a
                  href={"https://wa.me/91" + bdata.b_whatsapp}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src={require("../../assets/img/whatsapp.png")}
                    alt=""
                    className="p-2 btn bg-white shadow"
                    width={"80%"}
                    style={{
                      objectFit: "contain",
                      borderRadius: "10px",
                      aspectRatio: "1 / 1",
                    }}
                  />
                </a>
                <div>Whatsapp</div>
              </div>
              <div
                className="col-3 col-md-2 col-lg-1 p-1 text-center"
                data-bs-toggle="modal"
                data-bs-target="#modal_share_page"
              >
                <img
                  src={require("../../assets/img/sharing.png")}
                  alt=""
                  className="p-2 btn bg-white shadow"
                  width={"80%"}
                  style={{
                    objectFit: "contain",
                    borderRadius: "10px",
                    aspectRatio: "1 / 1",
                  }}
                />
                <div>Share</div>
              </div>
              <div className="col-3 col-md-2 col-lg-1 p-1 text-center">
                <Link to={"/qr-code/" + upath}>
                  <img
                    src={require("../../assets/img/qr-code.png")}
                    alt=""
                    className="p-2 btn bg-white shadow"
                    width={"80%"}
                    style={{
                      objectFit: "contain",
                      borderRadius: "10px",
                      aspectRatio: "1 / 1",
                    }}
                  />
                </Link>
                Get Qr
              </div>
              <div className="col-3 col-md-2 col-lg-1 p-1 text-center">
                <Link to={"/career/" + upath}>
                  <img
                    src={require("../../assets/img/job-search.png")}
                    alt=""
                    className="p-2 btn bg-white shadow"
                    width={"80%"}
                    style={{
                      objectFit: "contain",
                      borderRadius: "10px",
                      aspectRatio: "1 / 1",
                    }}
                  />
                </Link>
                Career
              </div>
              <div className="col-3 col-md-2 col-lg-1 p-1 text-center">
                <Link to={"/career/" + upath}>
                  <img
                    src={require("../../assets/img/coming-soon.png")}
                    alt=""
                    className="p-2 btn bg-white shadow"
                    width={"80%"}
                    style={{
                      objectFit: "contain",
                      borderRadius: "10px",
                      aspectRatio: "1 / 1",
                    }}
                  />
                </Link>
                ........
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Share Social Link  */}
      <div
        className="modal fade"
        id="modal_social_media_links"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex="-1"
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title fs-5" id="staticBackdropLabel">
                Share it everywhere
              </h1>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <div className="row m-0">
                <div className="col-3  p-1 text-center">
                  <a
                    href={bdata.facebook}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      src={require("../../assets/img/facebook.png")}
                      alt=""
                      className="p-2"
                      width={"80%"}
                      style={{
                        objectFit: "contain",
                        borderRadius: "10px",
                        aspectRatio: "1 / 1",
                      }}
                    />
                  </a>
                  Facebook
                </div>
                <div className="col-3 p-1 text-center">
                  <a
                    href={bdata.twitter}
                    target={"_blank"}
                    rel="noopener noreferrer"
                  >
                    <img
                      src={require("../../assets/img/twitter.png")}
                      alt=""
                      className="p-2"
                      width={"80%"}
                      style={{
                        objectFit: "contain",
                        borderRadius: "10px",
                        aspectRatio: "1 / 1",
                      }}
                    />
                  </a>
                  Twitter
                </div>
                <div className="col-3  p-1 text-center">
                  <a
                    href={bdata.instagram}
                    target={"_blank"}
                    rel="noopener noreferrer"
                  >
                    <img
                      src={require("../../assets/img/instagram.png")}
                      alt=""
                      className="p-2"
                      width={"80%"}
                      style={{
                        objectFit: "contain",
                        borderRadius: "10px",
                        aspectRatio: "1 / 1",
                      }}
                    />
                  </a>
                  Instagram
                </div>
                <div className="col-3  p-1 text-center">
                  <a
                    href={bdata.google_business}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      src={require("../../assets/img/linkedin.png")}
                      alt=""
                      className="p-2"
                      width={"80%"}
                      style={{
                        objectFit: "contain",
                        borderRadius: "10px",
                        aspectRatio: "1 / 1",
                      }}
                    />
                  </a>
                  <div>Linked In</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="modal fade"
        id="modal_share_page"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex="-1"
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title fs-5" id="staticBackdropLabel">
                Share it everywhere
              </h1>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <div className="row m-0">
                <div className="col-4 text-center">
                  <a
                    href={
                      "https://www.facebook.com/sharer/sharer.php?u=https://biziness.in/ez/" +
                      upath
                    }
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      src={require("../../assets/img/facebook.png")}
                      style={{ width: "50%" }}
                      alt=""
                    />
                  </a>
                </div>
                <div className="col-4 text-center">
                  <a
                    href={
                      "https://twitter.com/intent/tweet?text=" +
                      encodeURI(
                        "My business is now on Biziness.in, please check my profile here " +
                          window.location.href
                      )
                    }
                    target={"_blank"}
                    rel="noopener noreferrer"
                  >
                    <img
                      src={require("../../assets/img/twitter.png")}
                      style={{ width: "50%" }}
                      alt=""
                    />
                  </a>
                </div>
                <div className="col-4 text-center">
                  <a
                    href={
                      "whatsapp://send?text=" +
                      encodeURI(
                        "My business is now on Biziness.in, please check my profile here at https://biziness.in/ez/" +
                          upath
                      )
                    }
                    data-action="share/whatsapp/share"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      src={require("../../assets/img/whatsapp.png")}
                      style={{ width: "50%" }}
                      alt=""
                    />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
