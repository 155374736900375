import React from "react";
import { Link } from "react-router-dom";

export default function GoToHome(prop) {
  return (
    <div>
      <Link to={prop.link}>
        <i className="bi bi-arrow-90deg-left btn-primary btn shadow"></i> Go to Main Page
      </Link>
    </div>
  );
}
