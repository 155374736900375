import React, { useEffect } from "react";
import "../../layout/common/common.css";
import FaqAccordian from "./sections/FaqAccordian";
export default function Faq() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="mh-90 mb-5">
      <FaqAccordian />
    </div>
  );
}
