import axios from "axios";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { API_PATH } from "../../Config";

export default function Intro() {
  // schedule a meeting
  const [meetingwithperson, setmeetingwithperson] = useState("");
  const [meetingwithcompany, setmeetingwithcompany] = useState("");
  const [meetingwithcontact, setmeetingwithcontact] = useState("");
  const [meetingwithemail, setmeetingwithemail] = useState("");
  const [meetingwithbusinessdetail, setmeetingwithbusinessdetail] = useState(
    ""
  );
  const [statusclass, setstatusclass] = useState("");
  const [statusmsg, setstatusmsg] = useState("");
  const [knowmore, setknowmore] = useState(false);
  const schedulemeeting = (e) => {
    e.preventDefault();
    if (
      !meetingwithperson ||
      !meetingwithcompany ||
      !meetingwithcontact ||
      !meetingwithemail ||
      !meetingwithbusinessdetail
    ) {
      setstatusclass("alert alert-danger");
      setstatusmsg("All fields are important, please fill up carefully.");
    } else {
      if (meetingwithcontact.length < 10) {
        setstatusclass("alert alert-danger");
        setstatusmsg("Contact number can not be less than 10 digit.");
      } else if (meetingwithcompany.length < 3) {
        setstatusclass("alert alert-danger");
        setstatusmsg("Company name can not be less than 3 letters.");
      } else {
        setstatusclass("");
        setstatusmsg("");
        axios
          .post(API_PATH + "Action.php", {
            meetingwithperson: meetingwithperson,
            meetingwithcompany: meetingwithcompany,
            meetingwithcontact: meetingwithcontact,
            meetingwithemail: meetingwithemail,
            meetingwithbusinessdetail: meetingwithbusinessdetail,
            requestfrom: "user-prospect",
            action: "schedulemeeting",
          })
          .then((r) => {
            if (r.data.status === "success") {
              setmeetingwithperson("");
              setmeetingwithcompany("");
              setmeetingwithcontact("");
              setmeetingwithemail("");
              setmeetingwithbusinessdetail("");
              setstatusclass("alert alert-success");
              setstatusmsg(
                "Contact request sent successfully. Our team will reach you soon."
              );
            } else {
              setstatusclass("alert alert-danger");
              if (r.data.error === "existing") {
                setstatusmsg("This contact has alredy requested to connect. ");
              }
            }
            setTimeout(() => {
              setstatusclass("");
              setstatusmsg("");
            }, 5000);
          });
      }
    }
  };
  const updateknowmore = () => {
    if (knowmore === true) {
      setknowmore(false);
    } else {
      setknowmore(true);
    }
  };

  return (
    <>
      <div className="mt-4 pb-4 mh-90">
        <div className="row bg-white rounded m-0 shadow"> 
          <div className="col-md-6 p-4">
            <h1
              className="text-dark mt-md-4"
              style={{ fontSize: "45px", fontWeight: "bold" }}
            >
              Bringing Business Online.
            </h1>
            <h3 className="text-secondary">Made easy with Biziness.in !</h3>
            {/* nav on mobile */}
            <div className="row m-0 mt-5 d-sm-none">
              <div className="col-12 col-md-4 p-1">
                <button
                  className="btn form-control bg-white border rounded-pill shadow text-dark fs-6"
                  style={{ fontWeight: "bold" }}
                  data-bs-toggle="modal"
                  data-bs-target="#modal_meeting"
                >
                  Set a meeting
                </button>
              </div>
              <div className="col-12 col-md-4 p-1">
                <Link to={"/user-registration"}>
                  <button
                    className="btn form-control btn-primary rounded-pill shadow fs-6"
                    style={{ fontWeight: "bold" }}
                  >
                    Register Now
                  </button>
                </Link>
              </div>
              <div className="col-12 col-md-4 p-1">
                <Link to={"/search-business"}>
                  <button
                    className="btn form-control bg-white border rounded-pill shadow text-dark fs-6"
                    style={{ fontWeight: "bold" }}
                  >
                    Search Business
                  </button>
                </Link>
              </div>
            </div>
            <p className="mt-4 text-justify text-secondary">
              Create your Easy Business Profile and expand your business online.
              Use is as your full fledged website, Inventory Manager, Product
              Catalogue, Pos Solution, Business Identity and much more. Generate
              your Business QR Code and make your business available with a
              single scan. Utilize various modules according to your need and
              monitor your business online.
            </p>
            <h4 className="text-primary">Powered By AI</h4>

            {/* desktop navigation */}
            <div className="d-none d-sm-block">
              <div className="row m-0 mt-5 ">
                <div className="col-12 col-md-4 p-1">
                  <button
                    className="btn form-control bg-white border rounded-pill shadow text-dark fs-6"
                    style={{ fontWeight: "bold" }}
                    data-bs-toggle="modal"
                    data-bs-target="#modal_meeting"
                  >
                    Set a meeting
                  </button>
                </div>
                <div className="col-12 col-md-4 p-1">
                  <Link to={"/user-registration"}>
                    <button
                      className="btn btn-primary form-control text-white border rounded-pill shadow text-dark fs-6"
                      style={{ fontWeight: "bold" }}
                    >
                      Register Now
                    </button>
                  </Link>
                </div>
                <div className="col-12 col-md-4 p-1">
                  <Link to={"/search-business"}>
                    <button
                      className="btn form-control bg-white border shadow rounded-pill text-dark fs-6"
                      style={{ fontWeight: "bold" }}
                    >
                      Search Business
                    </button>
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-6 p-0">
            <img
              src={require("./../../assets/img/intronew.jpg")}
              className="w-100"
            />
          </div>
        </div>
        {/* <div className="container p-2 mt-4 bg-white rounded shadow pt-4 pb-4">
          <h3>List your business in 24 Primary and 6500+ sub categories.</h3>
          <p className="text-justify">
            Listing your business in a comprehensive online directory is crucial
            to enhance your online presence and reach out to a wider audience.
            By listing your business in 24 primary and 6500+ sub-categories, you
            can ensure that your business is easily found by potential customers
            looking for businesses in your niche. This extensive categorization
            also allows you to reach out to highly targeted audiences, resulting
            in better engagement and higher conversion rates. In this article,
            we will discuss the benefits of listing your business in such an
            online directory and how it can help you take your business to the
            next level.
          </p>
          <div className="row mt-5  pt-2 pb-2 rounded" style={{ fontSize: 12 }}>
            <div className="text-center col-4 col-md-1 p-1">
              <div>
                <img
                  src={require("./../../assets/icons/bullfinch.png")}
                  className="w-50"
                  alt=""
                />
              </div>
              <div>Animals</div>
            </div>
            <div className="text-center col-4 col-md-1 p-1">
              <div>
                <img
                  src={require("./../../assets/icons/paint-palette.png")}
                  className="w-50"
                  alt=""
                />
              </div>
              <div>Arts</div>
            </div>
            <div className="text-center col-4 col-md-1 p-1">
              <div>
                <img
                  src={require("./../../assets/icons/baby-boy.png")}
                  className="w-50"
                  alt=""
                />
              </div>
              <div>Baby</div>
            </div>
            <div className="text-center col-4 col-md-1 p-1">
              <div>
                <img
                  src={require("./../../assets/icons/factory.png")}
                  className="w-50"
                  alt=""
                />
              </div>
              <div>Business</div>
            </div>
            <div className="text-center col-4 col-md-1 p-1">
              <div>
                <img
                  src={require("./../../assets/icons/camera.png")}
                  className="w-50"
                  alt=""
                />
              </div>
              <div>Optics</div>
            </div>
            <div className="text-center col-4 col-md-1 p-1">
              <div>
                <img
                  src={require("./../../assets/icons/clothes-hanger.png")}
                  className="w-50"
                  alt=""
                />
              </div>
              <div>Clothes</div>
            </div>
            <div className="text-center col-4 col-md-1 p-1">
              <div>
                <img
                  src={require("./../../assets/icons/electronics.png")}
                  className="w-50"
                  alt=""
                />
              </div>
              <div>Electronics</div>
            </div>
            <div className="text-center col-4 col-md-1 p-1">
              <div>
                <img
                  src={require("./../../assets/icons/diet.png")}
                  className="w-50"
                  alt=""
                />
              </div>
              <div>Food</div>
            </div>
            <div className="text-center col-4 col-md-1 p-1">
              <div>
                <img
                  src={require("./../../assets/icons/furniture.png")}
                  className="w-50"
                  alt=""
                />
              </div>
              <div>Furniture</div>
            </div>
            <div className="text-center col-4 col-md-1 p-1">
              <div>
                <img
                  src={require("./../../assets/icons/tools.png")}
                  className="w-50"
                  alt=""
                />
              </div>
              <div>Hardware</div>
            </div>
            <div className="text-center col-4 col-md-1 p-1">
              <div>
                <img
                  src={require("./../../assets/icons/healthcare.png")}
                  className="w-50"
                  alt=""
                />
              </div>
              <div>Health</div>
            </div>
            <div className="text-center col-4 col-md-1 p-1">
              <div>
                <img
                  src={require("./../../assets/icons/gardening.png")}
                  className="w-50"
                  alt=""
                />
              </div>
              <div>Home</div>
            </div>
            <div className="text-center col-4 col-md-1 p-1">
              <div>
                <img
                  src={require("./../../assets/icons/gardening.png")}
                  className="w-50"
                  alt=""
                />
              </div>
              <div>Bags</div>
            </div>
            <div className="text-center col-4 col-md-1 p-1">
              <div>
                <img
                  src={require("./../../assets/icons/media.png")}
                  className="w-50"
                  alt=""
                />
              </div>
              <div>Media</div>
            </div>
            <div className="text-center col-4 col-md-1 p-1">
              <div>
                <img
                  src={require("./../../assets/icons/office.png")}
                  className="w-50"
                  alt=""
                />
              </div>
              <div>Office</div>
            </div>
            <div className="text-center col-4 col-md-1 p-1">
              <div>
                <img
                  src={require("./../../assets/icons/ceremonial.png")}
                  className="w-50"
                  alt=""
                />
              </div>
              <div>Ceremonial</div>
            </div>
            <div className="text-center col-4 col-md-1 p-1">
              <div>
                <img
                  src={require("./../../assets/icons/software.png")}
                  className="w-50"
                  alt=""
                />
              </div>
              <div>Software</div>
            </div>
            <div className="text-center col-4 col-md-1 p-1">
              <div>
                <img
                  src={require("./../../assets/icons/sports.png")}
                  className="w-50"
                  alt=""
                />
              </div>
              <div>Sporting</div>
            </div>
            <div className="text-center col-4 col-md-1 p-1">
              <div>
                <img
                  src={require("./../../assets/icons/toys.png")}
                  className="w-50"
                  alt=""
                />
              </div>
              <div>Toys</div>
            </div>
            <div className="text-center col-4 col-md-1 p-1">
              <div>
                <img
                  src={require("./../../assets/icons/fleet.png")}
                  className="w-50"
                  alt=""
                />
              </div>
              <div>Vehicles</div>
            </div>
            <div className="text-center col-4 col-md-1 p-1">
              <div>
                <img
                  src={require("./../../assets/icons/budget.png")}
                  className="w-50"
                  alt=""
                />
              </div>
              <div>Finance</div>
            </div>
            <div className="text-center col-4 col-md-1 p-1">
              <div>
                <img
                  src={require("./../../assets/icons/hospitality.png")}
                  className="w-50"
                  alt=""
                />
              </div>
              <div>Hospitality</div>
            </div>
            <div className="text-center col-4 col-md-1 p-1">
              <div>
                <img
                  src={require("./../../assets/icons/resourcing.png")}
                  className="w-50"
                  alt=""
                />
              </div>
              <div>Resourcing</div>
            </div>
            <div className="text-center col-4 col-md-1 p-1">
              <div>
                <img
                  src={require("./../../assets/icons/manufacturing.png")}
                  className="w-50"
                  alt=""
                />
              </div>
              <div>Production</div>
            </div>
          </div>
        </div>
        <div className="container p-2 mt-4 bg-white rounded pt-4 pb-4 shadow">
          <h3>
            Startup India, StartIn UP recongnized and REC Banda supported
            Start-Up
          </h3>
          <p className="text-justify">
            In recent years, the Indian government has taken significant steps
            to promote entrepreneurship and support start-ups in the country. As
            a start-up that has been shortlisted and recognized by both Startup
            India and StartIn UP, we have been able to take advantage of the
            resources and opportunities provided by these programs. With the
            help of these initiatives, we are able to grow and succeed in a
            competitive business environment. We believe that these government
            initiatives have played a crucial role in creating a more conducive
            environment for start-ups to thrive and contribute to India's
            economic growth.
          </p>
          <div className="row mt-5 pt-2 pb-2 rounded" style={{ fontSize: 12 }}>
            <div className="text-center col-6 col-md-3 p-1">
              <div className="">
                <img
                  src={require("../../assets/img/startup-india_large.jpg")}
                  className="w-50"
                  alt=""
                  style={{ aspectRatio: "1/1", objectFit: "contain" }}
                />
                
              </div>
            </div>
            <div className="text-center col-6 col-md-3 p-1">
              <div className="">
                <img
                  src={require("../../assets/img/startinup.jpg")}
                  className="w-50"
                  alt=""
                  style={{ aspectRatio: "1/1", objectFit: "contain" }}
                />
                
              </div>
            </div>
            <div className="text-center col-6 col-md-3 p-1">
              <div className="">
                <img
                  src={require("../../assets/img/rec-banda-logo.png")}
                  className="w-50"
                  alt=""
                  style={{ aspectRatio: "1/1", objectFit: "contain" }}
                />
                
              </div>
            </div>
            <div className="text-center col-6 col-md-3 p-1">
              <div className="">
                <img
                  src={require("../../assets/img/innovilla-logo.png")}
                  className="w-50"
                  alt=""
                  style={{ aspectRatio: "1/1", objectFit: "contain" }}
                />
                
              </div>
            </div>
          </div>
        </div>
        <div className=" mt-5 text-center">
          <h2
            className="text-secondary mt-md-4 mb-5"
            style={{ fontSize: "45px", fontWeight: "bold" }}
          >
            Want to know more ?
          </h2>
          <i
            className="bi bi-arrows-fullscreen btn border bg-white  shadow"
            style={{ fontWeight: "bold", fontStyle: "normal" }}
            onClick={updateknowmore}
          >
            {knowmore === true ? " Click To Hide" : " Click to Expand"}
          </i>
        </div> */}
      </div>

      {/* {knowmore === true && (
        <>
          <div className="container">
            <div className="row m-0">
              <div className="col-md-6 pt-4">
                <div className="row  rounded">
                  <div className="col-6 col-lg-4 p-1  rounded">
                    <div className="form-control btn bg-white shadow rounded p-1 text-center">
                      <i className="bi bi-file-bar-graph text-bold"></i>
                      <br />
                      Easy Pages
                    </div>
                  </div>
                  <div className="col-6 col-lg-4 p-1  rounded">
                    <div className="form-control btn bg-white shadow  rounded p-1 text-center">
                      <i className="bi bi-shop"></i>
                      <br />
                      Easy Store
                    </div>
                  </div>
                  <div className="col-6 col-lg-4 p-1  rounded">
                    <div className="form-control btn bg-white shadow  rounded p-1 text-center">
                      <i className="bi bi-bullseye"></i>
                      <br />
                      Easy Leads
                    </div>
                  </div>
                  <div className="col-6 col-lg-4 p-1  rounded">
                    <div className="form-control btn bg-white shadow  rounded p-1 text-center">
                      <i className="bi bi-basket"></i>
                      <br />
                      Easy Orders
                    </div>
                  </div>
                  <div className="col-6 col-lg-4 p-1  rounded">
                    <div className="form-control btn bg-white  shadow  rounded p-1 text-center">
                      <i className="bi bi-list-columns"></i>
                      <br />
                      Easy Inventory
                    </div>
                  </div>
                  <div className="col-6 col-lg-4 p-1  rounded">
                    <div className="form-control btn bg-white  shadow  rounded p-1 text-center">
                      <i className="bi bi-pc-display-horizontal"></i>
                      <br />
                      Easy Point of Sale
                    </div>
                  </div>
                  <div className="col-6 col-lg-4 p-1  rounded">
                    <div className="form-control btn bg-white  shadow  rounded p-1 text-center">
                      <i className="bi bi-receipt"></i>
                      <br />
                      Easy Invoicing
                    </div>
                  </div>
                  <div className="col-6 col-lg-4 p-1  rounded">
                    <div className="form-control btn bg-white  shadow  rounded p-1 text-center">
                      <i className="bi bi-clipboard-data"></i>
                      <br />
                      Easy Reports
                    </div>
                  </div>
                  <div className="col-6 col-lg-4 p-1  rounded">
                    <div className="form-control btn bg-white  shadow  rounded p-1 text-center">
                      <i className="bi bi-envelope"></i>
                      <br />
                      Easy HR
                    </div>
                  </div>
                  <div className="col-6 col-lg-4 p-1  rounded">
                    <div className="form-control btn bg-white  shadow  rounded p-1 text-center">
                      <i className="bi bi-telephone-forward"></i>
                      <br />
                      Easy Call Center
                    </div>
                  </div>
                  <div className="col-6 col-lg-4 p-1  rounded">
                    <div className="form-control btn bg-white  shadow  rounded p-1 text-center">
                      <i className="bi bi-wallet"></i>
                      <br />
                      Easy Wallet
                    </div>
                  </div>
                  <div className="col-6 col-lg-4 p-1  rounded">
                    <div className="form-control btn bg-white  shadow  rounded p-1 text-center">
                      <i className="bi bi-credit-card"></i>
                      <br />
                      Easy Bill Pay
                    </div>
                  </div>
                  <div className="col-6 col-lg-4 p-1  rounded">
                    <div className="form-control btn bg-white  shadow  rounded p-1 text-center">
                      <i className="bi bi-file-earmark-zip"></i>
                      <br />
                      Easy Docs
                    </div>
                  </div>
                  <div className="col-6 col-lg-4 p-1  rounded">
                    <div className="form-control btn bg-white  shadow  rounded p-1 text-center">
                      <i className="bi bi-graph-up-arrow"></i>
                      <br />
                      Easy Analytics
                    </div>
                  </div>
                  <div className="col-6 col-lg-4 p-1  rounded">
                    <div className="form-control btn bg-white  shadow  rounded p-1 text-center">
                      <i className="bi bi-infinity"></i>
                      <br />
                      And so on...
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-6 pt-4">
                <h2
                  className="text-dark text-bold"
                  style={{ fontSize: "35px" }}
                >
                  What you get with us.
                </h2>
                <p className="mt-5 text-justify">
                  You get various service under one roof to manage your business
                  and collaborate with your team. Biziness.in helps you
                  establish your office online and allows you to manage and
                  track your business and employees on the go. You can create
                  business profile which will fulfill the need of dedicated
                  website. You can upload product, manage inventory, receive
                  orders online, create invoices, keep track of sale, hire
                  employees, track attendence, maintain office docs and even
                  setup a mini call center for your offfice and lot more than
                  these.
                </p>
              </div>
            </div>
          </div>
          <div className="container mt-5 pt-5">
            <div>
              <h2
                className="text-center text-bold"
                style={{ fontSize: "45px" }}
              >
                How it works
              </h2>
            </div>
          </div>
          <div className="container">
            <div className="row m-0">
              <div className="col-md-6 pt-5">
                <h2
                  className="text-dark text-bold"
                  style={{ fontSize: "35px" }}
                >
                  Create your business profile
                </h2>
                <p className="mt-5 text-justify">
                  You can start using Biziness by getting registered over and
                  creating business profile. A list of business uses will appear
                  and will be available to use without any cost for ever. You
                  can customize the business profile by adding more useful
                  modules which will help enriching your profile as fully
                  established website. By setting up customized link, you can
                  share the page over internet to help your clients and
                  employees interact over this platform. This will even generate
                  a foot print for your employees and will help them get
                  recognized as your existing or ex employees.
                </p>
              </div>
              <div className="col-md-6 pt-5">
                <img src="./assets/img/intro3.svg" width="100%" alt="" />
              </div>
            </div>
          </div>
          <div className="container mt-5">
            <div className="row m-0">
              <div className="col-md-6 order-md-2 pt-5">
                <h2
                  className="text-dark text-bold"
                  style={{ fontSize: "35px" }}
                >
                  Manage your business needs
                </h2>
                <p className="mt-5 text-justify">
                  After copleting the business profile, you can start managing
                  your business online and avail benifits of free services and
                  you can upgrade to premium as the need may arise. This
                  platform offers free services with some limitation on uses. We
                  ensure that we keep our pricing as lowest as possible to let
                  you enjoy the services, without thinking of expenses. At
                  Biziness we believe that saving every single rupee is adding
                  wealth to the business. Get ready and reserve your business
                  name before anyone takes it.
                </p>
              </div>
              <div className="col-md-6 order-md-1 pt-1">
                <img src="./assets/img/intro5.png" width="100%" alt="" />
              </div>
            </div>
          </div>
        </>
      )} */}
      {/* <div className="container mt-5">
        <div className="row m-0">
          <div className="col-md-6 pt-5">
            <h2 className="text-dark text-bold" style={{ fontSize: "35px" }}>
              Enjoy the Handshake
            </h2>
            <p className="mt-5 text-justify">
              Biziness is commited to provide with every support you need to
              boost your business and collaborating with us is just a step in
              it. Lets bring your business to the top.
            </p>
          </div>
          <div className="col-md-6 pt-5">
            <img src="./assets/img/intro9.gif" width="100%" alt="" />
          </div>
        </div>
      </div> */}
      {/* Schedule a meeting modal  */}
      <div
        className="modal fade"
        id="modal_meeting"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex="-1"
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
          <div className="modal-content">
            <div className="modal-header">
              <h2 className="modal-title fs-5" id="staticBackdropLabel">
                Fill up details to set the meeting
              </h2>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className={"w-100 " + statusclass + " mt-1"}>{statusmsg}</div>

            <div className="modal-body">
              <div className="input-group mb-3">
                <span
                  className="input-group-text bg-primary text-white"
                  id="basic-addon1"
                >
                  <i className="bi bi-person-circle"></i>
                </span>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Your Name"
                  aria-label="username"
                  aria-describedby="basic-addon1"
                  value={meetingwithperson}
                  onChange={(e) => setmeetingwithperson(e.target.value)}
                />
              </div>
              <div className="input-group mb-3">
                <span
                  className="input-group-text  bg-primary text-white"
                  id="basic-addon2"
                >
                  <i className="bi bi-building"></i>
                </span>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Company Name"
                  aria-label="companyname"
                  aria-describedby="basic-addon2"
                  value={meetingwithcompany}
                  onChange={(e) => setmeetingwithcompany(e.target.value)}
                />
              </div>
              <div className="input-group mb-3">
                <span
                  className="input-group-text  bg-primary text-white"
                  id="basic-addon3"
                >
                  <i className="bi bi-phone-fill"></i>
                </span>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Contact Number"
                  aria-label="contactnumber"
                  aria-describedby="basic-addon3"
                  value={meetingwithcontact}
                  onChange={(e) => setmeetingwithcontact(e.target.value)}
                />
              </div>
              <div className="input-group mb-3">
                <span
                  className="input-group-text  bg-primary text-white"
                  id="basic-addon4"
                >
                  <i className="bi bi-envelope-fill"></i>
                </span>
                <input
                  type="email"
                  className="form-control"
                  placeholder="Email Address"
                  aria-label="emailaddress"
                  aria-describedby="basic-addon4"
                  value={meetingwithemail}
                  onChange={(e) => setmeetingwithemail(e.target.value)}
                />
              </div>
              <div className="input-group">
                <span className="input-group-text  bg-primary text-white">
                  <i className="bi bi-briefcase-fill"></i>
                </span>
                <textarea
                  className="form-control"
                  aria-label="businessdetails"
                  placeholder="Business Details"
                  value={meetingwithbusinessdetail}
                  onChange={(e) => setmeetingwithbusinessdetail(e.target.value)}
                ></textarea>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-primary text-white"
                onClick={schedulemeeting}
              >
                Set a Meeting
              </button>
              <button
                type="button"
                className="btn btn-light border"
                data-bs-dismiss="modal"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
