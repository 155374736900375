import React from "react";

export default function ProfileCompletionDashboard(prop) {
  return (
    <>
      <div className="container mt-3">
        <div className="row">
          <div className="col-md-3">Profile Status : {prop.score} Complete</div>
          <div className="col-md-9 pt-2">
            <div
              className="bg-secondary rounded"
              style={{ padding: 1, width: "100%" }}
            >
              <div
                className="bg-primary rounded p-1"
                style={{ width: prop.score }}
              ></div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
