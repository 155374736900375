import React from "react";

export default function Spinner() {
  return (
    <div
      className="text-center"
      style={{
        position: "absolute",
        left: 0,
        top: 54,
        zIndex: "999",
        width: "100%",
        height: "98vh",
        backgroundColor: "rgba(255,255,255,.5)",
      }}
    >
      <div
        style={{ marginTop: "40vh" }}
        className="text-center spinner-border text-primary bg-white"
        role="status"
      ></div>
      <div className="text-dark">Processing...</div>
    </div>
  );
}
