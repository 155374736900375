import axios from "axios";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { API_PATH } from "../../Config";

export default function PasswordResetMailAuth() {
  const [newpass, setnewpass] = useState("");
  const [verifypass, setverifypass] = useState("");
  const [retstatus, setretstatus] = useState("");
  const params =  useParams()

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const resetpassword = (e) => {
    e.preventDefault();
    if (newpass === verifypass && newpass.length > 2) {
      axios
        .post(API_PATH + "Auth.php", {
          userid: params.id,
          usertoken: params.hash,
          action: "resetpassforloggedin",
          pass: newpass,
        })
        .then((r) => {
          //console.log(r.data)
          if (r.data.status === "success") {
            setnewpass("");
            setverifypass("");
            setretstatus("success");
          } else {
            setretstatus("failed");
          }
        });
    } else {
      setnewpass("");
      setverifypass("");
      setretstatus("mismatch");
    }

    setTimeout(() => {
      setretstatus("");
    }, 4000);
  };

  return (
    <div className="container mt-5 pt-5 mb-5 mh-90">
      { 
        params.id>0 && params.hash ?
        <>
        <div className="border-bottom p-1">
          <h1>Reset User Password</h1>
          <small className="pb-1">Please provide details to reset password</small>
        </div>
        <div className="row m-0 mt-4">
          <div className="col-md-3"></div>
          <div className="col-md-6">
            {retstatus === "success" && (
              <div className="alert alert-success mt-2" role="alert">
                Password has successfully been reset.
              </div>
            )}
            {retstatus === "mismatch" && (
              <div className="alert alert-danger mt-2" role="alert">
                New password and verification password do not match, please try
                again.
              </div>
            )}
            {retstatus === "failed" && (
              <div className="alert alert-danger mt-2" role="alert">
                Something went wrong, please try again.
              </div>
            )}
            <form onSubmit={resetpassword}>
              <div className="form-group">
                <div className="">
                  <label>Enter new Password</label>
                  <input
                    type="password"
                    className="form-control"
                    value={newpass}
                    onChange={(e) => setnewpass(e.target.value)}
                  />
                </div>
                <div className="mt-2">
                  <label>Verify new Password</label>
                  <input
                    type="password"
                    className="form-control"
                    value={verifypass}
                    onChange={(e) => setverifypass(e.target.value)}
                  />
                </div>
                <div className="mt-2">
                  <button type="submit" className="btn btn-primary rounded-pill">
                    Reset Password
                  </button>
                </div>
              </div>
            </form>
          </div>
          <div className="col-md-3"></div>
        </div>
        </>
        :
        <>
        <div className="text-center">
          <h4 className="text-secondary">Sorry, Dirrect access to this page not allowed.</h4>
        </div>
        </>
      }
    </div>
  );
}
