import axios from "axios";
import React, { useEffect, useState } from "react";
import { Navigate, useLocation } from "react-router-dom";
import { API_PATH, APP_TOKEN } from "../../Config";
import useLoginAuth from "../../hooks/useLoginAuth";
import Spinner from "../../layout/common/Spinner";
import { useCallback } from "react";

export default function SalesVisitTracker() {
  const validatelogin = useLoginAuth();
  
  const [x,setx] = useState()
  const [businessname,setbusinessname] = useState('')
  const [contactperson,setcontactperson] = useState('')
  const [contactnumber,setcontactnumber] = useState('')
  const [email,setemail] = useState('')
  const [address,setaddress] = useState('')
  const [visitstatus,setvisitstatus] = useState('')
  const [remark,setremark] = useState('')
  const [latitude,setlatitude] = useState('')
  const [longitude,setlongitude] = useState('')
  const [gpsstatus,setgpsstatus] = useState('disabled')
  const [readonly,setreadonly] = useState(true)
  const [spinner, setspinner] = useState(false);
  
  useEffect(() => {
    window.scrollTo(0, 0);
    // get gos location
    navigator.geolocation.getCurrentPosition(function(position) {
      let lat = position.coords.latitude;
      let long = position.coords.longitude;

      if (lat!=='' && long!=='') {
        setgpsstatus("enabled");
        setlatitude(position.coords.latitude);
        setlongitude(position.coords.longitude);
        setreadonly(false)
      } else {
        setgpsstatus("disabled");
        setreadonly(true)
      }
    });
    
  },[]);

  const addsalestracker = ()=>{
    if(!latitude || !longitude || !businessname || !remark){
      alert('Gps, Business name and Remark are mandatory!')
    }else{
      axios.post(API_PATH+'ManageSale.php',{
        action:'addsalestrackerentry',
        userid : localStorage.getItem('ez-userid'),
        usertoken : localStorage.getItem('ez-usertoken'),
        businessname  : businessname,
        contactperson  : contactperson,
        contactnumber  : contactnumber,
        email  : email,
        address  : address,
        visitstatus  : visitstatus,
        remark  : remark,
        latitude  : latitude,
        longitude  : longitude
      }).then(r=>{
        console.log(r.data)
        if(r.data.status==='success'){
          setbusinessname('')
          setcontactperson('')
          setcontactnumber('')
          setemail('')
          setaddress('')
          setvisitstatus('')
          setremark('')
          alert('Data Added Successfully!')
        }else{
          alert('Something went wrong, please try again later!')
        }
      })
    }

  }
  
  if (validatelogin !== true) {
    return <Navigate to={"/login"} />;
  } else {
    return (
      <div className="container mt-3 pt-5 mb-5 mh-90">
        {spinner === true && <Spinner />}
        <div className="border-bottom p-1">
          <h1>Daily Visit Tracker</h1>
          <small className="pb-1">
            Track Down your daily pitch and create prospect data.
          </small>
        </div>
        {
          gpsstatus ==='enabled' ? 
          <div className="alert alert-success">
            Gps status seems to be OK, you can continue.
          </div>:
          <div className="alert alert-danger">
            Gps seems to be disabled, Form permission will be denied unless GPS is turned on.
            {gpsstatus}
          </div>

        }
        
        <div className="row m-0 mt-4">

          <div className="p-1 col-md-4">
            <label>Business Name</label>
            <input type="text" value={businessname} onChange={e=>{setbusinessname(e.target.value)}} className="form-control" disabled={readonly}/>
          </div>
          <div className="p-1 col-md-4">
            <label>Contact Person Name</label>
            <input type="text" value={contactperson} onChange={e=>{setcontactperson(e.target.value)}} className="form-control" disabled={readonly}/>
          </div>
          <div className="p-1 col-md-4">
            <label>Contact Number</label>
            <input type="number" value={contactnumber} onChange={e=>{setcontactnumber(e.target.value)}} className="form-control" disabled={readonly}/>
          </div>
          <div className="p-1 col-md-4">
            <label>Email Address</label>
            <input type="email" value={email} onChange={e=>{setemail(e.target.value)}} className="form-control" disabled={readonly}/>
          </div>
          <div className="p-1 col-md-4">
            <label>Address</label>
            <input type="text" value={address} onChange={e=>{setaddress(e.target.value)}} className="form-control" disabled={readonly}/>
          </div>
          <div className="p-1 col-md-4">
            <label>Visit Status</label>
            <select value={visitstatus} onChange={e=>{setvisitstatus(e.target.value)}} className="form-control" disabled={readonly}>
              <option value="">Select from list</option>
              <option value="interested">Interested</option>
              <option value="follow-up">Follow Up</option>
              <option value="rejected">Offer Rejected</option>
              <option value="other">Other-Specify</option>
            </select>
          </div>
          <div className="p-1 col-md-12">
            <label>Remarks</label>
            <textarea value={remark} onChange={e=>{setremark(e.target.value)}} className="form-control" rows={5} disabled={readonly}/>
          </div>
          
          
        </div>
        <div className="p-1  col-md-4">
          <button className="btn btn-primary text-dark" disabled={readonly} onClick={addsalestracker}>
            Add Entry
          </button>
        </div>
      </div>
    );
  }
}
