import React, { useState, useEffect } from "react";
import { BASE_URL } from "../../Config";
import { Link, useLocation } from "react-router-dom";
import Switcher from "./navswitch/Switcher";

export default function Navbar() {
  const [navToggle, setNavToggle] = useState("d-none");
  const location = useLocation();

  // check if current page is store
  const getstoreurl = location.pathname;
  const isstorepage = getstoreurl.slice(1, 3) === "ez" ? true : false;

  // toggle sidebar onclick
  const toggleSidebar = () => {
    if (navToggle === "d-none") {
      setNavToggle("d-block");
    } else {
      setNavToggle("d-none");
    }
  };

  // Detect url locaion change and hide mobile menu
  useEffect(() => {
    setNavToggle("d-none");
  }, [location.pathname]);
  return (
    <>
      {isstorepage ? (
        <>
          {/* store page navigation */}
          <div className="row m-0 ">
            <div className="col-md-6 col-10 text-center p-1 ps-md-4">
              <div className="nav-branding d-flex">
                <div className="pt-0 pt-sm-2 pt-md-0">
                  <Link to="/">
                    <div className="d-flex">
                      <img
                        src={require("./../../assets/img/bzlogo3.png")}
                        alt=""
                        style={{
                          width: "45px",
                          height: "45px",
                          aspectRatio: "1/1",
                          padding: "1px",
                        }}
                        className="shadow rounded"
                      />
                    </div>
                  </Link>
                </div>
              </div>
            </div>

            <div className="d-none d-md-block col-md-5 ">
              {/* added just to make layout work */}
            </div>

            <div className="col-md-1 col-2 p-1">
              <i
                className="btn bi bi-layout-text-sidebar fs-4"
                onClick={toggleSidebar}
              ></i>
            </div>
          </div>
        </>
      ) : (
        // navigation other than store page
        <nav className="navbar-custom fixed-top border-bottom border-white shadow-sm  border-1 text-right bg-primary">
          <div className="container-fluid">
            <div className="row m-0">
              <div className="col-lg-3 col-md-6 col-10 text-center p-0">
                <div className="nav-branding">
                  <div className="pt-0 pt-sm-2 pt-md-0">
                    <Link to="/">
                      <div className="d-flex">
                        <img
                          src={require("../../assets/img/bzlogo1.png")}
                          alt=""
                          width="170px"
                          className=""
                          style={{ marginLeft: "-5px" }}
                        />
                        <span>
                          <strong className="text-white">.in</strong>
                        </span>
                      </div>
                    </Link>
                  </div>
                </div>
              </div>

              <div className="d-none d-md-block d-lg-none col-md-3">
                {/* added just to make layout work */}
              </div>

              <div className="col-lg-9 col-md-3 col-2 p-0 ">
                <div className="d-none d-lg-block">
                  <Switcher />
                </div>
                <i
                  className="d-block d-lg-none bi bi-layout-text-sidebar text-white fs-3 togglebutton text-end"
                  onClick={toggleSidebar}
                ></i>
              </div>
            </div>
          </div>
        </nav>
      )}
      {/* mobile navigation */}
      <div
        className={`sidebar bg-light ${navToggle} border-end border-4 border-white shadow`}
      >
        <Switcher />
      </div>
    </>
  );
}
