import React, { useEffect, useRef, useState } from "react";
import ReactToPrint from "react-to-print";

import QRCode from "react-qr-code";
import axios from "axios";
import { API_PATH } from "../../Config";
import { useParams } from "react-router-dom";
import GoToHome from "./GoToHome";
import Spinner from "../../layout/common/Spinner";

export default function StoreFrontQr() {
  const [spinner, setspinner] = useState(false)

  const params = useParams();
  const [data, setdata] = useState([]);
  const [error, seterror] = useState("none");
  useEffect(() => {
    window.scrollTo(0, 0);
    setspinner(true)
    axios
      .post(API_PATH + "Action.php", {
        action: "getBusinessNameAndUniqueUrl",
        upath: params.id,
      })
      .then((r) => {
        if (r.data.status === "success") {
          setdata(r.data.retval);
        } else {
          seterror("Sorry, we are not able to fetch data for this business.");
        }
        setspinner(false)
      });
  }, []);

  const componentRef = useRef();

  return (
    <div className=" mt-5 mh-90 pt-4 pb-5">
      {spinner === true && <Spinner/>}
      <div className="container">
        <GoToHome link={"/ez/" + params.id} />
        <div className="text-center fs-2 text-bold mt-3">Easy QR</div>
        <p>
          <span className="text-bold">Easy Qr</span> code is designed to let you
          take print of it. You can take this print or make a pdf copy of it and
          share or paste in your office premise or online. Just fill out your
          business profile and let the system generate this qr with your Easy
          Page Link.
        </p>
        {error === "none" ? (
          <>
            <div className="row m-0 shadow rounded bg-white">
              <div className="col d-none d-md-block"></div>
              <div
                ref={componentRef}
                className="text-center pt-5 pb-5 bg-white"
                style={{ width: "8.26in", height:'11.6in', display: "flex" }}
              >
                <div style={{ width: "15%" }}></div>
                <div style={{ width: "80%" }}>
                  <div
                    className="bg-primary mb-2 text-white text-dark text-bold"
                    style={{
                      borderRadius: "15px 15px 0 0",
                      textTransform: "capitalize",
                      fontSize:'22px'
                    }}
                  >
                    {data.b_name}
                  </div>
                  <div
                    className=" border-base pt-1 pb-1 ps-1 pe-1"
                  >
                    <QRCode
                      value={"https://biziness.in/ez/" + params.id}
                      style={{
                        height: "auto",
                        maxWidth: "100%",
                        width: "100%",
                      }}
                    />
                  </div>
                  <div
                    className="bg-primary text-white mt-2 fs-4 text-bold"
                    style={{ borderRadius: "0 0 15px 15px" ,fontSize:'22px'}}
                  >
                    Find my Business Online
                  </div>
                  <div className="mt-5 fs-2">
                    <strong>This business is powered by Biziness.in</strong>
                    <img
                      src={require("./../../assets/img/bzlogo3.png")}
                      style={{ width: "50px" }}
                      alt=""
                      className="ms-1 rounded"
                    />
                  </div>
                </div>
                <div style={{ width: "15%" }}></div>
              </div>
              <div className="col d-none d-md-block"></div>
            </div>
            <div className="mt-2 text-center">
              <ReactToPrint
                trigger={() => (
                  <button className="btn btn-primary">Print this out!</button>
                )}
                content={() => componentRef.current}
              />
            </div>
          </>
        ) : (
          <div className="pt-5 mt-5">
            <h4 className="text-seondary text-center">{error}</h4>
          </div>
        )}
      </div>
    </div>
  );
}
