import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link, Navigate } from "react-router-dom";
import { API_PATH, USER_ID, USER_ROLE, USER_TOKEN } from "../../Config";
import useLoginAuth from "../../hooks/useLoginAuth";
import useValidateRegType from "../../hooks/useValidateRegType";
import PayForExpired from "../../layout/common/PayForExpired";
import Spinner from "../../layout/common/Spinner";

export default function PointOfSale() {
  const [spinner, setspinner] =useState(false)

  const validatelogin = useLoginAuth();
  const validateSubscription = useValidateRegType()
  
  const [product, setproduct] = useState("");
  const [prodqty, setprodqty] = useState(1);
  const [prodlist, setprodlist] = useState([]);
  const [cartlist, setcartlist] = useState([]);
  const [counter, setcounter] = useState(1);

  const [taxablevalue, settaxablevalue] = useState(0);
  const [taxamount, settaxamount] = useState(0);
  const [totalamount, settotalamount] = useState(0);

  useEffect(() => {
    window.scrollTo(0,0)
    setspinner(true)
    axios
      .post(API_PATH + `ManageInventory.php`, {
        userid: USER_ID,
        userrole: USER_ROLE,
        usertoken: USER_TOKEN,
        searchstr: product,
        action: "getProductForPos",
      })
      .then((res) => {
        console.log(res.data)
        if (res.data.status === "success") {
          setprodlist(res.data.retval);
        }
        setspinner(false)
      });
  }, [product]);

  useEffect(() => {
    setspinner(true)
    axios
      .post(API_PATH + `ManageSale.php`, {
        userid: USER_ID,
        userrole: USER_ROLE,
        usertoken: USER_TOKEN,
        action: "getItemizedInvoice",
      })
      .then((res) => {
        if (res.data.status === "success") {
          setcartlist(res.data.retval);
          settaxablevalue(res.data.taxablevalue);
          settaxamount(res.data.taxamount);
          settotalamount(res.data.totalamount);
        }
        setspinner(false)
      });
  }, [counter]);

  // add product to bag
  const addtobag = (e) => {
    e.preventDefault();
    if (prodqty && product) {
      setspinner(true)
      axios
        .post(API_PATH + `ManageSale.php`, {
          userid: USER_ID,
          userrole: USER_ROLE,
          usertoken: USER_TOKEN,
          product: product,
          prodqty: prodqty,
          action: "posAddToBag",
        })
        .then((res) => {
          // console.log(res.data)
          if (res.data.status === "success") {
            setproduct("");
            // setprodlist(res.data.retval);
          }
          setcounter(Number(counter) + 1);
          setspinner(false)
        });
    } else {
      alert("Product and quantity can't be blank.");
    }
  };

  const deleteFromBag = (e) => {
    e.preventDefault();
    setspinner(true)
    axios
      .post(API_PATH + `ManageSale.php`, {
        userid: USER_ID,
        userrole: USER_ROLE,
        usertoken: USER_TOKEN,
        action: "deleteFromItemizedInvoice",
        delid: e.target.id,
      })
      .then((r) => {
        if (r.data.status === "success") {
          setcounter(Number(counter) + 1);
        }
        setspinner(false)
      });
  };

  const updateBagItem = (e) => {
    e.preventDefault();
    axios
      .post(API_PATH + `ManageSale.php`, {
        userid: USER_ID,
        userrole: USER_ROLE,
        usertoken: USER_TOKEN,
        action: "updateItemFromItemizedInvoice",
        cartid: e.target.id,
        newvalue: e.target.value,
        cellname: e.target.name,
      })
      .then((r) => {
        if (r.data.status === "success") {
          setcounter(Number(counter) + 1);
        }
      });
  };

  if (validatelogin !== true) {
    return <Navigate to={"/login"} />;
  } else {
    if(validateSubscription.status===false){
      return(
        <PayForExpired/>
      )
    }else{
      return (
        <div className="pt-5 pb-5 mh-90">
          {spinner === true && <Spinner/>}
          <div className="container mt-5">
            <div className="accordion  mt-5" id="accordionExample">
              <div className="accordion-item mt-2">
                <h2 className="accordion-header" id="headingTwo">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseTwo"
                    aria-expanded="false"
                    aria-controls="collapseTwo"
                  >
                    <span style={{ fontWeight: "bold" }}>
                      Biziness Point of sale : Manage your daily sales and keep
                      track of. Click to know in detail.
                    </span>
                  </button>
                </h2>
                <div
                  id="collapseTwo"
                  className="accordion-collapse collapse"
                  aria-labelledby="headingTwo"
                  data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body">
                    This module helps you to take benifit of mall or big shopping
                    center like billing invoicing experience from your own store
                    or business. By searching (Manually or using bar code reader)
                    the product your have listed with biziness will be added to
                    boll and in next screen you can generate invoices for your
                    customer.
                  </div>
                </div>
              </div>
            </div>
            <div className="row m-0 mt-2">
              <div className="col-6 col-sm-1 p-1">
                <label>Add Prod.</label>
                <Link to={"/add-product"}>
                  <button className="form-control btn btn-danger shadow">
                    <i className="bi bi-file-earmark-plus"></i>
                  </button>
                </Link>
              </div>
              <div className="col-6 col-sm-1 p-1">
                <label>Track Sale</label>
                <Link to={"/order-delivered"}>
                  <button className="form-control btn btn-warning shadow">
                    <i className="bi bi-card-checklist"></i>
                  </button>
                </Link>
              </div>
              <div className="col-sm-7 p-1">
                <label>Search Product to Sale</label>
                <input
                  list="pos"
                  type={"search"}
                  className="form-control shadow"
                  value={product}
                  onChange={(e) => setproduct(e.target.value)}
                  autoComplete="on"
                />
                <datalist id="pos">
                  {prodlist.map((d) => {
                    return <option value={d.prod_full_name} key={d.id} />;
                  })}
                </datalist>
              </div>
              <div className="col-sm-1 p-1">
                <label>Qty</label>
                <input
                  type="number"
                  min="0"
                  value={prodqty}
                  onChange={(e) => setprodqty(e.target.value)}
                  className="form-control shadow"
                />
              </div>
              <div className="col-6 col-sm-1 p-1">
                <label>Add to Bag</label>
                <button className="form-control btn btn-info shadow" onClick={addtobag}>
                  <i className="bi bi-plus-circle-fill text-white"></i>
                </button>
              </div>
              <div className="col-6 col-sm-1 p-1">
                <label>Check Out</label>
                <Link to={'/point-of-sale-checkout'}>
                  <button className="form-control btn btn-success shadow">
                    <i className="bi bi-receipt-cutoff"></i>
                  </button>
                </Link>
              </div>
            </div>
            <div className="mt-4 bg-white shadow rounded" style={{ overflowX: "scroll", height: "350px" }}>
              <table className="table table-striped">
                <thead>
                  <tr>
                    <th>Action</th>
                    <th>Product Name</th>
                    <th>Product Qty</th>
                    <th>Rate</th>
                    <th>Taxable Value</th>
                    <th>Tax Rate</th>
                    <th>Tax Amount</th>
                    <th>Total Amount</th>
                  </tr>
                </thead>
                <tbody>
                  {cartlist.map((d) => {
                    return (
                      <tr key={d.id}>
                        <td>
                          <i
                            className="text-danger btn bi bi-trash3-fill"
                            id={d.id}
                            onClick={deleteFromBag}
                          ></i>
                        </td>
                        <td>{d.prod_name}</td>
                        <td>
                          <input
                            className="form-control"
                            type="number"
                            name="prod_qty"
                            onChange={updateBagItem}
                            id={d.id}
                            min={1}
                            defaultValue={d.prod_qty}
                          />
                        </td>
                        <td>
                          <input
                            className="form-control"
                            type="number"
                            name="unit_rate"
                            onChange={updateBagItem}
                            id={d.id}
                            min={0.01}
                            defaultValue={d.unit_rate}
                          />
                        </td>
                        <td>{d.taxable_value}</td>
                        <td>
                          <input
                            className="form-control"
                            type="number"
                            name="igst_rate"
                            onChange={updateBagItem}
                            id={d.id}
                            min={0}
                            defaultValue={d.igst_rate}
                          />
                        </td>
                        <td>{d.igst_amount}</td>
                        <td>{d.total_amount}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
            <div className="row m-0 mt-3 shadow rounded bg-white">
              <div className="col-6 col-md-8">
                <div className="text-bold">Bill Amount</div>
                <div className="text-bold">Tax Amount</div>
                <div className="text-bold">Due Amount</div>
              </div>
              <div className="col-6 col-md-4 text-end">
                <div className="text-bold">{taxablevalue}/-</div>
                <div className="text-bold">{taxamount}/-</div>
                <div className="border-top border-primary border-4 text-bold">
                  {totalamount}/-
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }
  }
}
