import axios from "axios";
import React, { useEffect, useState } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import { API_PATH } from "../../Config";
import useLoginAuth from "../../hooks/useLoginAuth";
import useValidateRegType from "../../hooks/useValidateRegType";
import PayForExpired from "../../layout/common/PayForExpired";
import Spinner from "../../layout/common/Spinner";



export default function CreateTeam() {
  const [spinner, setspinner] = useState(false)

  const validatelogin = useLoginAuth();
  const validateSubscription = useValidateRegType();
  const [fullname, setfullname] = useState("");
  const [designation, setdesignation] = useState("");
  const [imagefile, setimagefile] = useState([]);

  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const updateIntro = () => {
    const fd = new FormData();

    
    
    fd.append("action", "addnewteammember");
    fd.append("userid", localStorage.getItem("ez-userid"));
    fd.append("usertoken", localStorage.getItem("ez-usertoken"));

    fd.append("imagefile", imagefile);
    fd.append("fullname", fullname);
    fd.append("designation", designation);

    // check params are filled or not
    setspinner(true)
    axios
      .post(API_PATH + "ManageStoreFront.php", fd, {
        headers: {
          "content-type": "multipart/form-data",
        },
      })
      .then((res) => {
        if (res.data.status === "success") {
          setfullname("");
          setdesignation("")
          setimagefile([]);
          alert("Data Updated successfully!");
        } else {
          alert("Something went wrong, please try again later!");
        }
        setspinner(false)
      });

    window.scrollTo(0, 0);
  };
  if (validatelogin !== true) {
    return <Navigate to={"/login"} />;
  } else {
    if (validateSubscription.status === false) {
      return <PayForExpired />;
    } else {
      return (
        <div className="pt-5 mh-90 pb-5">
          {spinner === true && <Spinner/>}
          <div className="container mt-5">
            <h2 className="text-secondary">Display New Team Member</h2>
            <small>
              This page enables you to add new Team Member to your business
              profile on Biziness.in. Creating a Team is very easy. You just have
              to fill up the simple form and submit it.
            </small>
          </div>
          <div className="container mt-4">
            <div className="row m-0">
              <div className="col-md-6 pt-2">
                <label>Full name</label>
                <input
                  type="text"
                  className="form-control"
                  value={fullname}
                  onChange={(e) => setfullname(e.target.value)}
                />
              </div>
              <div className="col-md-3 pt-2">
                <label>Designation</label>
                <input
                  type="text"
                  className="form-control"
                  value={designation}
                  onChange={(e) => setdesignation(e.target.value)}
                />
              </div>
              <div className="col-md-3 pt-2">
                <label>Add an image</label>
                <input
                  type="file"
                  className="form-control"
                  onChange={(e) => setimagefile(e.target.files[0])}
                />
              </div>
            </div>
            <div className="row mt-3">
              <div className="d-none d-lg-block col-lg-3"></div>
              <div className="col-lg-3 text-center p-2">
                <button className="btn btn-primary" onClick={updateIntro}>
                  Add Team Member
                </button>
              </div>
              <div className="col-lg-3 text-center p-2">
                <button
                  className="btn btn-primary"
                  onClick={() => navigate("/manage-team")}
                >
                  Manage Member
                </button>
              </div>
              <div className="d-none d-lg-block col-lg-3 p-2"></div>
            </div>
          </div>
        </div>
      );
    }
  }
}
