import React from "react";

function FaqAccordian() {
  return (
    <>
      <div className="container mt-5 pt-5">
        <h1 className="text-center">Frequently asked questions</h1>
        <div className="accordion  mt-5" id="accordionExample">
          <div className="accordion-item">
            <h2 className="accordion-header" id="headingOne">
              <button
                className="accordion-button"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseOne"
                aria-expanded="true"
                aria-controls="collapseOne"
              >
                1. What is Biziness.in?
              </button>
            </h2>
            <div
              id="collapseOne"
              className="accordion-collapse collapse show"
              aria-labelledby="headingOne"
              data-bs-parent="#accordionExample"
            >
              <div className="accordion-body">
              Biziness.in is your personal business manager with an intent to
                help you grow with the help of technology which were not
                available for averyone. With Biziness.in you can manage your
                business online without worrying about expensive technology. All
                you have to select from best suitable plan for you and start
                managing your business online.
              </div>
            </div>
          </div>

          <div className="accordion-item mt-2">
            <h2 className="accordion-header" id="headingTwo">
              <button
                className="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseTwo"
                aria-expanded="false"
                aria-controls="collapseTwo"
              >
                2. How can It help me grow ?
              </button>
            </h2>
            <div
              id="collapseTwo"
              className="accordion-collapse collapse"
              aria-labelledby="headingTwo"
              data-bs-parent="#accordionExample"
            >
              <div className="accordion-body">
                In Biziness.in, we are bringing various business modules uder one
                roof, which were preveiously being utilized in large scale
                businesses but was not affordable or easy for small scale
                business. We believe that if similar technology is applied in
                small businesses, they can grow much faster than before.
              </div>
            </div>
          </div>

          <div className="accordion-item mt-2">
            <h2 className="accordion-header" id="headingThree">
              <button
                className="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseThree"
                aria-expanded="false"
                aria-controls="collapseThree"
              >
                3. Does your platform integrate with other software and tools?
              </button>
            </h2>
            <div
              id="collapseThree"
              className="accordion-collapse collapse"
              aria-labelledby="headingThree"
              data-bs-parent="#accordionExample"
            >
              <div className="accordion-body">
                Our aim is to provide everything you need under one roof, and we
                shall try our best to provide you every single module to help
                your business. But, we are open to integrate any available
                resource if required.
              </div>
            </div>
          </div>

          <div className="accordion-item mt-2">
            <h2 className="accordion-header" id="heading4">
              <button
                className="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapse4"
                aria-expanded="false"
                aria-controls="collapse4"
              >
                4. How long the free trial work ?
              </button>
            </h2>
            <div
              id="collapse4"
              className="accordion-collapse collapse"
              aria-labelledby="heading4"
              data-bs-parent="#accordionExample"
            >
              <div className="accordion-body">
                We are trying to keep the pricing as low as possible. Anyone can register and secure their business name, but, to manage other things people are requested to register with at least basic plan to help us keep the platform maintained. 
              </div>
            </div>
          </div>

          <div className="accordion-item mt-2">
            <h2 className="accordion-header" id="heading5">
              <button
                className="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapse5"
                aria-expanded="false"
                aria-controls="collapse5"
              >
                5. What’s included in my trial plan ?
              </button>
            </h2>
            <div
              id="collapse5"
              className="accordion-collapse collapse"
              aria-labelledby="heading5"
              data-bs-parent="#accordionExample"
            >
              <div className="accordion-body">
                There is not any trial plan for now, but people can register and secure their business url to avoide name conflicts. 
              </div>
            </div>
          </div>

          <div className="accordion-item mt-2">
            <h2 className="accordion-header" id="heading6">
              <button
                className="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapse6"
                aria-expanded="false"
                aria-controls="collapse6"
              >
                6. Do I have to sign a long-term contract ?
              </button>
            </h2>
            <div
              id="collapse6"
              className="accordion-collapse collapse"
              aria-labelledby="heading6"
              data-bs-parent="#accordionExample"
            >
              <div className="accordion-body">
                No. any account can be closed any time by contacting us and asking to close the account.
              </div>
            </div>
          </div>

          <div className="accordion-item mt-2">
            <h2 className="accordion-header" id="heading7">
              <button
                className="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapse7"
                aria-expanded="false"
                aria-controls="collapse7"
              >
                7. Do I need to enter my credit card info to sign up?
              </button>
            </h2>
            <div
              id="collapse7"
              className="accordion-collapse collapse"
              aria-labelledby="heading7"
              data-bs-parent="#accordionExample"
            >
              <div className="accordion-body">
                No credit card is required to register. You may be asked for payment details while registering or ugrading plans as reuired by payment gateway services.
              </div>
            </div>
          </div>

          <div className="accordion-item mt-2">
            <h2 className="accordion-header" id="heading8">
              <button
                className="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapse8"
                aria-expanded="false"
                aria-controls="collapse8"
              >
                8. Will my data be private and safe ?
              </button>
            </h2>
            <div
              id="collapse8"
              className="accordion-collapse collapse"
              aria-labelledby="heading8"
              data-bs-parent="#accordionExample"
            >
              <div className="accordion-body">
                Since, we are not generating any profit from your data. Every piece of data is private and will not be utilized for any commercial or personal benifit.
              </div>
            </div>
          </div>

          <div className="accordion-item mt-2">
            <h2 className="accordion-header" id="heading9">
              <button
                className="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapse9"
                aria-expanded="false"
                aria-controls="collapse9"
              >
                9. Can I cancel my account at any time ?
              </button>
            </h2>
            <div
              id="collapse9"
              className="accordion-collapse collapse"
              aria-labelledby="heading9"
              data-bs-parent="#accordionExample"
            >
              <div className="accordion-body">
                Yes. Account can be closed anytime by contacting us.
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default FaqAccordian;
