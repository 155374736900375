import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link, Navigate, useNavigate } from "react-router-dom";
import { API_PATH } from "../../Config";
import useLoginAuth from "../../hooks/useLoginAuth";
import Spinner from "../../layout/common/Spinner";
import "./store.css";

export default function ManageBlogPost() {
  const [spinner, setspinner] = useState(false)
  const validatelogin = useLoginAuth();
  const [data, setdata] = useState([]);
  const [counter, setcounter] = useState(0);
  const navigate = useNavigate();
  const upath = localStorage.getItem("ez-upath");

  useEffect(() => {
    window.scrollTo(0, 0);
    setspinner(true)
    axios
      .post(API_PATH + "ManageStoreFront.php", {
        action: "getBlogListById",
        userid: localStorage.getItem("ez-userid"),
        usertoken: localStorage.getItem("ez-usertoken"),
      })
      .then((r) => {
        if (r.data.status === "success") {
          setdata(r.data.retval);
        } else {
          setdata([]);
          alert("Sorry! No data Found.");
        }
        setspinner(false)
      });
  }, [counter]);

  // action after selection change
  const action = (e) => {
    switch (e.target.value) {
      case "view-blog-post":
        navigate("/blog/" + upath + "/" + e.target.id);
        break;

      case "delete":
        setspinner(true)
        axios
          .post(API_PATH + "ManageStoreFront.php", {
            action: "deleteBlogItemById",
            userid: localStorage.getItem("ez-userid"),
            usertoken: localStorage.getItem("ez-usertoken"),
            delid: e.target.id,
          })
          .then((r) => {
            //console.log(r.data)
            if (r.data.status === "success") {
              setcounter(Number(counter) + 1);
              alert("Post deleted successfully!");
            } else {
              alert("Sorry! Something went wrong.");
            }
            setspinner(false)
          });
        break;

      default:
        break;
    }
  };
  if (validatelogin !== true) {
    return <Navigate to={"/login"} />;
  } else {
    return (
      <div className="pt-5 mh-90 pb-5 bg-white">
        {
          spinner===true && <Spinner/>
        }
        <div className="container mt-5">
          <h2 className="text-secondary">Manage Blog Posts</h2>
          <small>
            This page enables you to update existing Post/Blog to your business
            profile on Biziness.in. Creating a blog is very easy. You just have to
            fill up the simple form and submit it.
          </small>
        </div>
        <div className="container mt-4">
          <table
            className="table table-striped"
            style={{ overflowX: "scroll" }}
          >
            <thead>
              <tr>
                <th>Action</th>
                <th>Title</th>
                <th>Date</th>
                <th>Time</th>
              </tr>
            </thead>
            <tbody>
              {data.map((d) => {
                return (
                  <tr key={d.id}>
                    <td>
                      <select className="rounded" onChange={action} id={d.id}>
                        <option value=""></option>
                        <option value="view-blog-post">View</option>
                        {/* <option value="">Edit</option> */}
                        <option value="delete">Delete</option>
                      </select>
                    </td>
                    <td>
                      <Link to={"/blog/" + upath + "/" + d.id}>
                        {d.post_title}
                      </Link>
                    </td>
                    <td>{d.date}</td>
                    <td>{d.time}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    );
  }
}
