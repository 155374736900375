import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { API_PATH, IMAGE_PATH } from "../../Config";
import Spinner from "../../layout/common/Spinner";

export default function StoreFrontProdSingleView() {
  const [spinner, setspinner] = useState(false)
  const param = useParams();
  const prodid = param.id;
  const upath = param.upath;
  const [proddata, setproddata] = useState([]);
  const [mainImage, setMainImage] = useState(
    require("./../../assets/img/bzlogo1.png")
  );

  useEffect(() => {
    window.scrollTo(0, 0);
    if (prodid > 0) {
      setspinner(true)
      axios
        .post(API_PATH + "ManageInventory.php", {
          productid: prodid,
          action: "getproductinfobyid",
        })
        .then((r) => {
          console.log(r.data)
          if (r.data.status === "success") {
            setproddata(r.data.retval);
            setMainImage(
              IMAGE_PATH + "product/" + r.data.retval.prod_image_link
            );
          }
          setspinner(false)
        });
    }
  }, []);
  const changeMainImage = (p) => {
    setMainImage(p.target.src);
  };
  return (
    <div className="mt-5 pt-2 mh-90">
      {spinner === true && <Spinner/>}
      <div className="container">
        <div className="row m-0 bg-white shadow rounded">
          <div className="col-md-4 ">
            <img
              style={{ width: "100%", objectFit: "contain", aspectRatio: "1/1" }}
              src={mainImage}
              className="product-image-main rounded"
              alt="product-main"
            />
            <div className="row m-0">
              {proddata.prod_image_link !== "" && (
                <div className="col-3 p-1">
                  <img
                    style={{
                      width: "100%",
                      objectFit: "contain",
                      aspectRatio: "1/1",
                    }}
                    src={IMAGE_PATH + "product/" + proddata.prod_image_link}
                    className="product-image-secondary rounded"
                    alt="product-secondary"
                    onClick={changeMainImage}
                  />
                </div>
              )}
              {proddata.prod_image_link_2 !== "" && (
                <div className="col-3 p-1">
                  <img
                    style={{
                      width: "100%",
                      objectFit: "contain",
                      aspectRatio: "1/1",
                    }}
                    src={IMAGE_PATH + "product/" + proddata.prod_image_link_2}
                    className="product-image-secondary rounded"
                    alt="product-secondary"
                    onClick={changeMainImage}
                  />
                </div>
              )}
              {proddata.prod_image_link_3 !== "" && (
                <div className="col-3 p-1">
                  <img
                    style={{
                      width: "100%",
                      objectFit: "contain",
                      aspectRatio: "1/1",
                    }}
                    src={IMAGE_PATH + "product/" + proddata.prod_image_link_3}
                    className="product-image-secondary rounded"
                    alt="product-secondary"
                    onClick={changeMainImage}
                  />
                </div>
              )}
              {proddata.prod_image_link_4 !== "" && (
                <div className="col-3 p-1">
                  <img
                    style={{
                      width: "100%",
                      objectFit: "contain",
                      aspectRatio: "1/1",
                    }}
                    src={IMAGE_PATH + "product/" + proddata.prod_image_link_4}
                    className="product-image-secondary rounded"
                    alt="product-secondary"
                    onClick={changeMainImage}
                  />
                </div>
              )}
              {proddata.prod_image_link_5 !== "" && (
                <div className="col-3 p-1">
                  <img
                    style={{
                      width: "100%",
                      objectFit: "contain",
                      aspectRatio: "1/1",
                    }}
                    src={IMAGE_PATH + "product/" + proddata.prod_image_link_5}
                    className="product-image-secondary rounded"
                    alt="product-secondary"
                    onClick={changeMainImage}
                  />
                </div>
              )}
            </div>
          </div>
          <div className="col-md-6 p-sm-5 p-2 ">
            <div className="product-title">
              <h3>
                <i className="bi bi-card-heading"> </i>
                {proddata.prod_full_name}
              </h3>
              <h6 className="">
                Max Price &nbsp;
                <i className="bi bi-cash-stack"></i>{" "}
                <span className="text-danger">
                  <del>&#x20B9; {proddata.prod_mrp}</del>
                </span>
              </h6>
              <h6>
                Offer Price &nbsp;
                <i className="bi bi-cash"></i>{" "}
                <span className="text-success">
                  &#x20B9; {proddata.final_sale_price}
                </span>
              </h6>
              <h6>
                Stock Status &nbsp;
                {proddata.prod_stock_qty > 0 ? (
                  <i className="bi bi-patch-check-fill text-success"></i>
                ) : (
                  <i className="bi bi-x-circle-fill text-danger"></i>
                )}
              </h6>
              <h6>
                Shpping Cost &nbsp;
                {proddata.shipping_price > 0 ? (
                  ">&#x20B9; " + proddata.shipping_price
                ) : (
                  <i className="bi bi-patch-check-fill text-success"></i>
                )}
              </h6>
              <h6>In Category : {proddata.category_name}</h6>
              <div className="text-center mt-4 mb-4">
                <Link to={"/contact/" + upath}>
                  <button className="btn btn-primary">Contact Seller</button>
                </Link>
              </div>
              <div className="mt-2 prod-short-desc p-1 text-center">
                {proddata.prod_short_description}
              </div>
            </div>
          </div>
        </div>
        <div className="mt-4 p-1 rounded bg-white shadow">
          <label className="bg-primary text-white p-1 rounded">
            Technical Details
          </label>
          <div className="border-top mt-1">
            <div className="row m-0">
              {proddata.batch_no && (
                <div className="mt-1 col-sm-6 col-md-4 p-1">
                  <div className="rounded border-start border-bottom p-1">
                    Batch No. : {proddata.batch_no}
                  </div>
                </div>
              )}
              {proddata.brand_modal_name && (
                <div className="mt-1 col-sm-6 col-md-4 p-1">
                  <div className="rounded border-start border-bottom p-1">
                    Brand / Model Name : {proddata.brand_modal_name}
                  </div>
                </div>
              )}
              
              {proddata.exp_date && (
                <div className="mt-1 col-sm-6 col-md-4 p-1">
                  <div className="rounded border-start border-bottom p-1">
                    Expiry Date : {proddata.exp_date}
                  </div>
                </div>
              )}
              {proddata.mfg_date && (
                <div className="mt-1 col-sm-6 col-md-4 p-1">
                  <div className="rounded border-start border-bottom p-1">
                    Mfg Date : {proddata.mfg_date}
                  </div>
                </div>
              )}
              {proddata.model_part_no && (
                <div className="mt-1 col-sm-6 col-md-4 p-1">
                  <div className="rounded border-start border-bottom p-1">
                    Model / Part No : {proddata.model_part_no}
                  </div>
                </div>
              )}
              {proddata.packing_qty_in_pcs && (
                <div className="mt-1 col-sm-6 col-md-4 p-1">
                  <div className="rounded border-start border-bottom p-1">
                    Qty In Pack : {proddata.packing_qty_in_pcs}
                  </div>
                </div>
              )}
              
              {proddata.place_of_origin && (
                <div className="mt-1 col-sm-6 col-md-4 p-1">
                  <div className="rounded border-start border-bottom p-1">
                    Place Of Origin : {proddata.place_of_origin}
                  </div>
                </div>
              )}
              
              {proddata.weight && (
                <div className="mt-1 col-sm-6 col-md-4 p-1">
                  <div className="rounded border-start border-bottom p-1">
                    Product Weight : {proddata.weight}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="mt-4 p-1 bg-white p-1 rounded shadow mb-5">
          <label className="bg-primary text-white p-1 rounded">
            Detailed Description
          </label>
          <div className="border-top mt-1 prod-detailed-desc p-1">
            {proddata.prod_full_description}
          </div>
        </div>
      </div>
    </div>
  );
}
