import React from "react";

export default function RefundAndShipping() {
  return (
    <div className="pt-5 mt-5 pb-5">
      <div className="container mh-90">
      <h5 className="mt-4">1. REFUND POLICY</h5>
        <div style={{ fontSize: 14, textAlign: "justify" }}>
          No refund is available on any of our plan. Customer will be provided a 
          trial plan where he/she can test the platform and confirm registration or denial
           after trial plan expires. 
        </div>
      <h5 className="mt-4">2. SHIPPING POLICY</h5>
        <div style={{ fontSize: 14, textAlign: "justify" }}>
          All the service are available only through platform and do not require any shipping facility. 
        </div>
      <h5 className="mt-4">3. CONTACT US</h5>
        <div style={{ fontSize: 14, textAlign: "justify" }}>
          In order to resolve a complaint regarding the Site or to receive
          further information regarding use of the Site, please contact us
          at: Innovilla Private Limited Mahamanapuri Varanasi, Uttar Pradesh
          221005, India, office@innovilla.in
        </div>
      </div>
    </div>
  );
}
