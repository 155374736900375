import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { API_PATH, IMAGE_PATH } from "../../Config";
import Spinner from "../../layout/common/Spinner";
import GoToHome from "./GoToHome";
import ReactPlayer from "react-player/youtube";
import useExactPath from "../../hooks/useExactPath";

export default function StoreFrontGallery() {
  const [spinner, setspinner] = useState(false);
  const params = useParams();
  const upath = params.id;
  const exactpath = useExactPath();

  const [data, setdata] = useState([]);
  const [modalimage, setmodalimage] = useState("");
  const [businessname, setbusinessname] = useState("");
  const [status, setstatus] = useState("");
  const pageclass = exactpath === "/ez" ? "mt-5" : "mt-5 pt-4 mh-90";
  useEffect(() => {
    window.scrollTo(0, 0);
    setspinner(true);
    axios
      .post(API_PATH + "ManageStoreFront.php", {
        action: "getGallaryListingForStoreFront",
        upath: upath,
        limit: exactpath === "/ez" ? 12 : 0,
      })
      .then((r) => {
        console.log(r.data);
        if (r.data.status === "success") {
          setdata(r.data.retval);
          setbusinessname(r.data.businessname);
        } else {
          setstatus("failed");
        }
        setspinner(false);
      });
  }, []);

  return (
    <div className={pageclass}>
      {spinner === true && <Spinner />}
      <div className="container">
        {exactpath !== "/ez" && <GoToHome link={"/ez/" + upath} />}
        {status === "failed" && exactpath !== "/ez" ? (
          <>
            <h4 className="text-center mt-3">
              Sorry, No post for this business is updated yet.
            </h4>
            <p className="text-center">
              Some times businesses are too busy serving things to you and dont
              get time to update infromations here. If you are looking for some
              specific information. You can contact business by given button
              link.
            </p>
            <div className="text-center">
              <Link to={"/contact/" + upath}>
                <button className="btn btn-primary">Contact Business</button>
              </Link>
            </div>
          </>
        ) : (
          <>
            {(data.length > 0 || exactpath !== "/ez") && (
              <h4
                className="text-center text-secondary mt-3 mb-5"
                style={{ textTransform: "capitalize" }}
              >
                Media gallery for {businessname}
              </h4>
            )}
            <div className="row m-0">
              {data.length > 0 ? (
                data.map((d) => {
                  return (
                    <div
                      className="col-6 col-md-3 col-lg-2 p-1"
                      key={d.id}
                      data-bs-toggle="modal"
                      data-bs-target="#modal_media_gallery"
                      onClick={(e) =>
                        setmodalimage(
                          IMAGE_PATH + "/business-gallery/" + d.media_link
                        )
                      }
                    >
                      <div className="bg-white shadow ">
                        {d.media_type === "image" && (
                          <img
                            src={
                              IMAGE_PATH + "/business-gallery/" + d.media_link
                            }
                            className={"w-100 rounded"}
                            style={{
                              aspectRatio: "1/1",
                              objectFit: "contain",
                            }}
                            alt=""
                          />
                        )}
                        {d.media_type === "video" && (
                          <ReactPlayer
                            url={d.media_link}
                            width="100%"
                            height={"100%"}
                            style={{ aspectRatio: "1/1", objectFit: "contain" }}
                            controls={true}
                          />
                        )}
                      </div>
                    </div>
                  );
                })
              ) : (
                <>
                  {exactpath !== "/ez" && (
                    <>
                      <h4 className="text-center">
                        Sorry, No Media for this business is updated yet.
                      </h4>
                      <p className="text-center">
                        Some times businesses are too busy serving things to you
                        and dont get time to update infromations here. If you
                        are looking for some specific information. You can
                        contact business by given button link.
                      </p>
                      <div className="text-center">
                        <Link to={"/contact/" + upath}>
                          <button className="btn btn-primary">
                            Contact Business
                          </button>
                        </Link>
                      </div>
                    </>
                  )}
                </>
              )}
            </div>
          </>
        )}
      </div>
      {exactpath === "/ez" && (
        <div className="text-center mt-5">
          <Link to={"/gallery/" + upath}>
            <button className="btn bg-white border-primary rounded-pill shadow">
              View More Media Items
            </button>
          </Link>
        </div>
      )}
      {/* modal start */}
      <div
        className="modal fade modal-xl"
        id="modal_media_gallery"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex="-1"
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
          <div className="modal-content">
            <div className="modal-header p-1 ps-2 pe-2">
              <h1 className="modal-title fs-5" id="staticBackdropLabel">
                View it large
              </h1>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <div className="row m-0">
                <img src={modalimage} alt="" className="w-100" />
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* modal end  */}
    </div>
  );
}
