import React from 'react'
import axios from "axios";
import { useEffect, useState } from "react";
import { Navigate} from "react-router-dom";
import {
  API_PATH,
  IMAGE_PATH,
  USER_ID,
  USER_ROLE,
  USER_TOKEN,
} from "../../Config";
import useLoginAuth from "../../hooks/useLoginAuth";
import Spinner from '../../layout/common/Spinner';

export default function ViewCustomerBaseData() {
  const [spinner, setspinner] = useState(false)

  const validatelogin = useLoginAuth();
  const [dataLimit, setDataLimit] = useState(100);
  const [orderBy, setOrderBy] = useState("DESC");
  const [skipper, setSkipper] = useState(0);
  // const [fromDate, setFromDate] = useState("");
  // const [tillDate, setTillDate] = useState("");
  const [searchstr, setsearchstr] = useState("");
  const [dataStatus, setDataStatus] = useState("");
  const [dataset, setDataSet] = useState([]);
  const [counter, setcounter] = useState(0);
  

  useEffect(() => {
    setspinner(true)
    axios
      .post(API_PATH + `ManageUser.php`, {
        userid: USER_ID,
        userrole: USER_ROLE,
        usertoken: USER_TOKEN,
        searchstr: searchstr,
        datalimit: dataLimit,
        orderby: orderBy,
        fromdate: null,
        tilldate: null,
        skipval: skipper,
        action: "getcustomerbaselisting",
      })
      .then((res) => {
        setDataStatus(res.data.status);
        setDataSet(res.data.retval);
        setspinner(false)
      });
  }, [counter, searchstr]);

  // reduce skipper value only if skipper value is
  // greater than zero else it will show blank page on
  // next increament

  const reduceSkipper = (e) => {
    if (skipper > 0) {
      setSkipper(skipper - dataLimit);
      setcounter(Number(counter) + 1);
    }
  };

  // increase skipper value only if skipper value is
  // greater than zero else it will show blank page on
  // next increament

  const increaseSkipper = (e) => {
    setSkipper(Number(skipper) + Number(dataLimit));
    setcounter(Number(counter) + 1);
  };

  // list all dependency in dependency
  //array to let filter work properly
  // delete product on action change event
  const deleteProduct = (prodid) => {
    let confirm = window.confirm(
      "Are you sure you want to delete this product?"
    );
    if (confirm) {
      setspinner(true)
      axios
        .post(API_PATH + `ManageInventory.php`, {
          userid: USER_ID,
          userrole: USER_ROLE,
          usertoken: USER_TOKEN,
          action: "deleteProductById",
          productid: prodid,
        })
        .then((r) => {
          if (r.data.status === "success") {
            setcounter(Number(counter) + 1);
            alert("Product Deleted Successfully!");
          }
          setspinner(false)
        });
    }
  };

  // action button on change event
  const action = (e) => {
    e.preventDefault();
    switch (e.target.value) {
      case "view":
        // navigate('/product-view/'+e.target.id);
        break;

      case "edit":
        // navigate("/update-card-holder-data/" + e.target.id);
        break;

      case "delete":
        deleteProduct(e.target.id);
        break;

      default:
        break;
    }
  };

  // process data on load or state change
  if (validatelogin !== true) {
    return <Navigate to={"/login"} />;
  } else {
    return (
      <>
        <div className="bg-light p-2 pt-5 mh-90 pb-5">
          {spinner === true && <Spinner/>}
          <div className="row m-0 mt-5">
            <div className="col-6 col-sm-3 col-md-2 p-1">
              <label className="">Data Limit</label>
              <select
                className="form-control p-1"
                onChange={(e) => {
                  setDataLimit(e.target.value);
                }}
              >
                <option value={dataLimit} key="">
                  {dataLimit}
                </option>
                <option value="100">100</option>
                <option value="200">200</option>
                <option value="500">500</option>
                <option value="1000">1000</option>
                <option value="5000">5000</option>
              </select>
            </div>
            <div className="col-6 col-sm-3 col-md-2 p-1">
              <label className="">Order By</label>
              <select
                className="form-control p-1"
                onChange={(e) => {
                  setOrderBy(e.target.value);
                }}
              >
                <option value={orderBy}>{orderBy}</option>
                <option value="ASC">ASC</option>
                <option value="DESC">DESC</option>
              </select>
            </div>

            {/* <div className="col col-sm-3 col-md-2 p-1">
                    <label className="">Date From</label>
                    <input type="date" className="form-control p-1" onChange={e=>{setFromDate(e.target.value)}}/>
                </div>
                <div className="col col-sm-3 col-md-2 p-1">
                    <label className="">Till Date</label>
                    <input type="date" className="form-control p-1" onChange={e=>{setTillDate(e.target.value)}}/>
                </div> */}

            <div className="col p-1">
              <label>Navigate</label>
              <div className="p-0">
                <button
                  className="btn bg-danger text-white p-1"
                  onClick={reduceSkipper}
                >
                  <i className="bi bi-arrow-bar-left"></i>
                </button>
                <button
                  className="ms-2 btn bg-success text-white p-1"
                  onClick={increaseSkipper}
                >
                  <i className="bi bi-arrow-bar-right"></i>
                </button>
              </div>
            </div>
            <div className="col col-sm-3 col-md-2 p-1">
              <label className="">Search Customer</label>
              <input
                type="search"
                className="form-control p-1"
                onChange={(e) => {
                  setsearchstr(e.target.value);
                }}
              />
            </div>
          </div>
          <div
            className="p-2"
            style={{ overflowX: "scroll", fontSize: "12px" }}
          >
            <table className=" rounded bg-white table table-responsive">
              <thead>
                <tr key="">
                  {/* <th>Action</th> */}
                  <th>Full Name</th>
                  <th>Contact</th>
                  <th>Email</th>
                  <th>Is Business?</th>
                  <th>Business Name</th>
                  <th>GSTIN</th>
                  <th>Address</th>
                  <th>City</th>
                  <th>District</th>
                  <th>State</th>
                  <th>Pincode</th>
                  <th>Date</th>
                  <th>Time</th>
                </tr>
              </thead>
              <tbody>
                {dataStatus === "success" &&
                  dataset.map((d) => {
                    return (
                      <tr key={d.id}>
                        {/* <td>
                          <select id={d.id} onChange={action}>
                            <option value="none" key="0"></option> */}
                            {/* <option value="view" key="1">View</option> */}
                            {/* <option value="edit" key="2">Edit</option> */}
                            {/* <option value="delete" key="3">
                              Delete
                            </option> */}
                          {/* </select>
                        </td> */}
                        <td>{d.fname+' '+d.mname+' '+d.lname}</td>
                        <td><a href={"tel:"+d.contact}>{d.contact}</a></td>
                        <td>{d.email}</td>
                        <td>{d.is_business}</td>
                        <td>{d.business_name}</td>
                        <td>{d.gstn_no}</td>
                        <td>{d.address}</td>
                        <td>{d.city}</td>
                        <td>{d.district}</td>
                        <td>{d.state}</td>
                        <td>{d.pincode}</td>
                        <td>{d.date}</td>
                        <td>{d.time}</td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          </div>
        </div>
      </>
    );
  }
}
