import React, { useEffect, useState } from "react";
import { API_PATH, IMAGE_PATH } from "../../Config";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import useLoginAuth from "../../hooks/useLoginAuth";
import Spinner from "../../layout/common/Spinner";

export default function BusinessSearchBar() {
  const [spinner,setspinner] = useState(false)

  const validatelogin = useLoginAuth();
  const [searchkey, setsearchkey] = useState("");
  const [justloaded, setjustloaded] = useState(true);
  const [searchres, setsearchres] = useState([]);
  const navigate = useNavigate();

  const searchBusiness = (e) => {
    setsearchkey(e.target.value);
    setjustloaded(false);
    if (e.target.value.length > 0) {
      axios
        .post(API_PATH + "ManageStoreFront.php", {
          action: "searchBusiness",
          searchkey: e.target.value,
        })
        .then((r) => {
          // console.log(r.data)
          if (r.data.status === "success") {
            setsearchres(r.data.retval);
          }
        });
    }
  };

  useEffect(() => {
    setspinner(true)
    axios
      .post(API_PATH + "ManageStoreFront.php", {
        action: "searchBusiness",
        searchkey: "default",
      })
      .then((r) => {
        if (r.data.status === "success") {
          setsearchres(r.data.retval);
        }
        setspinner(false)
      });
  }, []);
  return (
    <>
      <div className="container mt-4">
        {spinner === true && <Spinner/>}
        <div className="row m-0">
          <div className="col-md-3"></div>
          <div className="col-md-6 p-1">
            <input
              type="search"
              placeholder="Search by Business or Product"
              className="form-control border shadow"
              onChange={searchBusiness}
              value={searchkey}
            />
          </div>
          <div className="col-md-3"></div>
          {/* <div className='col-md-6 p-1'>
                    <select className='form-control'>
                        <option value="" key="">Filter by business Category</option>
                        <option value="" key="">Category 1</option>
                        <option value="" key="">Category 2</option>
                    </select>
                </div> */}
        </div>
      </div>
      <div className="container mt-4">
        <div className="row m-0">
          {justloaded === true && (
            <div className="text-center text-dark mb-4 mt-2">
              <h1>Search any business here</h1>
              <p>
                Searching businesses on Biziness.in is very easy. you can search
                any business registered here with multiple parameteres like
                business name, contact number, city, district, pincode, business
                category , gst number or company cin number.
              </p>
            </div>
          )}
          {searchres.map((d) => {
            return (
              <div className="col-md-6 col-lg-4 p-1" key={d.id}>
                <div className="text-center">
                  <span className="rounded ps-1 pe-1 bg-white text-bold text-secondary">
                    {" "}
                    {d.b_name}{" "}
                  </span>
                </div>
                <div
                  className="shadow bg-white row ms-0 me-0 p-0 pt-1 border-bottom border"
                  style={{
                    // border: "1px dashed #ffcc00",
                    borderRadius: "15px",
                    marginTop: -11,
                  }}
                >
                  <div className="col-4 p-1">
                    <div
                      className="mb-1 border"
                      style={{
                        // border: "1px dashed #ffcc00",
                        borderRadius: "10px",
                      }}
                    >
                      <img
                        src={
                          d.b_profile_image !== null &&
                          d.b_profile_image.length > 5
                            ? IMAGE_PATH +
                              "./business-profile/" +
                              d.b_profile_image
                            : require("../../assets/img/bzlogo-transparent.png")
                        }
                        alt=""
                        style={{
                          borderRadius: "10px",
                          width: "100%",
                          aspectRatio: "1/1",
                          objectFit: "cover",
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-8 p-1" style={{ fontSize: 14 }}>
                    <div style={{ height: "90px", overflowY: "scroll" }} className="rounded">
                      Address :{" "}
                      {d.office_address +
                        " " +
                        d.office_city +
                        " " +
                        d.office_district +
                        " " +
                        d.office_state +
                        " " +
                        (d.office_pincode > 0 ? d.office_pincode : "")}
                    </div>
                    <div>
                      Contact :{" "}
                      {validatelogin === true ? (
                        d.b_contact > 0 ? (
                          <a
                            href={"tel:" + d.b_contact}
                            style={{ textDecoration: "none" }}
                          >
                            {d.b_contact + " "}
                            <i className="bi bi-telephone-fill"></i>
                          </a>
                        ) : (
                          <>Not Available</>
                        )
                      ) : (
                        <>Login to view</>
                      )}
                    </div>
                    <div className="text-center mt-1">
                      <button
                        className="btn btn-light shadow border rounded-pill p-1 ps-2 pe-2"
                        style={{ fontSize: 14 }}
                        onClick={() => navigate("/ez/" + d.unique_user_name)}
                      >
                        <i className="bi bi-eyeglasses"></i> View
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
}
