import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { API_PATH, IMAGE_PATH } from "../../Config";
import axios from "axios";
import parse from "html-react-parser";
import GoToHome from "./GoToHome";
import Spinner from "../../layout/common/Spinner";
import useExactPath from "../../hooks/useExactPath";

export default function StoreFrontIntro() {
  const [spinner, setspinner] = useState(false);
  const params = useParams();
  const upath = params.id;
  const [bdata, setbdata] = useState([]);
  const [introdisplay, setintrodisplay] = useState(true);
  const [introstyle, setintrostyle] = useState({
    height: "200px",
    overflow: "hidden",
  });
  const exactpath = useExactPath();

  useEffect(() => {
    window.scrollTo(0, 0);
    setspinner(true);
    axios
      .post(API_PATH + "ManageStoreFront.php", {
        action: "getBusinessIntroData",
        upath: upath,
      })
      .then((r) => {
        if (r.data.status === "success") {
          setbdata(r.data.retval);
        } else {
          // alert("Something went wrong. We didn't find the data.");
        }
        setspinner(false);
      });
  }, [upath]);

  const toggelintro = () => {
    if (introdisplay === true) {
      setintrodisplay(false);
    } else {
      setintrodisplay(true);
    }
  };

  return (
    <>
      {bdata.content && bdata.content !== "" && (
        <div className="container mt-0 p-3">
          <div className="shadow bg-white rounded p-2">
            <div
              className="text-justify"
              style={
                introdisplay === false
                  ? { height: "100%" }
                  : { height: "200px", overflow: "hidden" }
              }
            >
              {bdata.content && parse(bdata.content)}
            </div>
            <div className="text-center mt-4">
              <button
                className="btn btn-primary rounded-pill border-primary"
                onClick={toggelintro}
              >
                {introdisplay === false ? "Hide" : "Show More"}
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
