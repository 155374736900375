import axios from "axios";
import React, { useState, useEffect } from "react";
import { Navigate } from "react-router-dom";
import { API_PATH, USER_ID, USER_TOKEN } from "../../Config";
import useLoginAuth from "../../hooks/useLoginAuth";
import useValidateRegType from "../../hooks/useValidateRegType";
import PayForExpired from "../../layout/common/PayForExpired";
import Spinner from "../../layout/common/Spinner";

export default function AddNewProduct() {
  const [spinner, setspinner] = useState(false);

  const validatelogin = useLoginAuth();
  const validateSubscription = useValidateRegType();

  // prepare category selection
  const [selectCat1, setSelectCat1] = useState([]);
  const [prodCat, setProdCat] = useState("");

  const [selectsubCat1, setselectsubCat1] = useState([]);
  const [subcat1, setsubcat1] = useState("");

  const [selectsubCat2, setselectsubCat2] = useState([]);
  const [subcat2, setsubcat2] = useState("");

  const [selectsubCat3, setselectsubCat3] = useState([]);
  const [subcat3, setsubcat3] = useState("");

  const [selectsubCat4, setselectsubCat4] = useState([]);
  const [subcat4, setsubcat4] = useState("");

  const [resultStatus, setResultStatus] = useState("");
  const [resultStatusClass, setResultStatusClass] = useState("");
  
  useEffect(() => {
    window.scrollTo(0, 0);
    setspinner(true);
    axios
      .post(API_PATH + "ManageInventory.php", {
        usertoken: USER_TOKEN,
        action: "getCategoryData",
        parentcat: "",
      })
      .then((r) => {
        r.data.status === "success"
          ? setSelectCat1(r.data.retval)
          : setSelectCat1([]);
        setspinner(false);
      });
  }, []);

  // sub category 1
  useEffect(() => {
    if (prodCat.length > 2) {
      axios
        .post(API_PATH + "ManageInventory.php", {
          usertoken: USER_TOKEN,
          action: "getCategoryData",
          parentcat: prodCat,
        })
        .then((r) => {
          r.data.status === "success"
            ? setselectsubCat1(r.data.retval)
            : setselectsubCat1([]);
        });
    }
  }, [prodCat]);

  // sub category 2
  useEffect(() => {
    if (subcat1.length > 2) {
      axios
        .post(API_PATH + "ManageInventory.php", {
          usertoken: USER_TOKEN,
          action: "getCategoryData",
          parentcat: subcat1,
        })
        .then((r) => {
          r.data.status === "success"
            ? setselectsubCat2(r.data.retval)
            : setselectsubCat2([]);
        });
    }
  }, [subcat1]);

  // sub category 3
  useEffect(() => {
    if (subcat2.length > 2) {
      axios
        .post(API_PATH + "ManageInventory.php", {
          usertoken: USER_TOKEN,
          action: "getCategoryData",
          parentcat: subcat2,
        })
        .then((r) => {
          r.data.status === "success"
            ? setselectsubCat3(r.data.retval)
            : setselectsubCat3([]);
        });
    }
  }, [subcat2]);

  // sub category 4
  useEffect(() => {
    if (subcat3.length > 2) {
      axios
        .post(API_PATH + "ManageInventory.php", {
          usertoken: USER_TOKEN,
          action: "getCategoryData",
          parentcat: subcat3,
        })
        .then((r) => {
          r.data.status === "success"
            ? setselectsubCat4(r.data.retval)
            : setselectsubCat4([]);
        });
    }
  }, [subcat3]);

  // change values of other categories if parent has changed
  const processprimarycategory = (e) => {
    setProdCat(e.target.value);

    setselectsubCat1([]);
    setsubcat1("");
    setselectsubCat2([]);
    setsubcat2("");
    setselectsubCat3([]);
    setsubcat3("");
    setselectsubCat4([]);
    setsubcat4("");
  };
  const processsubcat1 = (e) => {
    setsubcat1(e.target.value);
    setselectsubCat2([]);
    setsubcat2("");
    setselectsubCat3([]);
    setsubcat3("");
    setselectsubCat4([]);
    setsubcat4("");
  };
  const processsubcat2 = (e) => {
    setsubcat2(e.target.value);
    setselectsubCat3([]);
    setsubcat3("");
    setselectsubCat4([]);
    setsubcat4("");
  };
  const processsubcat3 = (e) => {
    setsubcat3(e.target.value);
    setselectsubCat4([]);
    setsubcat4("");
  };

  // formdata
  const [prodServiceType, setProdServiceType] = useState("product");
  const [fullName, setFullName] = useState("");
  const [alias, setAlias] = useState("");
  const [sku, setSku] = useState("");
  const [barcode, setBarcode] = useState("");
  const [stockQty, setStockQty] = useState("0");
  const [mrp, setMrp] = useState("0");
  const [salePrice, setSalePrice] = useState("0");
  const [finalSalePrice, setFinalSalePrice] = useState("0");
  const [weight, setWeight] = useState("0");
  const [weighingUnit, setWeighingUnit] = useState("");
  const [qtyInPack, setQtyInPack] = useState("0");
  const [itemCode, setItemCode] = useState("");
  const [itemGroupCode, setItemGroupCode] = useState("");
  const [brandName, setBrandName] = useState("");
  const [shortDesc, setShortDesc] = useState("");
  const [longDesc, setLongDesc] = useState("");
  const [mfgDate, setMfgDate] = useState("");
  const [expiryDate, setExpiryDate] = useState("");
  const [origin, setOrigin] = useState("India");
  // const [taxType, setTaxType] = useState("none");
  const [taxRate, setTaxRate] = useState("0");
  const [taxAmount, setTaxAmount] = useState("0");
  const [hsnCode, setHsnCode] = useState("");
  const [batchNumber, setBacthNumber] = useState("");
  const [prodImage1, setProdImage1] = useState([]);
  const [prodImage2, setProdImage2] = useState([]);
  const [prodImage3, setProdImage3] = useState([]);
  const [prodImage4, setProdImage4] = useState([]);
  const [prodImage5, setProdImage5] = useState([]);

  // setNewsImagePreview(URL.createObjectURL(e.target.files[0]));

  const addProductToData = () => {
    const prodFormData = new FormData();
    prodFormData.append("action", "addProductToData");
    prodFormData.append("userid", USER_ID);
    prodFormData.append("username", "");
    prodFormData.append("usertoken", USER_TOKEN);

    prodFormData.append("prodImage1", prodImage1);
    prodFormData.append("prodImage2", prodImage2);
    prodFormData.append("prodImage3", prodImage3);
    prodFormData.append("prodImage4", prodImage4);
    prodFormData.append("prodImage5", prodImage5);

    prodFormData.append("primarycategory", prodCat);
    prodFormData.append("subcat1", subcat1);
    prodFormData.append("subcat2", subcat2);
    prodFormData.append("subcat3", subcat3);
    prodFormData.append("subcat4", subcat4);

    prodFormData.append("prodServiceType", prodServiceType);
    prodFormData.append("fullName", fullName);
    prodFormData.append("alias", alias);
    prodFormData.append("sku", sku);
    prodFormData.append("barcode", barcode);
    prodFormData.append("stockQty", stockQty);
    prodFormData.append("mrp", mrp);
    prodFormData.append("salePrice", salePrice);
    prodFormData.append("finalsaleamount", finalSalePrice);
    prodFormData.append("weight", weight);
    prodFormData.append("weighingUnit", weighingUnit);
    prodFormData.append("qtyInPack", qtyInPack);
    prodFormData.append("prodCat", prodCat);
    prodFormData.append("itemCode", itemCode);
    prodFormData.append("itemGroupCode", itemGroupCode);
    prodFormData.append("brandName", brandName);
    prodFormData.append("shortDesc", shortDesc);
    prodFormData.append("longDesc", longDesc);
    prodFormData.append("mfgDate", mfgDate);
    prodFormData.append("expiryDate", expiryDate);
    prodFormData.append("origin", origin);
    prodFormData.append("taxType", "none");
    prodFormData.append("taxRate", taxRate);
    prodFormData.append("taxamount", taxAmount);
    prodFormData.append("hsnCode", hsnCode);
    prodFormData.append("batchNumber", batchNumber);

    // check params are filled or not

    if (
      !fullName ||
      !stockQty ||
      stockQty <1 ||
      !mrp ||
      mrp <1 ||
      !salePrice ||
      salePrice < 1 ||
      !prodCat ||
      !shortDesc ||
      !longDesc ||
      !origin ||
      // !taxType ||
      !taxRate ||
      !prodImage1
    ) {
      setResultStatus("Please enter mandatory fields, Numbers should be more than zero.");
      setResultStatusClass("alert alert-danger p-1");
      setTimeout(() => {
        setResultStatus("");
        setResultStatusClass("");
      }, 5000);
    } else {
      setspinner(true)
      axios
        .post(API_PATH + "ManageInventory.php", prodFormData, {
          headers: {
            "content-type": "multipart/form-data",
          },
        })
        .then((res) => {
          console.log(res)
          if (res.data.status === "success") {
            setResultStatus("Data Updated Successfully");
            setResultStatusClass("alert alert-success p-1");
          } else {
            setResultStatus("Something went wrong, please try again later.");
            setResultStatusClass("alert alert-danger p-1");
          }
          setTimeout(() => {
            setResultStatus("");
            setResultStatusClass("");
          }, 3000);
          setspinner(false)
        });
    }

    window.scrollTo(0, 0);
  };

  const calcSalePrice = (e) => {
    setSalePrice(e.target.value);
    var taxamount = (e.target.value * taxRate) / 100;
    setTaxAmount(taxamount);
    var finalsaleamount = Number(e.target.value) + Number(taxamount);
    setFinalSalePrice(finalsaleamount);
  };

  const calcTaxAmount = (e) => {
    setTaxRate(e.target.value);
    var taxamount = (salePrice * e.target.value) / 100;
    setTaxAmount(taxamount);
    var finalsaleamount = Number(salePrice) + Number(taxamount);
    setFinalSalePrice(finalsaleamount);
  };

  if (validatelogin !== true) {
    return <Navigate to={"/login"} />;
  } else {
    if (validateSubscription.status === false) {
      return <PayForExpired />;
    } else {
      return (
        <>
          <div className="bg-light mh-90">
            <div className="container mt-5 mh-90 pt-5">
              {spinner === true && <Spinner />}
              <h2 className="text-secondary">Add Product To Inventory</h2>
              <div className={resultStatusClass}>{resultStatus}</div>
              <div className="p-2 card shadow mt-2 mb-5">
                <div className="row m-0">
                  <div className="col-sm-3 pt-2">
                    <label>Product/Service Type*</label>
                    <select
                      className="border-danger form-control p-1"
                      value={prodServiceType}
                      onChange={(e) => {
                        setProdServiceType(e.target.value);
                      }}
                    >
                      <option value="product">Product</option>
                      <option value="service">Service</option>
                    </select>
                  </div>
                  <div className="col-sm-9 pt-2">
                    <label>Prod. Full Name*</label>
                    <input
                      type="text"
                      className="border-danger form-control p-1"
                      value={fullName}
                      onChange={(e) => {
                        setFullName(e.target.value);
                      }}
                    />
                  </div>
                  <div className="col-sm-6 pt-2">
                    <label>Prod. Alias</label>
                    <input
                      type="text"
                      className="border-grey form-control p-1"
                      value={alias}
                      onChange={(e) => {
                        setAlias(e.target.value);
                      }}
                    />
                  </div>
                  <div className="col-sm-3 pt-2">
                    <label>SKU</label>
                    <input
                      type="text"
                      className=" form-control p-1"
                      value={sku}
                      onChange={(e) => {
                        setSku(e.target.value);
                      }}
                    />
                  </div>
                  <div className="col-sm-3 pt-2">
                    <label>Bar Code</label>
                    <input
                      type="text"
                      className=" form-control p-1"
                      value={barcode}
                      onChange={(e) => {
                        setBarcode(e.target.value);
                      }}
                    />
                  </div>
                  <div className="col-sm-3 pt-2 hideonservice">
                    <label>Prod. Stock Qty(Numbers)*</label>
                    <input
                      type="number"
                      min={0}
                      className="border-danger form-control p-1"
                      value={stockQty}
                      onChange={(e) => {
                        setStockQty(e.target.value);
                      }}
                    />
                  </div>
                  <div className="col-sm-3 pt-2">
                    <label>Prod. MRP*</label>
                    <input
                      type="number"
                      min={0}
                      className="border-danger form-control p-1"
                      value={mrp}
                      onChange={(e) => {
                        setMrp(e.target.value);
                      }}
                    />
                  </div>
                  {/* <div className="col-sm-3 pt-2">
                    <label>Invoice Type</label>
                    <select
                      className="form-control"
                      value={taxType}
                      onChange={(e) => {
                        setTaxType(e.target.value);
                      }}
                    >
                      <option value="non-gst-invoice" key="non-gst-invoice">
                        Non Gst Invoice
                      </option>
                      <option value="gst-invoice" key="gst-invoice">
                        Gst Invoice
                      </option>
                    </select>
                  </div> */}
                  <div className="col-sm-3 pt-2">
                    <label>Sale Price*</label>
                    <input
                      type="number"
                      min={0}
                      className="border-danger form-control p-1"
                      value={salePrice}
                      onChange={calcSalePrice}
                    />
                  </div>
                  <div className="col-sm-3 pt-2">
                    <label>Tax Rate GST in % </label>
                    <input
                      type="number"
                      min={0}
                      className="border-danger form-control p-1"
                      value={taxRate}
                      onChange={calcTaxAmount}
                    />
                  </div>
                  <div className="col-sm-3 pt-2">
                    <label>Tax Amount </label>
                    <input
                      type="number"
                      min={0}
                      className="border-danger form-control p-1"
                      value={taxAmount}
                      readOnly
                    />
                  </div>
                  <div className="col-sm-3 pt-2">
                    <label>Final Sale Price </label>
                    <input
                      type="number"
                      min={0}
                      className="border-danger form-control p-1"
                      value={finalSalePrice}
                      readOnly
                    />
                  </div>
                  <div className="col-sm-3 pt-2 hideonservice">
                    <label>Item Weight</label>
                    <input
                      type="number"
                      min={0}
                      step="0.01"
                      className=" form-control p-1"
                      value={weight}
                      onChange={(e) => {
                        setWeight(e.target.value);
                      }}
                    />
                  </div>
                  <div className="col-sm-3 pt-2 hideonservice">
                    <label>Weighing Unit</label>
                    <input
                      type="text"
                      className=" form-control p-1"
                      value={weighingUnit}
                      onChange={(e) => {
                        setWeighingUnit(e.target.value);
                      }}
                    />
                  </div>
                  <div className="col-sm-3 pt-2 hideonservice">
                    <label>Quantity In Packing</label>
                    <input
                      type="number"
                      min={0}
                      className=" form-control p-1"
                      value={qtyInPack}
                      onChange={(e) => {
                        setQtyInPack(e.target.value);
                      }}
                    />
                  </div>
                  <div className="col-sm-3 pt-2">
                    <label>Primary Category</label>
                    <input
                      list="primaryCategory"
                      type={"search"}
                      className="form-control border-danger"
                      value={prodCat}
                      onChange={processprimarycategory}
                      autoComplete="on"
                    />
                    <datalist id="primaryCategory">
                      {selectCat1.map((c1) => {
                        return <option value={c1.cat_name} key={c1.id} />;
                      })}
                    </datalist>
                  </div>
                  <div className="col-sm-3 pt-2">
                    <label>Sub Category 1</label>
                    <input
                      list="subcat1"
                      type={"search"}
                      className="form-control"
                      value={subcat1}
                      onChange={processsubcat1}
                      autoComplete="on"
                    />
                    <datalist id="subcat1">
                      {selectsubCat1.map((c1) => {
                        return <option value={c1.cat_name} key={c1.id} />;
                      })}
                    </datalist>
                  </div>
                  <div className="col-sm-3 pt-2">
                    <label>Sub Category 2</label>
                    <input
                      list="subcat2"
                      type={"search"}
                      className="form-control"
                      value={subcat2}
                      onChange={processsubcat2}
                      autoComplete="on"
                    />
                    <datalist id="subcat2">
                      {selectsubCat2.map((c1) => {
                        return <option value={c1.cat_name} key={c1.id} />;
                      })}
                    </datalist>
                  </div>
                  <div className="col-sm-3 pt-2">
                    <label>Sub Category 3</label>
                    <input
                      list="subcat3"
                      type={"search"}
                      className="form-control"
                      value={subcat3}
                      onChange={processsubcat3}
                      autoComplete="on"
                    />
                    <datalist id="subcat3">
                      {selectsubCat3.map((c1) => {
                        return <option value={c1.cat_name} key={c1.id} />;
                      })}
                    </datalist>
                  </div>
                  <div className="col-sm-3 pt-2">
                    <label>Sub Category 4</label>
                    <input
                      list="subcat4"
                      type={"search"}
                      className="form-control"
                      value={subcat4}
                      onChange={(e) => {
                        setsubcat4(e.target.value);
                      }}
                      autoComplete="on"
                    />
                    <datalist id="subcat4">
                      {selectsubCat4.map((c1) => {
                        return <option value={c1.cat_name} key={c1.id} />;
                      })}
                    </datalist>
                  </div>
                  <div className="col-sm-3 pt-2">
                    <label>Item Code</label>
                    <input
                      type="text"
                      className=" form-control p-1"
                      value={itemCode}
                      onChange={(e) => {
                        setItemCode(e.target.value);
                      }}
                    />
                  </div>
                  <div className="col-sm-3 pt-2">
                    <label>Item Group Code</label>
                    <input
                      type="text"
                      className=" form-control p-1"
                      value={itemGroupCode}
                      onChange={(e) => {
                        setItemGroupCode(e.target.value);
                      }}
                    />
                  </div>
                  <div className="col-sm-3 pt-2">
                    <label>Brand/Model Name</label>
                    <input
                      type="text"
                      className=" form-control p-1"
                      value={brandName}
                      onChange={(e) => {
                        setBrandName(e.target.value);
                      }}
                    />
                  </div>
                  <div className="col-sm-3 pt-2 div_mfg_date">
                    <label>Manufacturing Date</label>
                    <input
                      type="date"
                      className="border-info form-control p-1"
                      value={mfgDate}
                      onChange={(e) => {
                        setMfgDate(e.target.value);
                      }}
                    />
                  </div>
                  <div className="col-sm-3 pt-2 div_exp_date">
                    <label>Expiry Date</label>
                    <input
                      type="date"
                      className="border-info form-control p-1"
                      value={expiryDate}
                      onChange={(e) => {
                        setExpiryDate(e.target.value);
                      }}
                    />
                  </div>
                  <div className="col-sm-3 pt-2 hideonservice">
                    <label>Place Of origin*</label>
                    <input
                      type="text"
                      className="border-danger form-control p-1"
                      value={origin}
                      onChange={(e) => {
                        setOrigin(e.target.value);
                      }}
                    />
                  </div>
                  <div className="col-sm-3 pt-2">
                    <label>Hsn Code*</label>
                    <input
                      type="text"
                      className="border-grey form-control p-1"
                      value={hsnCode}
                      onChange={(e) => {
                        setHsnCode(e.target.value);
                      }}
                    />
                  </div>
                  <div className="col-sm-3 pt-2">
                    <label>Batch No.</label>
                    <input
                      type="text"
                      className=" form-control p-1"
                      value={batchNumber}
                      onChange={(e) => {
                        setBacthNumber(e.target.value);
                      }}
                    />
                  </div>
                </div>
                <div className="row m-0">
                  <div className="col-sm-12 pt-2">
                    <label>Prod. Short Description*</label>
                    <textarea
                      className="border-danger form-control p-1"
                      rows="2"
                      value={shortDesc}
                      onChange={(e) => {
                        setShortDesc(e.target.value);
                      }}
                    ></textarea>
                  </div>
                  <div className="col-sm-12 pt-2">
                    <label>Prod. Detailed Description*</label>
                    <textarea
                      className="border-danger form-control p-1"
                      rows="10"
                      value={longDesc}
                      onChange={(e) => {
                        setLongDesc(e.target.value);
                      }}
                    ></textarea>
                  </div>
                  <div className="col-sm-3 pt-2">
                    <label>Add Product Image*</label>
                    <input
                      type="file"
                      className="border-danger form-control p-1"
                      accept=".jpg, .jpeg, .png, .webp"
                      onChange={(e) => {
                        setProdImage1(e.target.files[0]);
                      }}
                    />
                  </div>
                  <div className="col-sm-3 pt-2">
                    <label>Add Product Image 2</label>
                    <input
                      type="file"
                      className="border-info form-control p-1"
                      accept=".jpg, .jpeg, .png, .webp"
                      onChange={(e) => {
                        setProdImage2(e.target.files[0]);
                      }}
                    />
                  </div>
                  <div className="col-sm-3 pt-2">
                    <label>Add Product Image 3</label>
                    <input
                      type="file"
                      className="border-info form-control p-1"
                      accept=".jpg, .jpeg, .png, .webp"
                      onChange={(e) => {
                        setProdImage3(e.target.files[0]);
                      }}
                    />
                  </div>
                  <div className="col-sm-3 pt-2">
                    <label>Add Product Image 4</label>
                    <input
                      type="file"
                      className="border-info form-control p-1"
                      accept=".jpg, .jpeg, .png, .webp"
                      onChange={(e) => {
                        setProdImage4(e.target.files[0]);
                      }}
                    />
                  </div>
                </div>
                <div className="row m-0">
                  <div className="col-sm-3 pt-2">
                    <label>Add Product Image 5</label>
                    <input
                      type="file"
                      className="border-info form-control p-1"
                      accept=".jpg, .jpeg, .png, .webp"
                      onChange={(e) => {
                        setProdImage5(e.target.files[0]);
                      }}
                    />
                  </div>
                  <div className="col-sm-3 pt-2">
                    <label>Add Data to inventory</label>
                    <button
                      className="btn btn-primary text-white form-control p-1"
                      onClick={addProductToData}
                    >
                      Add record
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      );
    }
  }
}
