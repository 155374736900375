import React, { useEffect, useState } from "react";
import { Link, Navigate, useNavigate } from "react-router-dom";
import ProfileCompletionDashboard from "./ProfileCompletionDashboard";
import axios from "axios";
import { API_PATH } from "../../Config";
import useLoginAuth from "../../hooks/useLoginAuth";
import GoogleMap from "../map/GoogleMap";
import Spinner from "../../layout/common/Spinner";

export default function UpdateBusinessContacts() {
  const [spinner, setspinner] = useState(false);
  const validatelogin = useLoginAuth();
  const navigate = useNavigate();

  const [profiledata, setprofiledata] = useState([]);
  const [profilescore, setprofilescore] = useState("0%");
  const [businesscontact, setbusinesscontact] = useState("");
  const [businessemail, setbusinessemail] = useState("");
  const [businesswhatsapp, setbusinesswhatsapp] = useState("");

  // important to get map to work
  const [gpsstatus, setgpsstatus] = useState("disabled");
  const [latitude, setlatitude] = useState("");
  const [longitude, setlongitude] = useState("");

  useEffect(() => {
    window.scrollTo(0, 0);
    setspinner(true);
    axios
      .post(API_PATH + "ManageStoreFront.php", {
        action: "getBusinessProfile",
        userid: localStorage.getItem("ez-userid"),
        usertoken: localStorage.getItem("ez-usertoken"),
      })
      .then((r) => {
        if (r.data.status === "success") {
          setprofiledata(r.data.retval);
          setprofilescore(r.data.profilescore + "%");
        } else {
          setprofilescore("0%");
        }
        setspinner(false);
      });
  }, []);

  useEffect(() => {
    // if ("geolocation" in navigator) {
    //   console.log("Available");
    // } else {
    //   console.log("Not Available");
    // }

    // navigator.geolocation.getCurrentPosition(function (position) {
    //   console.log("Latitude is :", position.coords.latitude);
    //   console.log("Longitude is :", position.coords.longitude);
    // });

    navigator.geolocation.watchPosition(function(position) {
      let lat = position.coords.latitude;
      let long = position.coords.longitude;

      if (lat !== "" && long !== "") {
        setgpsstatus("enabled");
        setlatitude(position.coords.latitude);
        setlongitude(position.coords.longitude);
      } else {
        setgpsstatus("disabled");
      }
    });
  }, []);

  const updateContacts = () => {
    let score = 0;

    if (businesscontact !== "") {
      score += 1;
    }

    if (businessemail !== "") {
      score += 1;
    }

    if (businesswhatsapp !== "") {
      score += 1;
    }

    if (latitude !== "") {
      score += 1;
    }
    if (longitude !== "") {
      score += 1;
    }

    if (score > 0) {
      if (
        (businesswhatsapp.length > 0 && businesswhatsapp.length !== 10) ||
        (businesscontact.length > 0 && businesscontact.length !== 10)
      ) {
        alert("Contact and whatsapp number should be of 10 digit!");
      } else {
        setspinner(true);
        axios
          .post(API_PATH + "ManageStoreFront.php", {
            action: "updateBusinessContact",
            userid: localStorage.getItem("ez-userid"),
            usertoken: localStorage.getItem("ez-usertoken"),
            contactnumber: businesscontact,
            email: businessemail,
            whatsapp: businesswhatsapp,
            lat: latitude,
            long: longitude,
          })
          .then((r) => {
            if (r.data.status === "success") {
              alert("Contact data uploaded successfully.");
            } else {
              alert("Something went wrong, please try again.");
            }
            setspinner(false);
          });
      }
    } else {
      alert("Can not update empty fields.");
    }
  };

  if (validatelogin !== true) {
    return <Navigate to={"/login"} />;
  } else {
    return (
      <div className="pt-5 mh-90 pb-5 bg-white">
        {spinner === true && <Spinner />}
        <div className="container mt-4">
          <h2 className="text-secondary">Update your Contact Details</h2>
          <small>
            Fill up details reuired in form fields and create your own EasyStore
            where you can display all your business information at your
            customized business page. Click{" "}
            {
              <Link
                to={"/ez/" + profiledata.unique_user_name}
                className="border rounded bg-warning text-dark p-1"
              >
                Here
              </Link>
            }{" "}
            to view your page progress.
          </small>
        </div>
        <ProfileCompletionDashboard score={profilescore} />
        <div className="container mt-4">
          <div className="row pb-4">
            <div className="pt-2 col-md-3"></div>
            <div className="pt-2 col-md-6">
              <label>Your Business Contact Number </label>
              <input
                type="number"
                min={0}
                className="form-control"
                placeholder={profiledata.b_contact}
                onChange={(e) => setbusinesscontact(e.target.value)}
              />
            </div>
            <div className="pt-2 col-md-3"></div>
            <div className="pt-2 col-md-3"></div>
            <div className="pt-2 col-md-6">
              <label>Office Email Address </label>
              <input
                type="email"
                className="form-control"
                placeholder={profiledata.b_email}
                onChange={(e) => setbusinessemail(e.target.value)}
              />
            </div>
            <div className="pt-2 col-md-3"></div>
            <div className="pt-2 col-md-3"></div>
            <div className="pt-2 col-md-6">
              <label>Office Whatsapp Number </label>
              <input
                type="number"
                className="form-control"
                placeholder={profiledata.b_whatsapp}
                onChange={(e) => setbusinesswhatsapp(e.target.value)}
              />
            </div>
            <div className="pt-2 col-md-3"></div>
            <div className="pt-2 col-md-3"></div>
            <div className="pt-2 col-md-6">
              <div className="alert alert-danger">
                Turn on your phone's GPS and move around to capture correct
                location
              </div>
              Latitude is {latitude} <br />
              Longtitude is {longitude}
              <div className="rounded mt-2">
                {// google map will not work when lat or long is blank , check for value before using map
                gpsstatus === "enabled" && latitude > 0 && longitude > 0 ? (
                  <GoogleMap
                    lat={latitude}
                    long={longitude}
                    zoom={15}
                    mapwidth={"100%"}
                    mapheight={"300px"}
                  />
                ) : (
                  <div>
                    <p>
                      Either GPS is not enabled or we are not to get your
                      location.{" "}
                    </p>
                  </div>
                )}
              </div>
            </div>
            <div className="pt-2 col-md-3"></div>
          </div>
          <div className="row mt-3">
            <div className="d-none d-lg-block col-lg-3"></div>
            <div className="col-lg-3 text-center p-2">
              <button className="btn btn-primary" onClick={updateContacts}>
                Update Contacts
              </button>
            </div>
            <div className="col-lg-3 text-center p-2">
              <button
                className="btn btn-primary"
                onClick={() => navigate("/update-social-media-links")}
              >
                Go to Social Media
              </button>
            </div>
            <div className="d-none d-lg-block col-lg-3 p-2"></div>
          </div>
        </div>
      </div>
    );
  }
}
