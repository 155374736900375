import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { API_PATH } from "../../Config";
import axios from "axios";
import GoogleMap from "../map/GoogleMap";
import GoToHome from "./GoToHome";
import useExactPath from "../../hooks/useExactPath";
import Spinner from "../../layout/common/Spinner";

export default function StoreFrontContact() {
  const [spinner, setspinner] = useState(false)
  
  const exactpath = useExactPath();

  const params = useParams();
  const upath = params.id;

  const [bdata, setbdata] = useState([]);
  const [latitude, setlatitude] = useState("");
  const [longtitude, setlongtitude] = useState("");

  const [fullname, setfullname] = useState("");
  const [bcontactnumber, setbcontactnumber] = useState("");
  const [bemail, setbemail] = useState("");
  const [bpurpose, setbpurpose] = useState("");

  useEffect(() => {
    window.scrollTo(0, 0);
    setspinner(true)
    axios
      .post(API_PATH + "ManageStoreFront.php", {
        action: "getBusinessContactData",
        upath: upath,
      })
      .then((r) => {
        if (r.data.status === "success") {
          setbdata(r.data.retval);
          setlatitude(Number(r.data.retval.latitude));
          setlongtitude(Number(r.data.retval.longtitude));
        } else {
          alert("Something went wrong. We didn't find the data.");
        }
        setspinner(false)
      });
  }, [upath]);

  const generatecontactrequest = (e) => {
    e.preventDefault();
    if (!fullname || !bcontactnumber || !bemail || !bpurpose) {
      alert("Please fill up all contact details.");
    } else {
      if (bcontactnumber.length < 10) {
        alert("Contact number can not be less than 10 digits");
      } else {
        setspinner(true)
        axios
          .post(API_PATH + "ManageStoreFront.php", {
            action: "sendcontactrequest",
            upath: upath,
            fullname: fullname,
            bcontactnumber: bcontactnumber,
            bemail: bemail,
            bpurpose: bpurpose,
          })
          .then((r) => {
            if (r.data.status === "success") {
              setfullname("");
              setbcontactnumber("");
              setbemail("");
              setbpurpose("");
              alert(
                "Contact details sent successfully, Someone will call you back very soon."
              );
            } else {
              alert("Something went wrong. Please try again");
            }
            setspinner(false)
          });
      }
    }
  };

  return (
    <>
      {bdata && (
        <div className="container mt-5 pt-4 pb-5 mh-90">
          {spinner === true && <Spinner/>}
          {exactpath !== "/ez" && (
            <>
              <GoToHome link={"/ez/" + upath} />
              <h2
                className="text-secondary mt-2"
                style={{ textTransform: "uppercase" }}
              >
                Contact Details of {bdata.b_name}
              </h2>
              <div className="d-flex bg-white shadow p-2 rounded" style={{ textTransform: "uppercase" }}>
                <div className="bg-primary text-white rounded p-1">Cin : {bdata.b_cin}</div>
                <div className="bg-primary text-white rounded ms-1 p-1">
                  Pan : {bdata.b_pan}
                </div>
                <div className="bg-primary text-white rounded ms-1 p-1">
                  GSt : {bdata.b_pan}
                </div>
              </div>
              <div
                className="rounded p-1 mt-3 shadow bg-white"
              >
                <div className="row">
                  <div className="col-md-6">
                    <h5 className="p-0 m-0 border-bottom p-1">
                      Key Person Details
                    </h5>
                    <p className="mt-2" style={{ textTransform: "capitalize" }}>
                      Contact Person :{" "}
                      {bdata.key_person_fname +
                        " " +
                        bdata.key_person_mname +
                        " " +
                        bdata.key_person_lname}{" "}
                      <br />
                      Key Person profile : {bdata.key_person_profile}
                    </p>
                  </div>
                  <div className="col-md-6">
                    <h5 className="p-0 m-0 border-bottom p-1">
                      Contact Details
                    </h5>
                    <p className="mt-2" style={{ textTransform: "capitalize" }}>
                      Contact number :{" "}
                      <a href={"tel:+91" + bdata.b_contact}>
                        <i className="text-success bi bi-telephone-forward-fill"></i>{" "}
                        {bdata.b_contact}
                      </a>
                      <br />
                      Office Email : {bdata.b_email} <br />
                      Office Whatsapp : {bdata.b_whatsapp}
                    </p>
                  </div>
                </div>
              </div>
              <div
                className="rounded p-1 mt-4 mb-4 bg-white shadow"
              >
                <div className="row">
                  <div className="col-md-6">
                    <h5 className="p-0 m-0 border-bottom p-1">
                      Office Address
                    </h5>
                    <p className="mt-2" style={{ textTransform: "capitalize" }}>
                      Address :
                      {bdata.office_address +
                        " " +
                        bdata.office_city +
                        " " +
                        bdata.office_district +
                        " " +
                        bdata.office_state +
                        " " +
                        bdata.office_pincode}
                    </p>
                  </div>
                  <div className="col-md-6">
                    <h5 className="p-0 m-0 border-bottom p-1">
                      Registered Address
                    </h5>
                    <p className="mt-2" style={{ textTransform: "capitalize" }}>
                      Address :
                      {bdata.registered_address +
                        " " +
                        bdata.registered_city +
                        " " +
                        bdata.registered_district +
                        " " +
                        bdata.registered_state +
                        " " +
                        bdata.registered_pincode}
                    </p>
                  </div>
                </div>
              </div>
            </>
          )}
          <div className="rounded mt-2 bg-white shadow">
            <div className="row m-0">
              <div className="col-md-6 p-1">
                <form onSubmit={generatecontactrequest}>
                  <div
                    className="p-0 m-0  p-2 border rounded"
                  >
                    <h5 className="p-0 m-0 border-bottom p-1">
                      Send a Contact Request
                    </h5>
                    <div className="mt-1">
                      <label>Full Name</label>
                      <input
                        className="form-control shadow"
                        type="search"
                        value={fullname}
                        onChange={(e) => setfullname(e.target.value)}
                      />
                    </div>
                    <div className="mt-1">
                      <label>Contact Number</label>
                      <input
                        className="form-control shadow"
                        type="number"
                        min="0"
                        value={bcontactnumber}
                        onChange={(e) => setbcontactnumber(e.target.value)}
                      />
                    </div>
                    <div className="mt-1">
                      <label>Email</label>
                      <input
                        className="form-control shadow"
                        type="search"
                        value={bemail}
                        onChange={(e) => setbemail(e.target.value)}
                      />
                    </div>
                    <div className="mt-1">
                      <label>Pupose to contact</label>
                      <textarea
                        className="form-control shadow"
                        type="search"
                        rows={5}
                        value={bpurpose}
                        onChange={(e) => setbpurpose(e.target.value)}
                      />
                    </div>
                    <div className="mt-1">
                      <button className="btn btn-primary shadow" type="submit">
                        Submit Request
                      </button>
                    </div>
                  </div>
                </form>
              </div>
              <div className="col-md-6 p-1">
                <div
                  className="p-0 m-0 p-1 rounded "
                  // style={{ border: "2px dashed #ffcc00" }}
                >
                  <h5 className="p-0 m-0 p-1">Find on Map</h5>
                  <div style={{ width: "100%" }}>
                    {// google map will not work when lat or long is blank , check for value before using map
                    latitude !== "" &&
                      longtitude !== "" &&
                      latitude !== null &&
                      longtitude !== null && (
                        <GoogleMap
                          lat={latitude}
                          long={longtitude}
                          zoom={15}
                          mapwidth={"100%"}
                          mapheight={"400px"}
                        />
                      )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
