import React from "react";

export default function Vision() {
  return (
    <>
      <div className="container pt-5 pb-5">
        <div className="row m-0">
          <div className="col-md-12 text-center">
            <h2 className="">
              Integrated solutions designed for small business
            </h2>
            <p className="text-secondary">
              Communicate with flexible tools that go where your team goes.
            </p>
          </div>
          <div className="col-md-4 mt-4">
            <div className="text-center">
              <img
                src={require("./../../../assets/img/service1.png")}
                className="w-50"
                alt="pic"
              />
              <h3 className="mt-3">Easy</h3>
              <p className="p-1 text-secondary">
                Managing business is as easy as filling up simple forms.
              </p>
            </div>
          </div>
          <div className="col-md-4 mt-4">
            <div className="text-center">
              <img
                src={require("./../../../assets/img/service2.png")}
                className="w-50"
                alt="pic"
              />
              <h3 className="mt-3">Efficient</h3>
              <p className="p-1 text-secondary">
                Speed up your business with realtime data powerful tools.
              </p>
            </div>
          </div>
          <div className="col-md-4 mt-4">
            <div className="text-center">
              <img
                src={require("./../../../assets/img/service3.png")}
                className="w-50"
                alt="pic"
              />
              <h3 className="mt-3">Expandable</h3>
              <p className="p-1 text-secondary">
                Start learning with basics and expand as per your need.
              </p>
            </div>
          </div>
        </div>
      </div>
      {/* <div className="container-fluid bg-white shadow">
        <div className="row">
          <div className="col-md-6">
            <div className="">
              <div className="d-flex">
                <div className="dropcaps-circle ">
                  <i className="bi bi-graph-up-arrow fs-1 text-primary bg-light rounded-pill border-2 border p-3"></i>
                </div>
                <h4 className="margin-bottom-5">Customize your workflow.</h4>
              </div>

              <p>
                Manage any process and be ready to address any challenge with
                total ease.
              </p>
            </div>
            <div className="features-second">
              <div className="dropcaps-circle">
                <i className="fas fa-network-wired"></i>
              </div>

              <h4 className="margin-bottom-5">
                Easy onboarding, fast adoption.
              </h4>

              <p>
                With Essentials getting your team on board is as simple as
                sending an email.
              </p>
            </div>
            <div className="features-second">
              <div className="dropcaps-circle">
                <i className="fas fa-rocket"></i>
              </div>

              <h4 className="margin-bottom-5">Improve subscriber retention.</h4>

              <p>
                Analyze customers by region, discounts and more and put a plan
                in place to improve subscriber retention.
              </p>
            </div>
          </div>
          <div
            className="col-md-6 wow slideInRight"
            data-wow-delay="0.25s"
            style={{
              visibility: "visible",
              animationDelay: "0.25s",
              animationName: "slideInRight",
            }}
          >
            <img
              src="images/img10.png"
              className="width-100 box-shadow"
              alt="pic"
            />
          </div>
        </div>
      </div> */}
      <div className="row bg-white m-0">
        <div className="col-md-6">
          <img
            src={require("./../../../assets/img/about_soft.png")}
            className="w-100"
            alt="about"
          />
        </div>
        <div className="col-md-6 p-5">
          <h1>The Solution that’s right way for you!</h1>
          <p>
            Ever wondered how some large companied are managing their business without involving business owner into everything. By using Technology.
          </p>
          <p>
            This is what we are working on. To bring similar technology for small scale businesses to let them grow and manage....
          </p>
          <div className="row">
            <ul
              style={{ listStyle: "none" }}
              className="col-xl-6 col-md-6 col-sm-6"
            >
              <li>
                <i className="bi bi-check-circle text-primary"></i> 
                {' '} Products & Inventory
              </li>
              <li>
                <i className="bi bi-check-circle text-primary"></i> 
                {' '} Billing & Accountancy
              </li>
              <li>
                <i className="bi bi-check-circle text-primary"></i> 
                {' '} Human resource
              </li>
              <li>
                <i className="bi bi-check-circle text-primary"></i>
                {' '} Documents
              </li>
            </ul>
            <ul
              style={{ listStyle: "none" }}
              className="col-xl-6 col-md-6 col-sm-6"
            >
              <li>
                <i className="bi bi-check-circle text-primary"></i>
                {' '} Point of Sale
              </li>
              <li>
                <i className="bi bi-check-circle text-primary"></i> 
                {' '} Calling & Leads
              </li>
              <li>
                <i className="bi bi-check-circle text-primary"></i> 
                {' '} And so on...
              </li>
              
            </ul>
          </div>
        </div>
      </div>
    </>
  );
}
