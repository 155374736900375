import React from "react";
import "./Marker.css";

function Marker(props) {
  const { color, name} = props;
  return (
    <div>
      <div
        className="pin bounce"
        style={{
          backgroundColor: color,
          cursor: "pointer",
          border: "1px solid black",
        }}
        title={name}
      />
      <div className="pulse" />
    </div>
  );
}
export default React.memo(Marker)