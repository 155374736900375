import React from "react";
import { Link } from "react-router-dom";

export default function NavlinksReseller() {
  return (
    <div className="navlinks">
      <ul>
      <li>
          <i className="bi bi-person-bounding-box"></i> Account
          <ul className="child-nav">
            <li>
              <Link to={"/ez/" + localStorage.getItem('ez-upath')}>
                <i className="bi bi-emoji-heart-eyes"></i> View Site
              </Link>
            </li>
            <li>
              <Link to="/dashboard">
                <i className="bi bi-speedometer"></i> Dashboard
              </Link>
            </li>
            <li>
              <Link to="/logout">
                <i className="bi bi-door-open"></i> Logout
              </Link>
            </li>
            <li>
              <Link to="/reset-password">
                <i className="bi bi-shield-lock"></i> Password Reset
              </Link>
            </li>
          </ul>
        </li>
        <li>
          <Link to={"/search-business"}>
            <i className="bi bi-search"></i> Search business
          </Link>
        </li>
        <li>
          <i className="bi bi-briefcase"></i> Biz. Profile
          <ul className="child-nav">
            <li>
              <Link to="/create-store-front">
                <i className="bi bi-shop"></i> Create Store Front
              </Link>
            </li>
            <li>
              <Link to="/update-business-contacts">
                <i className="bi bi-person-rolodex"></i> Contact Details
              </Link>
            </li>
            <li>
              <Link to="/update-social-media-links">
                <i className="bi bi-twitch"></i> Social Media
              </Link>
            </li>
            <li>
              <Link to="/update-payment-link">
                <i className="bi bi-credit-card"></i> Payment Links
              </Link>
            </li>
            <li>
              <Link to="/update-about-us">
                <i className="bi bi-file-person"></i> Add/Edit Intro.
              </Link>
            </li>
            <li>
              <Link to="/create-blog-post">
                <i className="bi bi-pencil-square"></i> Add Blog Post
              </Link>
            </li>
            <li>
              <Link to="/manage-blogs">
                <i className="bi bi-list-check"></i> Manage Blog Post
              </Link>
            </li>
            <li>
              <Link to="/create-gallery-item">
                <i className="bi bi-images"></i> Add Gallery Item
              </Link>
            </li>
            <li>
              <Link to="/view-gallery-items">
                <i className="bi bi-list-check"></i> Manage Gallery Items
              </Link>
            </li>
          </ul>
        </li>
        <li>
          <i className="bi bi-bag-heart"></i> Inventory
          <ul className="child-nav">
            <li>
              <Link to="/add-product">
                <i className="bi bi-node-plus"></i> Add Product
              </Link>
            </li>
            <li>
              <Link to="/view-product-listing">
                <i className="bi bi-binoculars"></i> view Listings
              </Link>
            </li>
            {/* <li>
              <Link to="/manage-orders">
                <i className="bi bi-basket"></i> Manage Orders
              </Link>
            </li> */}
            <li>
              <Link to="/point-of-sale">
                <i className="bi bi-shop"></i> Point Of Sale
              </Link>
            </li>
            <li>
              <Link to="/manage-orders">
                <i className="bi bi-list-check"></i> Sale Summary
              </Link>
            </li>
          </ul>
        </li>
        <li>
          <i className="bi bi-briefcase"></i> Manage Business
          <ul className="child-nav">
            <li>
              <Link to={"/user-registration/"+localStorage.getItem('ez-userid')}>
                <i className="bi bi-shop"></i> Create New User
              </Link>
            </li>
            <li>
              <Link to="view-user-data-reg-by-reseller">
                <i className="bi bi-person-rolodex"></i> View Registrations
              </Link>
            </li>
            
          </ul>
        </li>
        <li>
          <i className="bi bi-award"></i> Team
          <ul className="child-nav">
            <li>
              <Link to="/add-business-team-member">
                <i className="bi bi-person-plus"></i> Add Member
              </Link>
            </li>
            <li>
              <Link to="/manage-team">
                <i className="bi bi-microsoft-teams"></i> View Members
              </Link>
            </li>
            {/* <li>
              <Link to="/add-product">
                <i className="bi bi-hand-thumbs-up"></i> Post Vacancy
              </Link>
            </li>
            <li>
              <Link to="/view-product-listing">
                <i className="bi bi-plugin"></i> Manage Ads
              </Link>
            </li>
            <li>
              <Link to="/manage-orders">
                <i className="bi bi-file-earmark-bar-graph"></i> Job Applications
              </Link>
            </li>
            <li>
              <Link to="/manage-orders">
                <i className="bi bi-person-plus"></i> Add Member
              </Link>
            </li>
            <li>
              <Link to="/manage-orders">
                <i className="bi bi-people-fill"></i> Manage Team
              </Link>
            </li> */}
          </ul>
        </li>
        <li>
          <i className="bi bi-brightness-high"></i> Leads
          <ul className="child-nav">
            <li>
              <Link to={'view-contact-request'}>
                <i className="bi bi-hand-thumbs-up"></i> Contact Requests
              </Link>
            </li>
          </ul>
        </li>
      </ul>
    </div>
  );
}
