import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { API_PATH, IMAGE_PATH } from "../../Config";
import axios from "axios";
import parse from "html-react-parser";
import GoToHome from "./GoToHome";
import Spinner from "../../layout/common/Spinner";

export default function StoreFrontAboutUs() {
  const [spinner, setspinner] = useState(false);
  const params = useParams();
  const upath = params.id;
  const [bdata, setbdata] = useState([]);
  
  useEffect(() => {
    window.scrollTo(0, 0);
    setspinner(true);
    axios
      .post(API_PATH + "ManageStoreFront.php", {
        action: "getBusinessIntroData",
        upath: upath,
      })
      .then((r) => {
        if (r.data.status === "success") {
          setbdata(r.data.retval);
        } else {
          // alert("Something went wrong. We didn't find the data.");
        }
        setspinner(false);
      });
  }, [upath]);

  return (
    <>
      <div className="container mt-5 pt-4 mh-90">
        {spinner === true && <Spinner />}
        <div className="row">
          <GoToHome link={"/ez/" + upath} />
          <div
            className="col-12 text-center mb-4 mt-3"
            style={{ textTransform: "uppercase" }}
          >
            <h4 className="text-secondary">About {bdata.b_name}</h4>
          </div>
          <div
            className="col-12 text-left mb-4"
            style={{ textTransform: "uppercase" }}
          >
            <h6 className="text-secondary">
              <span className="text-white bg-primary rounded ps-1 pe-1">Title</span>{" "}
              : {bdata.title}
            </h6>
            <h6 className="text-secondary mt-3">
              <span className="text-white bg-primary rounded  ps-1 pe-1">
                Tagline
              </span>{" "}
              : {bdata.subject}
            </h6>
          </div>
          <div className="col-md-4 pt-3">
            <img
              className="bg-white rounded shadow"
              src={IMAGE_PATH + "business-intro/" + bdata.image}
              alt=""
              style={{ width: "100%", borderRadius: "12px" }}
            />
          </div>
          <div className="col-md-8 pt-3">
            {bdata.content && parse(bdata.content)}
          </div>
        </div>
      </div>
    </>
  );
}
