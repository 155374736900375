import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { API_PATH, APP_TOKEN } from "../../Config";
import Spinner from "../../layout/common/Spinner";

export default function Register() {
  const param = useParams();
  const sponsorid = param.id;
  const navigate = useNavigate();
  const [email, setemail] = useState("");
  const [actype, setactype] = useState("business");
  const [toggleBusinessUrlInput, setToggleBusinessUrlInput] = useState("show");
  const [pageurl, setpageurl] = useState("");
  const [contact, setcontact] = useState("");
  const [password, setpassword] = useState("");
  const [verifypassword, setverifypassword] = useState("");
  const [sponsorUser, setSponsorUser] = useState("");
  const [statusClass, setStatusClass] = useState("d-none");
  const [statusMessage, setStatusMessage] = useState("");
  const [urlStatusMsg, setUrlStatusMsg] = useState("");

  const [spinner, setspinner] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // create friendly url business name
  const ToSeoUrl = (e) => {
    // make the url lowercase
    var encodedUrl = e.target.value.toString().toLowerCase();

    // replace & with and
    encodedUrl = encodedUrl.split(/\&+/).join("-and-"); //eslint-disable-line

    // remove invalid characters
    encodedUrl = encodedUrl.split(/[^a-z0-9]/).join("-");

    // remove duplicates
    encodedUrl = encodedUrl.split(/-+/).join("-");

    // trim leading & trailing characters
    encodedUrl = encodedUrl.trim("-");
    // const checkendchar = encodedUrl.charAt(encodedUrl.length-1);
    // if(checkendchar==='-'){
    //     encodedUrl = encodedUrl.slice(0, -1)
    // }
    setpageurl(encodedUrl);

    if (encodedUrl.toString().length > 0) {
      axios
        .post(API_PATH + "Auth.php", {
          apptoken: APP_TOKEN,
          action: "verifyBusinessUrl",
          url: encodedUrl,
        })
        .then((r) => {
          if (r.data.status === "failed") {
            setUrlStatusMsg(
              "Sorry, This url has been taken by some one. Please try with other."
            );
          } else {
            setUrlStatusMsg("");
          }
        });
    } else {
      setUrlStatusMsg("");
    }

    return;
  };

  //toggle business url input on user type change
  const toggleactype = (e) => {
    setactype(e.target.value);
    if (e.target.value === "business") {
      setToggleBusinessUrlInput("show");
    } else {
      setToggleBusinessUrlInput("hide");
    }
  };

  // register user to database
  const RegisterUser = () => {
    if (password !== verifypassword) {
      setStatusClass("d-block alert-danger");
      setStatusMessage(
        "Enetered password did not match at verification, Please try again."
      );
    } else if (
      email === "" ||
      contact === "" ||
      password === "" ||
      verifypassword === "" ||
      actype === ""
    ) {
      setStatusClass("d-block alert-danger");
      setStatusMessage("All fields are mandatory.");
    } else {
      setspinner(true);
      axios
        .post(API_PATH + "Auth.php", {
          apptoken: APP_TOKEN,
          actype: actype,
          pageurl: pageurl,
          email: email,
          contact: contact,
          password: password,
          sponsor: sponsorUser,
          sponsorid: sponsorid > 0 ? sponsorid : 0,
          action: "userRegistration",
        })
        .then(function(r) {
          if (r.data.status === "success") {
            setStatusClass("alert-success");
            setStatusMessage(
              "Registration Successfull. Please login to proceed."
            );

            setemail("");
            setcontact("");
            setpassword("");
            setverifypassword("");
            setSponsorUser("");

            setTimeout(() => {
              navigate("/login");
            }, 2000);
          } else {
            switch (r.data.error) {
              case "invalidsponsor":
                setStatusClass("alert-danger");
                setStatusMessage(
                  "No Sponsor detail matched in system. Please try again."
                );
                break;

              case "userexist":
                setStatusClass("alert-danger");
                setStatusMessage(
                  "Contact number or Email id already attached to some existing user."
                );
                break;

              default:
                setStatusClass("alert-danger");
                setStatusMessage("Something went wrong, Please contact Admin");
                break;
            }
          }
          setspinner(false);
        });
    }
    window.scrollTo(0, 0);
  };

  return (
    <div className="container mt-5 pt-5 mb-5 bg-white rounded shadow">
      {spinner === true && <Spinner />}
      <div className="border-bottom p-1">
        <h1>User Registration</h1>
        <small className="pb-1">
          Please fill up the form and submit to register.
        </small>
      </div>
      <div className="row m-0 mt-1">
        <div className="col-md-6 border-end">
          <div className={`alert ${statusClass} `} role="alert">
            {statusMessage}
          </div>

          <div className="p-1 mt-1">
            <label>Account Type</label>
            <select
              className="form-control"
              onChange={toggleactype}
              defaultValue={"business"}
            >
              {/* <option value="" key="1">
                Select from List
              </option> */}
              <option value="business" key="2">
                Business Account
              </option>
              {/* <option value="public" key="3">
                Public Account
              </option> */}
            </select>
          </div>
          {toggleBusinessUrlInput !== "hide" && (
            <div className="p-1">
              <label>Create Business Page Url</label>
              <input
                type="text"
                className="form-control bg-light mt-1"
                value={pageurl}
                onChange={ToSeoUrl}
              />
              {urlStatusMsg !== "" && (
                <small className="bg-danger text-white rounded pe-2 ps-2">
                  {urlStatusMsg}
                </small>
              )}
            </div>
          )}

          <div className="p-1">
            <label>Please enter Email Address</label>
            <input
              type="email"
              className="form-control bg-light mt-1"
              value={email}
              onChange={(e) => {
                setemail(e.target.value);
              }}
            />
          </div>

          <div className="p-1 mt-1">
            <label>Please enter Contact Number</label>
            <input
              type="number"
              min="0"
              className="form-control bg-light mt-1"
              value={contact}
              onChange={(e) => {
                setcontact(e.target.value);
              }}
            />
          </div>

          <div className="p-1 mt-1">
            <label>Please enter Password</label>
            <input
              type="password"
              className="form-control bg-light mt-1"
              value={password}
              onChange={(e) => {
                setpassword(e.target.value);
              }}
            />
          </div>

          <div className="p-1 mt-1">
            <label>Enter Password again to verify</label>
            <input
              type="password"
              className="form-control bg-light mt-1"
              value={verifypassword}
              onChange={(e) => {
                setverifypassword(e.target.value);
              }}
            />
          </div>
          {!sponsorid && (
            <div className="p-1 mt-1">
              <label>Referrer's Mobile Number</label> <br />
              <small> Registered Mobile number of referre user. </small>
              <input
                type="number"
                min="0"
                className="form-control bg-light mt-1"
                value={sponsorUser}
                onChange={(e) => {
                  setSponsorUser(e.target.value);
                }}
              />
            </div>
          )}

          <div className="p-1">
            <button className="btn btn-primary  rounded-pill" onClick={RegisterUser}>
              Register
            </button>
            {!param.id && (
              <button
                className="btn btn-primary  rounded-pill ms-2"
                onClick={() => navigate("/login")}
              >
                Login
              </button>
            )}
          </div>
        </div>
        <div className="col-md-6">
          <div style={{fontSize:14}} className="text-justify mt-3">
            Welcome to Biziness.in businesses registration page! We are excited
            to have you onboard and provide you with our innovative solution to
            help you streamline your business operations. <br /><br />
            Our platform is designed to help you manage your business more
            efficiently and effectively, with features that include customer
            relationship management, project management, and financial
            management tools. You'll have access to real-time analytics and
            insights, so you can make informed decisions based on accurate data.{" "}
            <br /><br />
            To register, simply fill in the required fields with your personal
            and business information. Once you have submitted your registration,
            you will receive a confirmation email with your login credentials.
            From there, you can start exploring our platform and customizing it
            to fit your business needs.
            <br /><br />
            We pride ourselves on providing exceptional customer support, so if
            you have any questions or need assistance, our team is here to help
            you. We're committed to helping you succeed and grow your business,
            and we look forward to being a part of your journey.
            <br /><br />
            Thank you for choosing our SaaS platform for businesses. Let's get
            started!
            <br />
          </div>
        </div>
      </div>
    </div>
  );
}
