import React from "react";
import { Helmet } from "react-helmet-async";

export default function Seo(prop) {
  return (
    <Helmet>
      <title>{prop.title}</title>
      <meta name="keywords" content={prop.keywords} />
      <meta name="description" content={prop.description} />
      {prop.canonical !== "" && <link rel="canonical" href={prop.canonical} />}
      <meta property="og:title" content={prop.title} />
      <meta property="og:url" content={prop.ogurl} />
      <meta property="og:image" content={prop.ogimage} />
      <meta property="og:type" content={prop.ogtype} />
      <meta property="og:description" content={prop.description} />
      <meta property="og:locale" content="en_IN" />
      <meta property="og:site_name" content="www.biziness.in" />
    </Helmet>
  );
}
