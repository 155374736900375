import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { API_PATH, PROFILE } from "../../Config";
import Spinner from "../../layout/common/Spinner";

export default function DashboardAbm() {
  const [spinner, setspinner] = useState(false);
  const [data, setdata] = useState([]);
  useEffect(() => {
    window.scrollTo(0, 0);
    setspinner(true);
    axios
      .post(API_PATH + "ManageDashboard.php", {
        userid: localStorage.getItem("ez-userid"),
        userrole: localStorage.getItem("ez-userrole"),
        usertoken: localStorage.getItem("ez-usertoken"),
        action: "getdashboardstats",
      })
      .then((r) => {
        console.log(r.data);
        if (r.data.status === "success") {
          setdata(r.data.retval);
        }
        setspinner(false);
      });
  }, []);
  return (
    <div className="mt-5 pt-4 pb-5 mh-90">
      {spinner === true && <Spinner />}
      <div className="mt-2 container-fluid">
        <div className="text-center mt-2 mb-5 ">
          <h4 style={{ textTransform: "capitalize" }}>
            Welcome {" "}
            {PROFILE[localStorage.getItem("ez-userrole")].display}
          </h4>
        </div>
        <div className="text-center">
          <div className="row m-0">
            <div className="col-md-6">
              <input
                className="form-control"
                value={
                  "https://biziness.in/user-registration/" +
                  localStorage.getItem("ez-userid")
                }
                readOnly
              />
            </div>
            <div className="col-md-2">
              <button
                className="btn  btn-primary form-control"
                style={{ fontWeight: "bold" }}
                onClick={(e) => {
                  navigator.clipboard.writeText(
                    "https://biziness.in/user-registration/" +
                      localStorage.getItem("ez-userid")
                  );
                  e.target.classList.remove("btn-primary");
                  e.target.classList.add("btn-success");
                  e.target.classList.add("text-white");
                }}
              >
                Copy Invitation Link
              </button>
            </div>
            <div className="col-md-2">
              <Link
                to={"/user-registration/" + localStorage.getItem("ez-userid")}
              >
                <button className="btn  btn-primary form-control">
                  <strong>Add Business</strong>
                </button>
              </Link>
            </div>
          </div>
        </div>
        <div className="row mt-4">
          {/* <div className="col-6 col-md-3 col-lg-2 p-1">
            <Link to={"/manage-user"}>
              <div className="p-2 border shadow rounded">
                <p className="text-center text-bold text-secondary">
                  Registered Users
                </p>
                <p className="m-0 text-center text-bold text-secondary">
                  {totaluser > 0 ? totaluser : 0}
                </p>
              </div>
            </Link>
          </div> */}
          <div className="col-6 col-md-3 col-lg-2 p-1">
            <Link to={"/manage-user/level3"}>
              <div className="p-2 border shadow rounded">
                <p className="text-center text-bold text-secondary">Total SM</p>
                <p className="m-0 text-center text-bold text-secondary">
                  {data.level3 > 0 ? data.level3 : 0}
                </p>
              </div>
            </Link>
          </div>
          <div className="col-6 col-md-3 col-lg-2 p-1">
            <Link to={"/manage-user/level4"}>
              <div className="p-2 border shadow rounded">
                <p className="text-center text-bold text-secondary">
                  Total Advisor
                </p>
                <p className="m-0 text-center text-bold text-secondary">
                  {data.level4 > 0 ? data.level4 : 0}
                </p>
              </div>
            </Link>
          </div>
          <div className="col-6 col-md-3 col-lg-2 p-1">
            <Link to={"/manage-user/level4"}>
              <div className="p-2 border shadow rounded">
                <p className="text-center text-bold text-secondary">
                  Total Business
                </p>
                <p className="m-0 text-center text-bold text-secondary">
                  {data.business > 0 ? data.business : 0}
                </p>
              </div>
            </Link>
          </div>
          <div className="col-6 col-md-3 col-lg-2 p-1">
            <Link to={"#"}>
              <div className="p-2 border shadow rounded">
                <p className="text-center text-bold text-secondary">
                  Total Revenue
                </p>
                <p className="m-0 text-center text-bold text-secondary">
                  0
                </p>
              </div>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}
