import axios from "axios";
import React, { useEffect, useState } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import { API_PATH } from "../../Config";
import "./store.css";
import useLoginAuth from "../../hooks/useLoginAuth";
import useValidateRegType from "../../hooks/useValidateRegType";
import PayForExpired from "../../layout/common/PayForExpired";


// important for tiny mce editor
import { useRef } from "react";
import { Editor } from "@tinymce/tinymce-react";
import Spinner from "../../layout/common/Spinner";

export default function CreateBlogPost() {
  const [spinner, setspinner] = useState(false)
  const editorRef = useRef(null);

  const validatelogin = useLoginAuth();
  const validateSubscription = useValidateRegType();
  const [subject, setsubject] = useState("");
  const [imagefile, setimagefile] = useState([]);

  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const updateIntro = () => {
    const fd = new FormData();

    // get data from tiny mce declaration before this function will return null
    const description = editorRef.current.getContent();

    
    fd.append("action", "addNewBlogPost");
    fd.append("userid", localStorage.getItem("ez-userid"));
    fd.append("usertoken", localStorage.getItem("ez-usertoken"));

    fd.append("imagefile", imagefile);
    fd.append("subject", subject);
    fd.append("description", description);

    // check params are filled or not
    setspinner(true)
    axios
      .post(API_PATH + "ManageStoreFront.php", fd, {
        headers: {
          "content-type": "multipart/form-data",
        },
      })
      .then((res) => {
        if (res.data.status === "success") {
          setsubject("");
          setimagefile([]);
          alert("Data Updated successfully!");
        } else {
          alert("Something went wrong, please try again later!");
        }
        setspinner(false)
      });

    window.scrollTo(0, 0);
  };
  if (validatelogin !== true) {
    return <Navigate to={"/login"} />;
  } else {
    if (validateSubscription.status === false) {
      return <PayForExpired />;
    } else {
      return (
        <div className="pt-5 mh-90 pb-5">
          {spinner === true && <Spinner/>}
          <div className="container mt-5">
            <h2 className="text-secondary">Add a New Blog/Post</h2>
            <small>
              This page enables you to add new Post/Blog to your business
              profile on Biziness.in. Creating a blog is very easy. You just have
              to fill up the simple form and submit it.
            </small>
          </div>
          <div className="container mt-4">
            <div className="row m-0">
              <div className="col-md-9 pt-2">
                <label>Subject/Title</label>
                <input
                  type="text"
                  className="form-control"
                  onChange={(e) => setsubject(e.target.value)}
                />
              </div>
              <div className="col-md-3 pt-2">
                <label>Add an image</label>
                <input
                  type="file"
                  className="form-control"
                  onChange={(e) => setimagefile(e.target.files[0])}
                />
              </div>
              <div className="col-md-12 pt-2">
                <label>Detailed Description</label>

                <Editor
                  apiKey="51bzughom7qhj3gygo8ysr5oyk781cqqjd0pqmjs12x1h3gw"
                  onInit={(evt, editor) => (editorRef.current = editor)}
                  initialValue=""
                  init={{
                    selector: '#tinymce',
                    branding: false,
                    height: 500,
                    menubar: false,
                    plugins: [
                      // "a11ychecker",
                      "advlist",
                      // "advcode",
                      // "advtable",
                      "autolink",
                      // "checklist",
                      // "export",
                      "lists",
                      "link",
                      "image",
                      "charmap",
                      "preview",
                      "anchor",
                      "searchreplace",
                      "visualblocks",
                      // "powerpaste" ,
                      "fullscreen",
                      // "formatpainter", 
                      "insertdatetime",
                      "media",
                      "table",
                      "help",
                      "wordcount",
                    ],
                    toolbar:
                      "undo redo | casechange blocks | bold italic backcolor | " +
                      "alignleft aligncenter alignright alignjustify | " +
                      "bullist numlist checklist outdent indent | removeformat | a11ycheck code table help",
                    content_style:
                      "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
                  }}
                />
              </div>
            </div>
            <div className="row mt-3">
              <div className="d-none d-lg-block col-lg-3"></div>
              <div className="col-lg-3 text-center p-2">
                <button className="btn btn-primary" onClick={updateIntro}>
                  Add Post
                </button>
              </div>
              <div className="col-lg-3 text-center p-2">
                <button
                  className="btn btn-primary"
                  onClick={() => navigate("/manage-blogs")}
                >
                  Manage Posts
                </button>
              </div>
              <div className="d-none d-lg-block col-lg-3 p-2"></div>
            </div>
          </div>
        </div>
      );
    }
  }
}
