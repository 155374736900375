import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import TawkTo from "tawkto-react";

export default function TawToChat() {
  const location = useLocation();

  useEffect(() => {
    let propertyId = "628278507b967b11798f9c71";
    let tawkId = "1g36qbvc9";
    var tawk = new TawkTo(propertyId, tawkId);

    tawk.onStatusChange((status) => {
    });
  }, [location.pathname]);
  return null;
}
