import React, { useEffect } from "react";
import "../../layout/common/common.css";
import PartnershipIntro from "./sections/PartnershipIntro";

export default function PartnerWithUs() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="mh-90 pb-5 pt-5">
      <PartnershipIntro />
    </div>
  );
}
