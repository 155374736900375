import React, { useEffect, useState } from "react";
import NavlinksPublic from "./NavlinksPublic";
import NavlinksAdmin from "./NavlinksAdmin";
import { useLocation } from "react-router-dom";
import NavlinksBusiness from "./NavlinksBusiness";
import NavlinksReseller from "./NavlinksReseller";
import NavlinksBranch from "./NavlinksBranch";
import NavlinksAbm from "./NavlinksAbm";
import NavlinksSm from "./NavlinksSm";
import NavlinksAdvisor from "./NavlinksAdvisor";

export default function Switcher() {
  const location = useLocation();
  const [UserRole, SetUserRole] = useState(localStorage.getItem('ez-userrole'));
  useEffect(() => {
    SetUserRole(localStorage.getItem('ez-userrole'));
  }, [location.pathname]);

  switch (UserRole) {
    case "admin":
      return <NavlinksAdmin />
      
    case "reseller":
      return  <NavlinksReseller />
    
    case "business":
      return <NavlinksBusiness />
      
    case "level1":
      return <NavlinksBranch />
      
    case "level2":
      return <NavlinksAbm />
      
    case "level3":
      return <NavlinksSm />
      
    case "level4":
      return <NavlinksAdvisor />
      
    default:
      return <NavlinksPublic />

  }

}
