import React from "react";

export default function JobPost() {
  return (
    <div className="row m-0">
      <div className="col-lg-3"></div>
      {/* <div className="col-lg-6">
        <div className="mt-4 p-2 shadow border-base rounded ">
          <div className="">Assistant Manager</div>
          <div className="" style={{ fontSize: 12 }}>
            Company Name xyz
          </div>
          <div className="row" style={{ fontSize: 12 }}>
            <div className="col">1-3 years</div>
            <div className="col">10k-15k</div>
            <div className="col">Varanasi Uttar Pradesh</div>
          </div>
          <div className="" style={{ fontSize: 12 }}>
            managing hospital work and so on demo text
          </div>
          <div className="">
            <button className="mt-2 btn bg-base" style={{ fontSize: 12 }}>
              View Company
            </button>
            <button className="mt-2 btn bg-base ms-2" style={{ fontSize: 12 }}>
              Easy Apply
            </button>
          </div>
        </div>
        <div className="mt-4 p-2 shadow border-base rounded ">
          <div className="">Assistant Manager</div>
          <div className="" style={{ fontSize: 12 }}>
            Company Name xyz
          </div>
          <div className="row" style={{ fontSize: 12 }}>
            <div className="col">1-3 years</div>
            <div className="col">10k-15k</div>
            <div className="col">Varanasi Uttar Pradesh</div>
          </div>
          <div className="" style={{ fontSize: 12 }}>
            managing hospital work and so on demo text
          </div>
          <div className="">
            <button className="mt-2 btn bg-base" style={{ fontSize: 12 }}>
              View Company
            </button>
            <button className="mt-2 btn bg-base ms-2" style={{ fontSize: 12 }}>
              Easy Apply
            </button>
          </div>
        </div>
        <div className="mt-4 p-2 shadow border-base rounded ">
          <div className="">Assistant Manager</div>
          <div className="" style={{ fontSize: 12 }}>
            Company Name xyz
          </div>
          <div className="row" style={{ fontSize: 12 }}>
            <div className="col">1-3 years</div>
            <div className="col">10k-15k</div>
            <div className="col">Varanasi Uttar Pradesh</div>
          </div>
          <div className="" style={{ fontSize: 12 }}>
            managing hospital work and so on demo text
          </div>
          <div className="">
            <button className="mt-2 btn bg-base" style={{ fontSize: 12 }}>
              View Company
            </button>
            <button className="mt-2 btn bg-base ms-2" style={{ fontSize: 12 }}>
              Easy Apply
            </button>
          </div>
        </div>
        <div className="mt-4 p-2 shadow border-base rounded ">
          <div className="">Assistant Manager</div>
          <div className="" style={{ fontSize: 12 }}>
            Company Name xyz
          </div>
          <div className="row" style={{ fontSize: 12 }}>
            <div className="col">1-3 years</div>
            <div className="col">10k-15k</div>
            <div className="col">Varanasi Uttar Pradesh</div>
          </div>
          <div className="" style={{ fontSize: 12 }}>
            managing hospital work and so on demo text
          </div>
          <div className="">
            <button className="mt-2 btn bg-base" style={{ fontSize: 12 }}>
              View Company
            </button>
            <button className="mt-2 btn bg-base ms-2" style={{ fontSize: 12 }}>
              Easy Apply
            </button>
          </div>
        </div>
        <div className="mt-4 p-2 shadow border-base rounded ">
          <div className="">Assistant Manager</div>
          <div className="" style={{ fontSize: 12 }}>
            Company Name xyz
          </div>
          <div className="row" style={{ fontSize: 12 }}>
            <div className="col">1-3 years</div>
            <div className="col">10k-15k</div>
            <div className="col">Varanasi Uttar Pradesh</div>
          </div>
          <div className="" style={{ fontSize: 12 }}>
            managing hospital work and so on demo text
          </div>
          <div className="">
            <button className="mt-2 btn bg-base" style={{ fontSize: 12 }}>
              View Company
            </button>
            <button className="mt-2 btn bg-base ms-2" style={{ fontSize: 12 }}>
              Easy Apply
            </button>
          </div>
        </div>
      </div> */}
      <div className="col-lg-6 text-center">
        <h1 className="text-secondary mt-5 pt-5">
          Coming Soon <br/> ....
        </h1>
      </div>
      <div className="col-lg-3"></div>
    </div>
  );
}
