import React, { useEffect } from "react";
import "../../layout/common/common.css";
import JobPost from "./JobPost";

export default function JobBoard() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="mh-90 pt-5 mb-5">
      <JobPost />
    </div>
  );
}
