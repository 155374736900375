import React from "react";
import { Link } from "react-router-dom";

export default function NavlinksSm() {
  
  return (
    <div className="navlinks">
      <ul>
        <li>
          <i className="bi bi-person-bounding-box"></i> Account
          <ul className="child-nav">
            <li>
              <Link to="/dashboard">
                <i className="bi bi-speedometer"></i> Dashboard
              </Link>
            </li>
            <li>
              <Link to="/reset-password">
                <i className="bi bi-shield-lock"></i> Password Reset
              </Link>
            </li>
            <li>
              <Link to="/logout">
                <i className="bi bi-door-open"></i> Logout
              </Link>
            </li>
          </ul>
        </li>
        <li>
          <i className="bi bi-cup-hot"></i> Team
          <ul className="child-nav">
            <li>
              <Link to="/add-user">
                <i className="bi bi-person-add"></i> Add User
              </Link>
            </li>
            {/* <li>
              <Link to="/manage-user/level4">
                <i className="bi bi-card-checklist"></i> View Advisor
              </Link>
            </li> */}
            {/* <li>
              <Link to="/manage-user/business">
                <i className="bi bi-card-checklist"></i> View Business
              </Link>
            </li> */}
            <li>
              <Link to="/view-user-data-reg-by-reseller">
                <i className="bi bi-card-checklist"></i> View Downline
              </Link>
            </li>
          </ul>
        </li>
      </ul>
    </div>
  );
}
