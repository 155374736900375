import axios from "axios";
import { useEffect, useState } from "react";
import { API_PATH } from "../Config";

export default function useValidateRegType() {
  const USER_ID = localStorage.getItem("ez-userid");
  const USER_TOKEN = localStorage.getItem("ez-usertoken");
  const USER_ROLE = localStorage.getItem("ez-userrole");
  const [subscriptionstatus, setsubscriptionstatus] = useState('pending')
  const [expirydate, setexpirydate] = useState()
  
  useEffect(() => {
    axios.post(API_PATH+'Auth.php',{
        userid : USER_ID,
        usertoken : USER_TOKEN,
        userrole : USER_ROLE,
        action:'validateuserregistrationtype'
    }).then((r) => {
      if(r.data.status==='success'){
        setsubscriptionstatus(true)
        setexpirydate(r.data.expirydate)
      }else{
        setsubscriptionstatus(false)
      }
      //console.log(r.data)
    });
  }, []);

  return {
    status : subscriptionstatus,
    date : expirydate
  };
}
