import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { API_PATH } from "../../Config";
import PayOnRegOrUpgrade from "../../layout/common/PayOnRegOrUpgrade";
import Spinner from "../../layout/common/Spinner";
import useValidateRegType from "../../hooks/useValidateRegType";

export default function DashboardBusiness() {
  const validateSubscription = useValidateRegType();

  const [spinner, setspinner] = useState(false);

  const [contactreqcount, setcontactreqcount] = useState();
  const [prodcount, setprodcount] = useState();
  const [outofstock, setoutofstock] = useState();
  useEffect(() => {
    window.scrollTo(0, 0);
    setspinner(true);
    axios
      .post(API_PATH + "ManageDashboard.php", {
        userid: localStorage.getItem("ez-userid"),
        userrole: localStorage.getItem("ez-userrole"),
        usertoken: localStorage.getItem("ez-usertoken"),
        action: "getdashboardstats",
      })
      .then((r) => {
        if (r.data.status === "success") {
          setcontactreqcount(r.data.contactrequestcount);
          setprodcount(r.data.productininventory);
          setoutofstock(r.data.productoutofstock);
        }
        setspinner(false);
      });
  }, []);
  return (
    <div className="mt-5 pt-4 pb-5 mh-90">
      {spinner === true && <Spinner />}
      <div className="mt-0 container-fluid">
        <div className="text-center">
          {validateSubscription.status === true ? (
            <div className="text-bold mb-2">
              <i className="bi bi-award fs-2 text-success"></i> Your account is{" "}
              <span className="text-success">ACTIVE</span>
            </div>
          ) : (
            <div className="text-bold text-danger mb-2">
              <i className="bi bi-exclamation-circle-fill fs-4 text-danger me-2"></i> Your account is{" "}
              <span className="text-dangersuccess">INACTIVE</span>
            </div>
          )}
          <Link to={"/create-store-front"}>
            <button className="btn btn-white shadow  rounded-pill" style={{}}>
              <strong>Create/Update Business Profile</strong>
            </button>
          </Link>
        </div>
        <div className="row mt-4">
          <div className="col-6 col-md-3 col-lg-2 p-1">
            <Link to={"/view-product-listing"}>
              <div className="bg-white p-2 border shadow rounded">
                <p className="text-center text-bold text-secondary">
                  Products Listed
                </p>
                <p className="m-0 text-center text-bold text-secondary">
                  {prodcount > 0 ? prodcount : 0}
                </p>
              </div>
            </Link>
          </div>
          <div className="col-6 col-md-3 col-lg-2 p-1">
            <div className="bg-white p-2 border shadow rounded">
              <p className="text-center text-bold text-secondary">
                Out of Stock
              </p>
              <p className="m-0 text-center text-bold text-secondary">
                {outofstock > 0 ? outofstock : 0}
              </p>
            </div>
          </div>
          <div className="col-6 col-md-3 col-lg-2 p-1">
            <div className="bg-white p-2 border shadow rounded">
              <p className="text-center text-bold text-secondary">New Orders</p>
              <p className="m-0 text-center text-bold text-secondary">0</p>
            </div>
          </div>
          <div className="col-6 col-md-3 col-lg-2 p-1">
            <div className="bg-white p-2 border shadow rounded">
              <p className="text-center text-bold text-secondary">Total Sale</p>
              <p className="m-0 text-center text-bold text-secondary">0</p>
            </div>
          </div>
          <div className="col-6 col-md-3 col-lg-2 p-1">
            <div className="bg-white p-2 border shadow rounded">
              <p className="text-center text-bold text-secondary">
                Pending Invoices
              </p>
              <p className="m-0 text-center text-bold text-secondary">0</p>
            </div>
          </div>
          <div className="col-6 col-md-3 col-lg-2 p-1">
            <Link to={"/view-contact-request"}>
              <div className="bg-white p-2 border shadow rounded">
                <p className="text-center text-bold text-secondary">
                  Contact Requests
                </p>
                <p className="m-0 text-center text-bold text-secondary">
                  {contactreqcount > 0 ? contactreqcount : 0}
                </p>
              </div>
            </Link>
          </div>
        </div>
      </div>
      <PayOnRegOrUpgrade/>
    </div>
  );
}
