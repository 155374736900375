import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link, Navigate, useNavigate } from "react-router-dom";
import { API_PATH } from "../../Config";

// important for tiny mce editor
import { useRef } from "react";
import { Editor } from "@tinymce/tinymce-react";


import "./store.css";
import useLoginAuth from "../../hooks/useLoginAuth";
import Spinner from "../../layout/common/Spinner";

export default function UpdateAboutUs() {
  const [spinner, setspinner] = useState(false)
  
  // Create reference for tiny mce editor
  const editorRef = useRef(null);

  const validatelogin = useLoginAuth();
  const [title, settitle] = useState("");
  const [subject, setsubject] = useState("");
  const [imagefile, setimagefile] = useState([]);
  const [profiledata, setprofiledata] = useState("");

  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
    setspinner(true)
    axios
      .post(API_PATH + "ManageStoreFront.php", {
        action: "getBusinessProfile",
        userid: localStorage.getItem("ez-userid"),
        usertoken: localStorage.getItem("ez-usertoken"),
      })
      .then((r) => {
        if (r.data.status === "success") {
          setprofiledata(r.data.retval);
        }
        setspinner(false)
      });
  }, []);

  const updateIntro = () => {
    const fd = new FormData();

    // get data from tiny mce declaration before this function will return null
    const description = editorRef.current.getContent();
    
    fd.append("action", "updateBusinessIntroduction");
    fd.append("userid", localStorage.getItem("ez-userid"));
    fd.append("usertoken", localStorage.getItem("ez-usertoken"));

    fd.append("imagefile", imagefile);
    fd.append("title", title);
    fd.append("subject", subject);
    fd.append("description", description);

    // check params are filled or not
    setspinner(true)
    axios
      .post(API_PATH + "ManageStoreFront.php", fd, {
        headers: {
          "content-type": "multipart/form-data",
        },
      })
      .then((res) => {
        if (res.data.status === "success") {
          alert("Data Updated successfully!");
        } else {
          alert("Something went wrong, please try again later!");
        }
        setspinner(false)
      });

    window.scrollTo(0, 0);
  };
  if (validatelogin !== true) {
    return <Navigate to={"/login"} />;
  } else {
    return (
      <div className="pt-5 mh-90 pb-5 bg-white">
        {spinner === true && <Spinner/>}
        <div className="container mt-4">
          <h2 className="text-secondary">Update Business Introduction</h2>
          <small>
            Fill up details reuired in form fields and create your own EasyStore
            where you can display all your business information at your
            customized business page. Click{" "}
            <Link
              to={"/intro/" + profiledata.unique_user_name}
              className="border rounded bg-warning text-dark p-1"
            >
              Here
            </Link>{" "}
            to view your page progress.
          </small>
        </div>
        <div className="container mt-4">
          <div className="row m-0">
            <div className="col-md-12 pt-2">
              <label>Introduction Title</label>
              <input
                type="text"
                className="form-control"
                onChange={(e) => settitle(e.target.value)}
              />
            </div>
            <div className="col-md-9 pt-2">
              <label>Subject Or Tagline</label>
              <input
                type="text"
                className="form-control"
                onChange={(e) => setsubject(e.target.value)}
              />
            </div>
            <div className="col-md-3 pt-2">
              <label>Add an image</label>
              <input
                type="file"
                className="form-control"
                onChange={(e) => setimagefile(e.target.files[0])}
              />
            </div>
            <div className="col-md-12 pt-2">
              <label>Detailed Description</label>
              <Editor
                  apiKey="51bzughom7qhj3gygo8ysr5oyk781cqqjd0pqmjs12x1h3gw"
                  onInit={(evt, editor) => (editorRef.current = editor)}
                  initialValue=""
                  init={{
                    selector: '#tinymce',
                    branding: false,
                    height: 500,
                    menubar: false,
                    plugins: [
                      // "a11ychecker",
                      "advlist",
                      // "advcode",
                      // "advtable",
                      "autolink",
                      // "checklist",
                      // "export",
                      "lists",
                      "link",
                      "image",
                      "charmap",
                      "preview",
                      "anchor",
                      "searchreplace",
                      "visualblocks",
                      // "powerpaste",
                      "fullscreen",
                      // "formatpainter",
                      "insertdatetime",
                      "media",
                      "table",
                      "help",
                      "wordcount",
                    ],
                    toolbar:
                      "undo redo | casechange blocks | bold italic backcolor | " +
                      "alignleft aligncenter alignright alignjustify | " +
                      "bullist numlist checklist outdent indent | removeformat | a11ycheck code table help",
                    content_style:
                      "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
                  }}
                />
            </div>
          </div>
          <div className="row mt-3">
            <div className="d-none d-lg-block col-lg-3"></div>
            <div className="col-lg-3 text-center p-2">
              <button className="btn btn-primary" onClick={updateIntro}>
                Update Introduction
              </button>
            </div>
            <div className="col-lg-3 text-center p-2">
              <button
                className="btn btn-primary"
                onClick={() =>
                  navigate("/intro/" + profiledata.unique_user_name)
                }
              >
                View on Page
              </button>
            </div>
            <div className="d-none d-lg-block col-lg-3 p-2"></div>
          </div>
        </div>
      </div>
    );
  }
}
