import React, { useEffect, useState } from "react";
import { Link, Navigate, useNavigate } from "react-router-dom";
import ProfileCompletionDashboard from "./ProfileCompletionDashboard";
import axios from "axios";
import { API_PATH, STATE_LIST, USER_TOKEN } from "../../Config";
import useLoginAuth from "../../hooks/useLoginAuth";
import Cropper from "react-easy-crop";
import getCroppedImg from "../utils/cropper/cropImage";
import Spinner from "../../layout/common/Spinner";

export default function CreateStoreFront() {
  const [spinner, setspinner] = useState(false);

  const validatelogin = useLoginAuth();
  const navigate = useNavigate();

  const [profilescore, setprofilescore] = useState("0%");
  const [bannerimage, setbannerimage] = useState([]);
  const [profileimage, setprofileimage] = useState([]);
  const [businessName, setbusinessName] = useState("");
  const [keypersonfname, setkeypersonfname] = useState("");
  const [keypersonmname, setkeypersonmname] = useState("");
  const [keypersonlname, setkeypersonlname] = useState("");
  const [keycontact, setkeycontact] = useState("");
  const [businesstype, setbusinesstype] = useState("");
  const [cin, setcin] = useState("");
  const [pan, setpan] = useState("");
  const [gst, setgst] = useState("");
  const [aboutowner, setaboutowner] = useState("");
  const [regaddress, setregaddress] = useState("");
  const [regcity, setregcity] = useState("");
  const [regdistrict, setregdistrict] = useState("");
  const [regstate, setregstate] = useState("");
  const [regpincode, setregpincode] = useState("");
  const [officeaddress, setofficeaddress] = useState("");
  const [officecity, setofficecity] = useState("");
  const [officedistrict, setofficedistrict] = useState("");
  const [officestate, setofficestate] = useState("");
  const [officepincode, setofficepincode] = useState("");
  const [profiledata, setprofiledata] = useState([]);
  // prepare category selection
  const [selectCat1, setSelectCat1] = useState([]);
  const [prodCat, setProdCat] = useState("");

  const [selectsubCat1, setselectsubCat1] = useState([]);
  const [subcat1, setsubcat1] = useState("");

  const [selectsubCat2, setselectsubCat2] = useState([]);
  const [subcat2, setsubcat2] = useState("");

  const [selectsubCat3, setselectsubCat3] = useState([]);
  const [subcat3, setsubcat3] = useState("");

  const [selectsubCat4, setselectsubCat4] = useState([]);
  const [subcat4, setsubcat4] = useState("");

  const [resultStatus, setResultStatus] = useState("");
  const [resultStatusClass, setResultStatusClass] = useState("");

  // image crop -------------------------------------------------------------------------->

  const [image, setImage] = useState(null);
  const [croppedArea, setCroppedArea] = useState(null);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [aspectRatio, setaspectRatio] = useState(15 / 3);
  const [cropShape, setcropShape] = useState("rect");
  const [zoom, setZoom] = useState(1);
  const [inputselector, setinputselector] = useState("");
  const [displaymodalonimgselect, setdisplaymodalonimgselect] = useState(false);

  // trigger file input by id on click
  const triggerFileSelectPopup = () => {
    //console.log(inputselector);
    if (inputselector !== "") {
      document.getElementById(inputselector).click();
    }
  };

  const onCropComplete = (croppedAreaPercentage, croppedAreaPixels) => {
    setCroppedArea(croppedAreaPixels);
  };

  const onSelectFile = (event) => {
    if (event.target.id === "banner_image") {
      setaspectRatio(15 / 5);
      setcropShape("rect");
    } else {
      setaspectRatio(1 / 1);
      setcropShape("round");
    }
    if (event.target.files && event.target.files.length > 0) {
      //console.log(event.target.id)
      const reader = new FileReader();
      reader.readAsDataURL(event.target.files[0]);
      reader.addEventListener("load", () => {
        setImage(reader.result);
        setinputselector(event.target.id);
        setdisplaymodalonimgselect(true);
      });
    }
  };

  const getImage = async () => {
    const output = await getCroppedImg(image, croppedArea, 0);
    setdisplaymodalonimgselect(false);
    if (inputselector === "banner_image") {
      setbannerimage(output);
    }
    if (inputselector === "profile_image") {
      setprofileimage(output);
    }
  };
  // image crop ----------------------------------------------------------------------------->

  useEffect(() => {
    window.scrollTo(0, 0);
    setspinner(true);
    axios
      .post(API_PATH + "ManageStoreFront.php", {
        action: "getBusinessProfile",
        userid: localStorage.getItem("ez-userid"),
        usertoken: localStorage.getItem("ez-usertoken"),
      })
      .then((r) => {
        if (r.data.status === "success") {
          setprofiledata(r.data.retval);
          setprofilescore(r.data.profilescore + "%");
        } else {
          setprofilescore("0%");
        }
        setspinner(false);
      });
  }, []);

  const updateProfile = () => {
    const fd = new FormData();
    fd.append("action", "updateprofilepage1");
    fd.append("userid", localStorage.getItem("ez-userid"));
    fd.append("usertoken", localStorage.getItem("ez-usertoken"));

    fd.append("bannerimage", bannerimage);
    fd.append("profileimage", profileimage);

    fd.append("businessName", businessName);
    fd.append("keypersonfname", keypersonfname);
    fd.append("keypersonmname", keypersonmname);
    fd.append("keypersonlname", keypersonlname);
    fd.append("keycontact", keycontact);
    fd.append("businesstype", businesstype);
    fd.append("cin", cin);
    fd.append("pan", pan);
    fd.append("gst", gst);
    fd.append("aboutowner", aboutowner);
    fd.append("regaddress", regaddress);
    fd.append("regcity", regcity);
    fd.append("regdistrict", regdistrict);
    fd.append("regstate", regstate);
    fd.append("regpincode", regpincode);
    fd.append("officeaddress", officeaddress);
    fd.append("officecity", officecity);
    fd.append("officedistrict", officedistrict);
    fd.append("officestate", officestate);
    fd.append("officepincode", officepincode);
    
    // for business category
    fd.append("prodcat", prodCat);
    fd.append("subcat1", subcat1);
    fd.append("subcat2", subcat2);
    fd.append("subcat3", subcat3);
    fd.append("subcat4", subcat4);

    // check params are filled or not
    setspinner(true);
    axios
      .post(API_PATH + "ManageStoreFront.php", fd, {
        headers: {
          "content-type": "multipart/form-data",
        },
      })
      .then((res) => {
        console.log(res.data);
        if (res.data.status === "success") {
          alert("Data Updated successfully!");
        }
        setspinner(false);
      });

    window.scrollTo(0, 0);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    setspinner(true);
    axios
      .post(API_PATH + "ManageInventory.php", {
        usertoken: USER_TOKEN,
        action: "getCategoryData",
        parentcat: "",
      })
      .then((r) => {
        r.data.status === "success"
          ? setSelectCat1(r.data.retval)
          : setSelectCat1([]);
        setspinner(false);
      });
  }, []);

  // sub category 1
  useEffect(() => {
    if (prodCat.length > 2) {
      axios
        .post(API_PATH + "ManageInventory.php", {
          usertoken: USER_TOKEN,
          action: "getCategoryData",
          parentcat: prodCat,
        })
        .then((r) => {
          r.data.status === "success"
            ? setselectsubCat1(r.data.retval)
            : setselectsubCat1([]);
        });
    }
  }, [prodCat]);

  // sub category 2
  useEffect(() => {
    if (subcat1.length > 2) {
      axios
        .post(API_PATH + "ManageInventory.php", {
          usertoken: USER_TOKEN,
          action: "getCategoryData",
          parentcat: subcat1,
        })
        .then((r) => {
          r.data.status === "success"
            ? setselectsubCat2(r.data.retval)
            : setselectsubCat2([]);
        });
    }
  }, [subcat1]);

  // sub category 3
  useEffect(() => {
    if (subcat2.length > 2) {
      axios
        .post(API_PATH + "ManageInventory.php", {
          usertoken: USER_TOKEN,
          action: "getCategoryData",
          parentcat: subcat2,
        })
        .then((r) => {
          r.data.status === "success"
            ? setselectsubCat3(r.data.retval)
            : setselectsubCat3([]);
        });
    }
  }, [subcat2]);

  // sub category 4
  useEffect(() => {
    if (subcat3.length > 2) {
      axios
        .post(API_PATH + "ManageInventory.php", {
          usertoken: USER_TOKEN,
          action: "getCategoryData",
          parentcat: subcat3,
        })
        .then((r) => {
          r.data.status === "success"
            ? setselectsubCat4(r.data.retval)
            : setselectsubCat4([]);
        });
    }
  }, [subcat3]);

  // change values of other categories if parent has changed
  const processprimarycategory = (e) => {
    setProdCat(e.target.value);

    setselectsubCat1([]);
    setsubcat1("");
    setselectsubCat2([]);
    setsubcat2("");
    setselectsubCat3([]);
    setsubcat3("");
    setselectsubCat4([]);
    setsubcat4("");
  };
  const processsubcat1 = (e) => {
    setsubcat1(e.target.value);
    setselectsubCat2([]);
    setsubcat2("");
    setselectsubCat3([]);
    setsubcat3("");
    setselectsubCat4([]);
    setsubcat4("");
  };
  const processsubcat2 = (e) => {
    setsubcat2(e.target.value);
    setselectsubCat3([]);
    setsubcat3("");
    setselectsubCat4([]);
    setsubcat4("");
  };
  const processsubcat3 = (e) => {
    setsubcat3(e.target.value);
    setselectsubCat4([]);
    setsubcat4("");
  };

  if (validatelogin !== true) {
    return <Navigate to={"/login"} />;
  } else {
    return (
      <>
        <div className="pt-5 mh-90 pb-5 bg-white">
          {spinner === true && <Spinner />}
          <div className="container mt-4">
            <h2 className="text-secondary">Create your EasyStore</h2>
            <small>
              Fill up details reuired in form fields and create your own
              EasyStore where you can display all your business information at
              your customized business page. Click{" "}
              {
                <Link
                  to={"/ez/" + profiledata.unique_user_name}
                  className="border rounded bg-primary text-white p-1"
                >
                  Here
                </Link>
              }{" "}
              to view your page progress.
            </small>
          </div>
          <ProfileCompletionDashboard score={profilescore} />
          <div className="container mt-4">
            <div className="row pb-4">
              <div className="pt-2 col-md-6">
                <label>Your Business/Company Name </label>
                <input
                  type="text"
                  className="form-control"
                  placeholder={profiledata.b_name}
                  onChange={(e) => setbusinessName(e.target.value)}
                />
              </div>
              <div className="pt-2 col-md-3">
                <label>Key person First Name</label>
                <input
                  type="text"
                  placeholder={profiledata.key_person_fname}
                  className="form-control"
                  onChange={(e) => setkeypersonfname(e.target.value)}
                />
              </div>
              <div className="pt-2 col-md-3">
                <label>Key person Middle Name</label>
                <input
                  type="text"
                  placeholder={profiledata.key_person_mname}
                  className="form-control"
                  onChange={(e) => setkeypersonmname(e.target.value)}
                />
              </div>
              <div className="pt-2 col-md-3">
                <label>Key person Last Name</label>
                <input
                  type="text"
                  placeholder={profiledata.key_person_lname}
                  className="form-control"
                  onChange={(e) => setkeypersonlname(e.target.value)}
                />
              </div>
              <div className="pt-2 col-md-3">
                <label>Key person Contact</label>
                <input
                  type="number"
                  min="0"
                  placeholder={profiledata.key_person_contact}
                  className="form-control"
                  onChange={(e) => setkeycontact(e.target.value)}
                />
              </div>
              <div className="pt-2 col-md-3">
                <label>Select Business Type</label>
                <select
                  className="form-control"
                  onChange={(e) => setbusinesstype(e.target.value)}
                >
                  <option value="" key="0">
                    Select from list
                  </option>
                  <option value="unregistered" key="1">
                    Unregistered
                  </option>
                  <option value="proprietorship" key="2">
                    Proprietorship
                  </option>
                  <option value="partnership" key="3">
                    Partnership
                  </option>
                  <option value="llp" key="4">
                    Limited Liability Partnership
                  </option>
                  <option value="opc" key="5">
                    One Person Company
                  </option>
                  <option value="private-limited" key="6">
                    Private Limited
                  </option>
                  <option value="public-limited" key="7">
                    Public Limited
                  </option>
                  <option value="ngo" key="8">
                    Ngo
                  </option>
                  <option value="trust" key="9">
                    Trust
                  </option>
                  <option value="other" key="10">
                    Other
                  </option>
                </select>
              </div>
              <div className="pt-2 col-md-3">
                <label>Cin if Applicable</label>
                <input
                  type="text"
                  placeholder={profiledata.b_cin}
                  className="form-control"
                  onChange={(e) => setcin(e.target.value)}
                />
              </div>
              <div className="pt-2 col-md-3">
                <label>Company Pan Number</label>
                <input
                  type="text"
                  placeholder={profiledata.b_pan}
                  className="form-control"
                  onChange={(e) => setpan(e.target.value)}
                />
              </div>
              <div className="pt-2 col-md-3">
                <label>Gst Number</label>
                <input
                  type="text"
                  placeholder={profiledata.b_gst}
                  className="form-control"
                  onChange={(e) => setgst(e.target.value)}
                />
              </div>
              <div className="pt-2 col-md-3">
                <label>Banner Image</label>
                <input
                  id={"banner_image"}
                  type="file"
                  className="form-control"
                  // onChange={(e) => setbannerimage(e.target.files[0])}
                  onChange={onSelectFile}
                />
              </div>
              <div className="pt-2 col-md-3">
                <label>Profile/Logo Image</label>
                <input
                  id={"profile_image"}
                  type="file"
                  className="form-control"
                  // onChange={(e) => setprofileimage(e.target.files[0])}
                  onChange={onSelectFile}
                />
              </div>
              <div className="col-sm-3 pt-2">
                <label>Business Category</label>
                <input
                  list="primaryCategory"
                  type={"search"}
                  className="form-control border-danger"
                  value={prodCat}
                  onChange={processprimarycategory}
                  autoComplete="on"
                />
                <datalist id="primaryCategory">
                  {selectCat1.map((c1) => {
                    return <option value={c1.cat_name} key={c1.id} />;
                  })}
                </datalist>
              </div>
              {selectsubCat1.length > 0 && (
                <div className="col-sm-3 pt-2">
                  <label>Sub Category 1</label>
                  <input
                    list="subcat1"
                    type={"search"}
                    className="form-control"
                    value={subcat1}
                    onChange={processsubcat1}
                    autoComplete="on"
                  />
                  <datalist id="subcat1">
                    {selectsubCat1.map((c1) => {
                      return <option value={c1.cat_name} key={c1.id} />;
                    })}
                  </datalist>
                </div>
              )}
              {selectsubCat2.length > 0 && (
                <div className="col-sm-3 pt-2">
                  <label>Sub Category 2</label>
                  <input
                    list="subcat2"
                    type={"search"}
                    className="form-control"
                    value={subcat2}
                    onChange={processsubcat2}
                    autoComplete="on"
                  />
                  <datalist id="subcat2">
                    {selectsubCat2.map((c1) => {
                      return <option value={c1.cat_name} key={c1.id} />;
                    })}
                  </datalist>
                </div>
              )}
              {selectsubCat3.length > 0 && (
                <div className="col-sm-3 pt-2">
                  <label>Sub Category 3</label>
                  <input
                    list="subcat3"
                    type={"search"}
                    className="form-control"
                    value={subcat3}
                    onChange={processsubcat3}
                    autoComplete="on"
                  />
                  <datalist id="subcat3">
                    {selectsubCat3.map((c1) => {
                      return <option value={c1.cat_name} key={c1.id} />;
                    })}
                  </datalist>
                </div>
              )}
              {selectsubCat4.length > 0 && (
                <div className="col-sm-3 pt-2">
                  <label>Sub Category 4</label>
                  <input
                    list="subcat4"
                    type={"search"}
                    className="form-control"
                    value={subcat4}
                    onChange={(e) => {
                      setsubcat4(e.target.value);
                    }}
                    autoComplete="on"
                  />
                  <datalist id="subcat4">
                    {selectsubCat4.map((c1) => {
                      return <option value={c1.cat_name} key={c1.id} />;
                    })}
                  </datalist>
                </div>
              )}
              <div className="pt-2 col-md-12">
                <label>Intro About Owner</label>
                <textarea
                  type="text"
                  placeholder={profiledata.key_person_profile}
                  className="form-control"
                  rows={5}
                  onChange={(e) => setaboutowner(e.target.value)}
                />
              </div>
            </div>
            <div className="row mt-4 border-top border-primary pt-4 pb-4">
              <div className="pt-2 col-md-6">
                <label>Registered Address </label>
                <input
                  type="text"
                  className="form-control"
                  placeholder={profiledata.registered_address}
                  onChange={(e) => setregaddress(e.target.value)}
                />
              </div>
              <div className="pt-2 col-md-3">
                <label>City</label>
                <input
                  type="text"
                  placeholder={profiledata.registered_city}
                  className="form-control"
                  onChange={(e) => setregcity(e.target.value)}
                />
              </div>
              <div className="pt-2 col-md-3">
                <label>District</label>
                <input
                  type="text"
                  placeholder={profiledata.registered_district}
                  className="form-control"
                  onChange={(e) => setregdistrict(e.target.value)}
                />
              </div>
              <div className="pt-2 col-md-3">
                <label>State</label>
                <select
                  className="form-control"
                  onChange={(e) => setregstate(e.target.value)}
                >
                  <option value="" key="0">
                    Select from list
                  </option>
                  {STATE_LIST.map((i) => {
                    return (
                      <option value={i} key={i}>
                        {i}
                      </option>
                    );
                  })}
                </select>
              </div>
              <div className="pt-2 col-md-3">
                <label>Pincode</label>
                <input
                  type="number"
                  min="0"
                  placeholder={profiledata.registered_pincode}
                  className="form-control"
                  onChange={(e) => setregpincode(e.target.value)}
                />
              </div>
            </div>
            <div className="row mt-4 border-top pb-4 border-primary pt-4">
              <div className="pt-2 col-md-6">
                <label>Office Address </label>
                <input
                  type="text"
                  className="form-control"
                  placeholder={profiledata.office_address}
                  onChange={(e) => setofficeaddress(e.target.value)}
                />
              </div>
              <div className="pt-2 col-md-3">
                <label>City</label>
                <input
                  type="text"
                  placeholder={profiledata.office_city}
                  className="form-control"
                  onChange={(e) => setofficecity(e.target.value)}
                />
              </div>
              <div className="pt-2 col-md-3">
                <label>District</label>
                <input
                  type="text"
                  placeholder={profiledata.office_district}
                  className="form-control"
                  onChange={(e) => setofficedistrict(e.target.value)}
                />
              </div>
              <div className="pt-2 col-md-3">
                <label>State</label>
                <select
                  className="form-control"
                  onChange={(e) => setofficestate(e.target.value)}
                >
                  <option value="" key="0">
                    Select from list
                  </option>
                  {STATE_LIST.map((i) => {
                    return (
                      <option value={i} key={i}>
                        {i}
                      </option>
                    );
                  })}
                </select>
              </div>
              <div className="pt-2 col-md-3">
                <label>Pincode</label>
                <input
                  type="number"
                  min="0"
                  placeholder={profiledata.office_pincode}
                  className="form-control"
                  onChange={(e) => setofficepincode(e.target.value)}
                />
              </div>
            </div>
            <div className="row mt-5">
              <div className="d-none d-lg-block col-lg-3"></div>
              <div className="col-lg-3 text-center p-2">
                <button className="btn btn-primary" onClick={updateProfile}>
                  Update data
                </button>
              </div>
              <div className="col-lg-3 text-center p-2">
                <button
                  className="btn btn-primary"
                  onClick={() => navigate("/update-business-contacts")}
                >
                  Go to Contacts
                </button>
              </div>
              <div className="d-none d-lg-block col-lg-3 p-2"></div>
            </div>
          </div>
        </div>
        {/* Cropper Start */}
        {displaymodalonimgselect ? (
          <>
            <div
              style={{
                width: "100%",
                height: "100%",
                padding: "10px",
                position: "fixed",
                zIndex: "99999",
                top: "0",
                left: "0",
              }}
            >
              <div
                style={{ height: "80%", width: "100%", position: "relative" }}
              >
                <Cropper
                  image={image}
                  crop={crop}
                  zoom={zoom}
                  cropShape={cropShape}
                  aspect={aspectRatio}
                  onCropChange={setCrop}
                  onZoomChange={setZoom}
                  onCropComplete={onCropComplete}
                />
              </div>
              <div
                className="text-center pt-2"
                style={{ height: "20%", width: "100%", position: "relative" }}
              >
                <button
                  className="btn btn-primary "
                  onClick={triggerFileSelectPopup}
                >
                  Change Image
                </button>

                <button className="btn btn-primary ms-1" onClick={getImage}>
                  Crop/Set Image
                </button>
              </div>
            </div>
          </>
        ) : null}
        {/* Cropper End */}
      </>
    );
  }
}
