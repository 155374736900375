import axios from 'axios';
import React, { useEffect } from 'react'
import { API_PATH } from '../Config';

export default function useCheckUpdate() {
    let appversion = require("./../../package.json").version;

    useEffect(() => {
      axios
        .post(API_PATH + "Action.php", {
          action: "checkforappupdate",
        })
        .then((r) => {
          if (r.data.status === "success") {
            if (appversion !== r.data.version) {
              if ("caches" in window) {
                  caches.keys().then((names) => {
                    // Delete all the cache files
                    names.forEach((name) => {
                      caches.delete(name);
                    });
                  });
                  window.stop();
                  window.location.reload(true);
                }
            }
          }
        });
    }, []);
  
    return;
}
