import React from "react";
import { Link } from "react-router-dom";

export default function OrderNav() { 
    return(
        <div>
            <div className="container mt-3 pb-1 border-bottom">
                <div className="order-nav">
                    <ul>
                        <li className="btn btn-primary">
                            <Link to="/manage-orders" className="text-white"> Fresh Order </Link>
                        </li>
                        <li className="btn btn-primary ms-1">
                            <Link to="/ready-to-ship"  className="text-white"> Ready To Ship </Link>
                        </li>
                        <li className="btn btn-primary ms-1">
                            <Link to="/in-transit" className="text-white"> Shipped </Link>
                        </li>
                        <li className="btn btn-primary ms-1">
                            <Link to="/order-delivered" className="text-white"> Delivered </Link>
                        </li>
                        
                    </ul>
                </div>
            </div>
        </div>
    );
}