import axios from "axios";
import React, { useEffect, useState } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import { API_PATH, APP_TOKEN } from "../../Config";
import Spinner from "../../layout/common/Spinner";

export default function Login() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const navigate = useNavigate();
  const [loginId, setLoginId] = useState("");
  const [password, setPassword] = useState("");
  const [statusClass, setStatusClass] = useState("d-none");
  const [statusMessage, setStatusMessage] = useState("");
  const [checkLoggedIn, setCheckLoggedIn] = useState("false");

  const [spinner, setspinner] = useState(false);

  const userLogin = (e) => {
    e.preventDefault();
    if (loginId === "" || password === "") {
      setStatusClass("d-block alert-danger");
      setStatusMessage("Something is Missing, Please Check Out.");
    } else {
      setspinner(true);
      axios
        .post(API_PATH + "Auth.php", {
          apptoken: APP_TOKEN,
          loginId: loginId,
          password: password,
          action: "userLogin",
        })
        .then(function(r) {
          console.log(r.data);
          switch (r.data.status) {
            case "success":
              setStatusClass("alert-success");
              setStatusMessage("Login Successfull. Redirecting....");
              localStorage.setItem("ez-isLoggedIn", "true");
              localStorage.setItem("ez-usertoken", r.data.usertoken);
              localStorage.setItem("ez-userid", r.data.userid);
              localStorage.setItem("ez-userrole", r.data.userrole);
              localStorage.setItem("ez-superowner", r.data.superowner);
              localStorage.setItem("ez-userstatus", r.data.userstatus);
              localStorage.setItem("ez-upath", r.data.upath);
              setCheckLoggedIn("true");
              break;

            case "usernotfound":
              setStatusClass("alert-danger");
              setStatusMessage(
                "No User detail matched in system. Please try again."
              );
              break;

            default:
              setStatusClass("alert-danger");
              setStatusMessage("Something went wrong, Please contact Admin");
              break;
          }
          window.scrollTo(0, 0);
          setspinner(false);
        });
    }
  };
  if (checkLoggedIn === "true") {
    return <Navigate to={"/dashboard"} />;
  } else {
    return (
      <div className="mh-90 container mt-5 pt-5 mb-5 bg-white shadow rounded">
        {spinner === true && <Spinner />}
        <div className="border-bottom p-1">
          <h1>Login</h1>
          <small className="pb-1">
            {" "}
            Please enter Contact NUmber or Email and password to login
          </small>
        </div>
        <form onSubmit={userLogin}>
          <div className="row m-0 mt-4">
            <div className="col-md-6 border-end">
              <div className={`alert ${statusClass} `} role="alert">
                {statusMessage}
              </div>
              <div className="p-1">
                <label>Please enter Contact number or Email Address</label>
                <input
                  type="text"
                  className="form-control bg-light mt-1"
                  onChange={(e) => {
                    setLoginId(e.target.value);
                  }}
                />
              </div>
              <div className="p-1 mt-1">
                <label>Please enter User Password</label>
                <input
                  type="password"
                  className="form-control bg-light mt-1"
                  onChange={(e) => {
                    setPassword(e.target.value);
                  }}
                />
              </div>
              <div className="p-1">
                <button className="btn btn-primary rounded-pill" onClick={userLogin}>
                  Login
                </button>
                <button
                  className="btn btn-primary rounded-pill ms-2"
                  onClick={() => navigate("/user-registration")}
                >
                  Register
                </button>
              </div>
            </div>
            <div className="col-md-6 text-justify" style={{fontSize:14}}>
              Dear user, <br/> Welcome to Biziness.in ! We are thrilled to have you
              as a part of our community. Our platform offers personalized user
              experiences and advanced security measures, ensuring that your
              time with us is both enjoyable and secure. If you have any
              questions or concerns, please do not hesitate to reach out to our
              customer support team. Thank you for choosing our platform, and we
              look forward to serving you! <br/> Best regards, <br/> Team Biziness.in
              Team
            </div>
          </div>
        </form>
      </div>
    );
  }
}
