import React, { useEffect } from "react";

export default function PageNotFount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="pt-5 pb-5 mh-90">
      <div className="container mt-5 text-center pt-5">
        <img src={require("../../assets/img/bzlogo1.png")} width="25%" alt="" />
        <h2 className="mt-2">Sorry ! Page not found.</h2>
        <p>
          The page your are looking for has either been moved to new location or
          doesn't exist on this server. Please contact our support team in case
          of any probelm.
        </p>
      </div>
    </div>
  );
}
