import React from "react";

export default function EzBanner() {
  return (
    <>
      <div className="container mt-5 pt-4">
        <div className="row">
          <div className="col-md-3 d-none d-md-block"></div>
          <div className="col-md-6 text-center ">
            <img
              src={require("../../../assets/img/bzlogo1.png")}
              alt=""
              className="bg-primary shadow"
              style={{
                borderRadius: 25,
                height: 146,
                width: "100%",
                objectFit: "cover",
                zIndex: -22,
              }}
            />
          </div>
          <div className="col-md-3 d-none d-md-block"></div>
        </div>
      </div>
    </>
  );
}
