import axios from "axios";
import { useEffect, useState } from "react";
import { API_PATH, APP_TOKEN } from "../Config";

export default function useLoginAuth() {
  const USER_ID = localStorage.getItem("ez-userid");
  const USER_TOKEN = localStorage.getItem("ez-usertoken");
  const USER_ROLE = localStorage.getItem("ez-userrole");
  const [loginstatus , setloginstatus] =useState(true)
  
  useEffect(() => {
    axios.post(API_PATH+'Auth.php',{
        userid : USER_ID,
        usertoken : USER_TOKEN,
        userrole : USER_ROLE,
        apptoken : APP_TOKEN,
        action:'validateuser'
    }).then((r) => {
      if(r.data.status==='success'){
        setloginstatus(true)
      }else{
        setloginstatus(false)
      }
    });
  }, [USER_ID, USER_ROLE, USER_TOKEN]);

  return loginstatus;
}
